@charset "UTF-8";
/**
 * Colors
 */
/**
 * SCSS Variables.
 *
 * Please use variables from this sheet to ensure consistency across the UI.
 * Don't add to this sheet unless you're pretty sure the value will be reused in many places.
 * For example, don't add rules to this sheet that affect block visuals. It's purely for UI.
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block & Editor UI.
 */
/**
 * Block paddings.
 */
/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */
/**
*  Converts a hex value into the rgb equivalent.
*
* @param {string} hex - the hexadecimal value to convert
* @return {string} comma separated rgb values
*/
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Focus styles.
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
/**
 * Breakpoints & Media Queries
 */
.wp-block-audio figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-audio figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-audio {
  margin: 0 0 1em 0;
}

.wp-block-code {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Menlo, Consolas, monaco, monospace;
  padding: 0.8em 1em;
}

.wp-block-embed figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-embed figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-embed {
  margin: 0 0 1em 0;
}

.blocks-gallery-caption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .blocks-gallery-caption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-image figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-image figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-image {
  margin: 0 0 1em 0;
}

.wp-block-pullquote {
  border-top: 4px solid currentColor;
  border-bottom: 4px solid currentColor;
  margin-bottom: 1.75em;
  color: currentColor;
}
.wp-block-pullquote cite,
.wp-block-pullquote footer, .wp-block-pullquote__citation {
  color: currentColor;
  text-transform: uppercase;
  font-size: 0.8125em;
  font-style: normal;
}

.wp-block-quote {
  border-left: 0.25em solid currentColor;
  margin: 0 0 1.75em 0;
  padding-left: 1em;
}
.wp-block-quote cite,
.wp-block-quote footer {
  color: currentColor;
  font-size: 0.8125em;
  position: relative;
  font-style: normal;
}
.wp-block-quote.has-text-align-right {
  border-left: none;
  border-right: 0.25em solid currentColor;
  padding-left: 0;
  padding-right: 1em;
}
.wp-block-quote.has-text-align-center {
  border: none;
  padding-left: 0;
}
.wp-block-quote.is-style-plain, .wp-block-quote.is-style-large, .wp-block-quote.is-large {
  border: none;
}

.wp-block-search .wp-block-search__label {
  font-weight: bold;
}

.wp-block-search__button {
  border: 1px solid #ccc;
  padding: 0.375em 0.625em;
}

:where(.wp-block-group.has-background) {
  padding: 1.25em 2.375em;
}

.wp-block-separator.has-css-opacity {
  opacity: 0.4;
}

.wp-block-separator {
  border: none;
  border-bottom: 2px solid currentColor;
  margin-left: auto;
  margin-right: auto;
}
.wp-block-separator.has-alpha-channel-opacity {
  opacity: initial;
}
.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  width: 100px;
}
.wp-block-separator.has-background:not(.is-style-dots) {
  border-bottom: none;
  height: 1px;
}
.wp-block-separator.has-background:not(.is-style-wide):not(.is-style-dots) {
  height: 2px;
}

.wp-block-table {
  margin: "0 0 1em 0";
}
.wp-block-table thead {
  border-bottom: 3px solid;
}
.wp-block-table tfoot {
  border-top: 3px solid;
}
.wp-block-table td,
.wp-block-table th {
  padding: 0.5em;
  border: 1px solid;
  word-break: normal;
}
.wp-block-table figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-table figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-video figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
.is-dark-theme .wp-block-video figcaption {
  color: rgba(255, 255, 255, 0.65);
}

.wp-block-video {
  margin: 0 0 1em 0;
}

.wp-block-template-part.has-background {
  padding: 1.25em 2.375em;
  margin-top: 0;
  margin-bottom: 0;
}

/*------------------------------------*\
    GRID FUNCTION
\*------------------------------------*/
/**
 * Space between columns
 */
/**
 * Number of total columns
 */
/**** COLOURS ****/
/* $start color */
/* $end color */
/**** SHADOWS ****/ /**** CDN Image with automatic query strings for caching ****/ /**
 * Clearfix
 */
/*------------------------------------*\
    GRID FUNCTION
\*------------------------------------*/
/**
 * Space between columns
 */
/**
 * Number of total columns
 */
.wp-block-audio {
  box-sizing: border-box;
}
.wp-block-audio figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.wp-block-audio audio {
  width: 100%;
  min-width: 300px;
}

.wp-block-button__link {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  word-break: break-word;
  box-sizing: border-box;
}
.wp-block-button__link.aligncenter {
  text-align: center;
}
.wp-block-button__link.alignright {
  /*rtl:ignore*/
  text-align: right;
}

:where(.wp-block-button__link) {
  box-shadow: none;
  text-decoration: none;
  border-radius: 9999px;
  padding: calc(0.667em + 2px) calc(1.333em + 2px);
}

.wp-block-button[style*=text-decoration] .wp-block-button__link {
  text-decoration: inherit;
}

.wp-block-buttons > .wp-block-button.has-custom-width {
  max-width: none;
}
.wp-block-buttons > .wp-block-button.has-custom-width .wp-block-button__link {
  width: 100%;
}
.wp-block-buttons > .wp-block-button.has-custom-font-size .wp-block-button__link {
  font-size: inherit;
}
.wp-block-buttons > .wp-block-button.wp-block-button__width-25 {
  width: calc(25% - var(--wp--style--block-gap, 0.5em) * 0.75);
}
.wp-block-buttons > .wp-block-button.wp-block-button__width-50 {
  width: calc(50% - var(--wp--style--block-gap, 0.5em) * 0.5);
}
.wp-block-buttons > .wp-block-button.wp-block-button__width-75 {
  width: calc(75% - var(--wp--style--block-gap, 0.5em) * 0.25);
}
.wp-block-buttons > .wp-block-button.wp-block-button__width-100 {
  width: 100%;
  flex-basis: 100%;
}

.wp-block-buttons.is-vertical > .wp-block-button.wp-block-button__width-25 {
  width: 25%;
}
.wp-block-buttons.is-vertical > .wp-block-button.wp-block-button__width-50 {
  width: 50%;
}
.wp-block-buttons.is-vertical > .wp-block-button.wp-block-button__width-75 {
  width: 75%;
}

.wp-block-button.is-style-squared,
.wp-block-button__link.wp-block-button.is-style-squared {
  border-radius: 0;
}

.wp-block-button.no-border-radius,
.wp-block-button__link.no-border-radius {
  border-radius: 0 !important;
}

.wp-block-button.is-style-outline > .wp-block-button__link,
.wp-block-button .wp-block-button__link.is-style-outline {
  border: 2px solid currentColor;
  padding: 0.667em 1.333em;
}

.wp-block-button.is-style-outline > .wp-block-button__link:not(.has-text-color),
.wp-block-button .wp-block-button__link.is-style-outline:not(.has-text-color) {
  color: currentColor;
}

.wp-block-button.is-style-outline > .wp-block-button__link:not(.has-background),
.wp-block-button .wp-block-button__link.is-style-outline:not(.has-background) {
  background-color: transparent;
}

.wp-block-buttons {
  /* stylelint-disable indentation */
}
.wp-block-buttons.is-vertical {
  flex-direction: column;
}
.wp-block-buttons.is-vertical > .wp-block-button:last-child {
  margin-bottom: 0;
}
.wp-block-buttons > .wp-block-button {
  display: inline-block;
  margin: 0;
}
.wp-block-buttons.is-content-justification-left {
  justify-content: flex-start;
}
.wp-block-buttons.is-content-justification-left.is-vertical {
  align-items: flex-start;
}
.wp-block-buttons.is-content-justification-center {
  justify-content: center;
}
.wp-block-buttons.is-content-justification-center.is-vertical {
  align-items: center;
}
.wp-block-buttons.is-content-justification-right {
  justify-content: flex-end;
}
.wp-block-buttons.is-content-justification-right.is-vertical {
  align-items: flex-end;
}
.wp-block-buttons.is-content-justification-space-between {
  justify-content: space-between;
}
.wp-block-buttons.aligncenter {
  text-align: center;
}
.wp-block-buttons:not(.is-content-justification-space-between,
.is-content-justification-right,
.is-content-justification-left,
.is-content-justification-center) .wp-block-button.aligncenter {
  /* stylelint-enable indentation */
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.wp-block-buttons[style*=text-decoration] .wp-block-button,
.wp-block-buttons[style*=text-decoration] .wp-block-button__link {
  text-decoration: inherit;
}
.wp-block-buttons.has-custom-font-size .wp-block-button__link {
  font-size: inherit;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.wp-block-code code {
  display: block;
  font-family: inherit;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.wp-block-columns {
  display: flex;
  margin-bottom: 1.75em;
  box-sizing: border-box;
  flex-wrap: wrap !important;
  align-items: initial !important;
  /**
  * All Columns Alignment
  */
}
@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: nowrap !important;
  }
}
.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start;
}
.wp-block-columns.are-vertically-aligned-center {
  align-items: center;
}
.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end;
}
@media (max-width: 781px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 100% !important;
  }
}
@media (min-width: 782px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column[style*=flex-basis] {
    flex-grow: 0;
  }
}
.wp-block-columns.is-not-stacked-on-mobile {
  flex-wrap: nowrap !important;
}
.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column {
  flex-basis: 0;
  flex-grow: 1;
}
.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column[style*=flex-basis] {
  flex-grow: 0;
}

:where(.wp-block-columns.has-background) {
  padding: 1.25em 2.375em;
}

.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  /**
  * Individual Column Alignment
  */
}
.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start;
}
.wp-block-column.is-vertically-aligned-center {
  align-self: center;
}
.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end;
}
.wp-block-column.is-vertically-aligned-top, .wp-block-column.is-vertically-aligned-center, .wp-block-column.is-vertically-aligned-bottom {
  width: 100%;
}

.wp-block-cover-image,
.wp-block-cover {
  position: relative;
  background-position: center center;
  min-height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  /*rtl:raw: direction: ltr; */
  /**
   * Set a default background color for has-background-dim _unless_ it includes another
   * background-color class (e.g. has-green-background-color). The presence of another
   * background-color class implies that another style will provide the background color
   * for the overlay.
   *
   * See:
   *   - Issue with background color specificity: https://github.com/WordPress/gutenberg/issues/26545
   *   - Issue with alternative fix: https://github.com/WordPress/gutenberg/issues/26545
   */
}
.wp-block-cover-image.has-background-dim:not([class*=-background-color]),
.wp-block-cover-image .has-background-dim:not([class*=-background-color]),
.wp-block-cover.has-background-dim:not([class*=-background-color]),
.wp-block-cover .has-background-dim:not([class*=-background-color]) {
  background-color: #000;
}
.wp-block-cover-image .has-background-dim.has-background-gradient,
.wp-block-cover .has-background-dim.has-background-gradient {
  background-color: transparent;
}
.wp-block-cover-image.has-background-dim::before,
.wp-block-cover.has-background-dim::before {
  content: "";
  background-color: inherit;
}
.wp-block-cover-image.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover-image .wp-block-cover__background,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover .wp-block-cover__background,
.wp-block-cover .wp-block-cover__gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0.5;
}
.wp-block-cover-image.has-background-dim.has-background-dim-10:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-10:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background {
  opacity: 0.1;
}
.wp-block-cover-image.has-background-dim.has-background-dim-20:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-20:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background {
  opacity: 0.2;
}
.wp-block-cover-image.has-background-dim.has-background-dim-30:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-30:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background {
  opacity: 0.3;
}
.wp-block-cover-image.has-background-dim.has-background-dim-40:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-40:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background {
  opacity: 0.4;
}
.wp-block-cover-image.has-background-dim.has-background-dim-50:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-50:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background {
  opacity: 0.5;
}
.wp-block-cover-image.has-background-dim.has-background-dim-60:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-60:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background {
  opacity: 0.6;
}
.wp-block-cover-image.has-background-dim.has-background-dim-70:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-70:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background {
  opacity: 0.7;
}
.wp-block-cover-image.has-background-dim.has-background-dim-80:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-80:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background {
  opacity: 0.8;
}
.wp-block-cover-image.has-background-dim.has-background-dim-90:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-90:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background {
  opacity: 0.9;
}
.wp-block-cover-image.has-background-dim.has-background-dim-100:not(.has-background-gradient)::before,
.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-100:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background {
  opacity: 1;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-0,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-0,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-0,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-0 {
  opacity: 0;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-10,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-10,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-10,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-10 {
  opacity: 0.1;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-20,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-20,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-20,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-20 {
  opacity: 0.2;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-30,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-30,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-30,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-30 {
  opacity: 0.3;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-40,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-40,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-40,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-40 {
  opacity: 0.4;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-50,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-50,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-50,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-50 {
  opacity: 0.5;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-60,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-60,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-60,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-60 {
  opacity: 0.6;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-70,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-70,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-70,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-70 {
  opacity: 0.7;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-80,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-80,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-80,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-80 {
  opacity: 0.8;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-90,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-90,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-90,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-90 {
  opacity: 0.9;
}
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-100,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-100,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-100,
.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-100 {
  opacity: 1;
}
.wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  max-width: 420px;
  width: 100%;
}
.wp-block-cover-image::after,
.wp-block-cover::after {
  display: block;
  content: "";
  font-size: 0;
  min-height: inherit;
}
@supports (position: sticky) {
  .wp-block-cover-image::after,
.wp-block-cover::after {
    content: none;
  }
}
.wp-block-cover-image.aligncenter, .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
.wp-block-cover.aligncenter,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  display: flex;
}
.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover__inner-container {
  width: 100%;
  z-index: 1;
  color: #fff;
  /*rtl:raw: direction: rtl; */
}
.wp-block-cover-image.is-light .wp-block-cover__inner-container,
.wp-block-cover.is-light .wp-block-cover__inner-container {
  color: #000;
}
.wp-block-cover-image p:not(.has-text-color),
.wp-block-cover-image h1:not(.has-text-color),
.wp-block-cover-image h2:not(.has-text-color),
.wp-block-cover-image h3:not(.has-text-color),
.wp-block-cover-image h4:not(.has-text-color),
.wp-block-cover-image h5:not(.has-text-color),
.wp-block-cover-image h6:not(.has-text-color),
.wp-block-cover p:not(.has-text-color),
.wp-block-cover h1:not(.has-text-color),
.wp-block-cover h2:not(.has-text-color),
.wp-block-cover h3:not(.has-text-color),
.wp-block-cover h4:not(.has-text-color),
.wp-block-cover h5:not(.has-text-color),
.wp-block-cover h6:not(.has-text-color) {
  color: inherit;
}
.wp-block-cover-image.is-position-top-left,
.wp-block-cover.is-position-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}
.wp-block-cover-image.is-position-top-center,
.wp-block-cover.is-position-top-center {
  align-items: flex-start;
  justify-content: center;
}
.wp-block-cover-image.is-position-top-right,
.wp-block-cover.is-position-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}
.wp-block-cover-image.is-position-center-left,
.wp-block-cover.is-position-center-left {
  align-items: center;
  justify-content: flex-start;
}
.wp-block-cover-image.is-position-center-center,
.wp-block-cover.is-position-center-center {
  align-items: center;
  justify-content: center;
}
.wp-block-cover-image.is-position-center-right,
.wp-block-cover.is-position-center-right {
  align-items: center;
  justify-content: flex-end;
}
.wp-block-cover-image.is-position-bottom-left,
.wp-block-cover.is-position-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}
.wp-block-cover-image.is-position-bottom-center,
.wp-block-cover.is-position-bottom-center {
  align-items: flex-end;
  justify-content: center;
}
.wp-block-cover-image.is-position-bottom-right,
.wp-block-cover.is-position-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}
.wp-block-cover-image.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container {
  margin: 0;
  width: auto;
}
.wp-block-cover-image .wp-block-cover__image-background,
.wp-block-cover-image video.wp-block-cover__video-background,
.wp-block-cover .wp-block-cover__image-background,
.wp-block-cover video.wp-block-cover__video-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  object-fit: cover;
  outline: none;
  border: none;
  box-shadow: none;
}

.wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax,
.wp-block-cover__image-background.has-parallax,
video.wp-block-cover__video-background.has-parallax {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
@supports (-webkit-overflow-scrolling: touch) {
  .wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax,
.wp-block-cover__image-background.has-parallax,
video.wp-block-cover__video-background.has-parallax {
    background-attachment: scroll;
  }
}
@media (prefers-reduced-motion: reduce) {
  .wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax,
.wp-block-cover__image-background.has-parallax,
video.wp-block-cover__video-background.has-parallax {
    background-attachment: scroll;
  }
}
.wp-block-cover-image.is-repeated,
.wp-block-cover.is-repeated,
.wp-block-cover__image-background.is-repeated,
video.wp-block-cover__video-background.is-repeated {
  background-repeat: repeat;
  background-size: auto;
}

.wp-block-cover__video-background {
  z-index: 0;
}

.wp-block-cover__image-background {
  z-index: 0;
}

section.wp-block-cover-image h2,
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: #fff;
}
section.wp-block-cover-image h2 a,
section.wp-block-cover-image h2 a:hover,
section.wp-block-cover-image h2 a:focus,
section.wp-block-cover-image h2 a:active,
.wp-block-cover-image-text a,
.wp-block-cover-image-text a:hover,
.wp-block-cover-image-text a:focus,
.wp-block-cover-image-text a:active,
.wp-block-cover-text a,
.wp-block-cover-text a:hover,
.wp-block-cover-text a:focus,
.wp-block-cover-text a:active {
  color: #fff;
}

.wp-block-cover-image .wp-block-cover.has-left-content {
  justify-content: flex-start;
}
.wp-block-cover-image .wp-block-cover.has-right-content {
  justify-content: flex-end;
}

section.wp-block-cover-image.has-left-content > h2,
.wp-block-cover-image.has-left-content .wp-block-cover-image-text,
.wp-block-cover.has-left-content .wp-block-cover-text {
  margin-left: 0;
  text-align: left;
}

section.wp-block-cover-image.has-right-content > h2,
.wp-block-cover-image.has-right-content .wp-block-cover-image-text,
.wp-block-cover.has-right-content .wp-block-cover-text {
  margin-right: 0;
  text-align: right;
}

section.wp-block-cover-image > h2,
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text {
  font-size: 2em;
  line-height: 1.25;
  z-index: 1;
  margin-bottom: 0;
  max-width: 840px;
  padding: 0.44em;
  text-align: center;
}

.wp-block[data-align=left] > [data-type="core/embed"],
.wp-block[data-align=right] > [data-type="core/embed"],
.wp-block-embed.alignleft,
.wp-block-embed.alignright {
  max-width: 360px;
  width: 100%;
}
.wp-block[data-align=left] > [data-type="core/embed"] .wp-block-embed__wrapper,
.wp-block[data-align=right] > [data-type="core/embed"] .wp-block-embed__wrapper,
.wp-block-embed.alignleft .wp-block-embed__wrapper,
.wp-block-embed.alignright .wp-block-embed__wrapper {
  min-width: 280px;
}

.wp-block-cover .wp-block-embed {
  min-width: 320px;
  min-height: 240px;
}

.wp-block-embed {
  overflow-wrap: break-word;
}
.wp-block-embed figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.wp-block-embed iframe {
  max-width: 100%;
}

.wp-block-embed__wrapper {
  position: relative;
}

.wp-embed-responsive .wp-has-aspect-ratio .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 50%;
}
.wp-embed-responsive .wp-has-aspect-ratio iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wp-embed-responsive .wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
  padding-top: 42.85%;
}
.wp-embed-responsive .wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
  padding-top: 50%;
}
.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
  padding-top: 56.25%;
}
.wp-embed-responsive .wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
  padding-top: 75%;
}
.wp-embed-responsive .wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
  padding-top: 100%;
}
.wp-embed-responsive .wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
  padding-top: 177.77%;
}
.wp-embed-responsive .wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  padding-top: 200%;
}

.wp-block-file {
  margin-bottom: 1.5em;
}
.wp-block-file:not(.wp-element-button) {
  font-size: 0.8em;
}
.wp-block-file.aligncenter {
  text-align: center;
}
.wp-block-file.alignright {
  /*rtl:ignore*/
  text-align: right;
}
.wp-block-file * + .wp-block-file__button {
  margin-left: 0.75em;
}

.wp-block-file__embed {
  margin-bottom: 1em;
}

:where(.wp-block-file__button) {
  border-radius: 2em;
  padding: 0.5em 1em;
}
:where(.wp-block-file__button):is(a):hover, :where(.wp-block-file__button):is(a):visited, :where(.wp-block-file__button):is(a):focus, :where(.wp-block-file__button):is(a):active {
  box-shadow: none;
  color: #fff;
  opacity: 0.85;
  text-decoration: none;
}

.wp-block-gallery:not(.has-nested-images),
.blocks-gallery-grid:not(.has-nested-images) {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item {
  margin: 0 1em 1em 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: calc(50% - 1em);
}
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:nth-of-type(even),
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:nth-of-type(even),
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image:nth-of-type(even),
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item:nth-of-type(even) {
  margin-right: 0;
}
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figure,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figure,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figure,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figure {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
}
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figcaption,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figcaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 3em 0.77em 0.7em;
  color: #fff;
  text-align: center;
  font-size: 0.8em;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent);
  box-sizing: border-box;
  margin: 0;
  z-index: 2;
}
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figcaption img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figcaption img {
  display: inline;
}
.wp-block-gallery:not(.has-nested-images) figcaption,
.blocks-gallery-grid:not(.has-nested-images) figcaption {
  flex-grow: 1;
}
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image img, .wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item img,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-image img,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-item img {
  width: 100%;
  height: 100%;
  flex: 1;
  object-fit: cover;
}
.wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-image, .wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-item {
  width: 100%;
  margin-right: 0;
}
@media (min-width: 600px) {
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-image, .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-item {
    width: calc(33.3333333333% - 0.6666666667em);
    margin-right: 1em;
  }
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-image, .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-item {
    width: calc(25% - 0.75em);
    margin-right: 1em;
  }
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-image, .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-item {
    width: calc(20% - 0.8em);
    margin-right: 1em;
  }
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-image, .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-item {
    width: calc(16.6666666667% - 0.8333333333em);
    margin-right: 1em;
  }
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-image, .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-item {
    width: calc(14.2857142857% - 0.8571428571em);
    margin-right: 1em;
  }
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-image, .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-item,
.blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-item {
    width: calc(12.5% - 0.875em);
    margin-right: 1em;
  }
  .wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-image:nth-of-type(1n), .wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-item:nth-of-type(1n),
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-image:nth-of-type(1n),
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-item:nth-of-type(1n) {
    margin-right: 0;
  }
  .wp-block-gallery:not(.has-nested-images).columns-2 .blocks-gallery-image:nth-of-type(2n), .wp-block-gallery:not(.has-nested-images).columns-2 .blocks-gallery-item:nth-of-type(2n),
.blocks-gallery-grid:not(.has-nested-images).columns-2 .blocks-gallery-image:nth-of-type(2n),
.blocks-gallery-grid:not(.has-nested-images).columns-2 .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0;
  }
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-image:nth-of-type(3n), .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-item:nth-of-type(3n),
.blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-image:nth-of-type(3n),
.blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-item:nth-of-type(3n) {
    margin-right: 0;
  }
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-image:nth-of-type(4n), .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-item:nth-of-type(4n),
.blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-image:nth-of-type(4n),
.blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-item:nth-of-type(4n) {
    margin-right: 0;
  }
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-image:nth-of-type(5n), .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-item:nth-of-type(5n),
.blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-image:nth-of-type(5n),
.blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-item:nth-of-type(5n) {
    margin-right: 0;
  }
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-image:nth-of-type(6n), .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-item:nth-of-type(6n),
.blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-image:nth-of-type(6n),
.blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-item:nth-of-type(6n) {
    margin-right: 0;
  }
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-image:nth-of-type(7n), .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-item:nth-of-type(7n),
.blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-image:nth-of-type(7n),
.blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-item:nth-of-type(7n) {
    margin-right: 0;
  }
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-image:nth-of-type(8n), .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-item:nth-of-type(8n),
.blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-image:nth-of-type(8n),
.blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:last-child,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:last-child,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image:last-child,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item:last-child {
  margin-right: 0;
}
.wp-block-gallery:not(.has-nested-images).alignleft, .wp-block-gallery:not(.has-nested-images).alignright,
.blocks-gallery-grid:not(.has-nested-images).alignleft,
.blocks-gallery-grid:not(.has-nested-images).alignright {
  max-width: 420px;
  width: 100%;
}
.wp-block-gallery:not(.has-nested-images).aligncenter .blocks-gallery-item figure,
.blocks-gallery-grid:not(.has-nested-images).aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.wp-block-gallery:not(.is-cropped) .blocks-gallery-item {
  align-self: flex-start;
}

figure.wp-block-gallery.has-nested-images {
  align-items: normal;
}

.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image) {
  width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px) / 2);
  margin: 0;
}
.wp-block-gallery.has-nested-images figure.wp-block-image {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
}
.wp-block-gallery.has-nested-images figure.wp-block-image > div,
.wp-block-gallery.has-nested-images figure.wp-block-image > a {
  margin: 0;
  flex-direction: column;
  flex-grow: 1;
}
.wp-block-gallery.has-nested-images figure.wp-block-image img {
  display: block;
  height: auto;
  max-width: 100% !important;
  width: auto;
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent);
  bottom: 0;
  color: #fff;
  font-size: 13px;
  left: 0;
  margin-bottom: 0;
  max-height: 60%;
  overflow: auto;
  padding: 0 8px 8px;
  position: absolute;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption img {
  display: inline;
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption a {
  color: inherit;
}
.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border img {
  box-sizing: border-box;
}
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded > div,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded > a, .wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border > div,
.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border > a {
  flex: 1 1 auto;
}
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded figcaption, .wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border figcaption {
  flex: initial;
  background: none;
  color: inherit;
  margin: 0;
  padding: 10px 10px 9px;
  position: relative;
}
.wp-block-gallery.has-nested-images figcaption {
  flex-grow: 1;
  flex-basis: 100%;
  text-align: center;
}
.wp-block-gallery.has-nested-images:not(.is-cropped) figure.wp-block-image:not(#individual-image) {
  margin-top: 0;
  margin-bottom: auto;
}
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) {
  align-self: inherit;
}
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) > div:not(.components-drop-zone),
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) > a {
  display: flex;
}
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) a,
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) img {
  width: 100%;
  flex: 1 0 0%;
  height: 100%;
  object-fit: cover;
}
.wp-block-gallery.has-nested-images.columns-1 figure.wp-block-image:not(#individual-image) {
  width: 100%;
}
@media (min-width: 600px) {
  .wp-block-gallery.has-nested-images.columns-3 figure.wp-block-image:not(#individual-image) {
    width: calc(33.3333333333% - (var(--wp--style--unstable-gallery-gap, 16px) * 0.6666666667));
  }
  .wp-block-gallery.has-nested-images.columns-4 figure.wp-block-image:not(#individual-image) {
    width: calc(25% - (var(--wp--style--unstable-gallery-gap, 16px) * 0.75));
  }
  .wp-block-gallery.has-nested-images.columns-5 figure.wp-block-image:not(#individual-image) {
    width: calc(20% - (var(--wp--style--unstable-gallery-gap, 16px) * 0.8));
  }
  .wp-block-gallery.has-nested-images.columns-6 figure.wp-block-image:not(#individual-image) {
    width: calc(16.6666666667% - (var(--wp--style--unstable-gallery-gap, 16px) * 0.8333333333));
  }
  .wp-block-gallery.has-nested-images.columns-7 figure.wp-block-image:not(#individual-image) {
    width: calc(14.2857142857% - (var(--wp--style--unstable-gallery-gap, 16px) * 0.8571428571));
  }
  .wp-block-gallery.has-nested-images.columns-8 figure.wp-block-image:not(#individual-image) {
    width: calc(12.5% - (var(--wp--style--unstable-gallery-gap, 16px) * 0.875));
  }
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image) {
    width: calc(33.33% - (var(--wp--style--unstable-gallery-gap, 16px) * 0.6666666667));
  }
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2),
.wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2) ~ figure.wp-block-image:not(#individual-image) {
    width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px) * 0.5);
  }
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(1) {
    width: 100%;
  }
}
.wp-block-gallery.has-nested-images.alignleft, .wp-block-gallery.has-nested-images.alignright {
  max-width: 420px;
  width: 100%;
}
.wp-block-gallery.has-nested-images.aligncenter {
  justify-content: center;
}

.wp-block-group {
  box-sizing: border-box;
}

h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
  padding: 1.25em 2.375em;
}

.wp-block-image img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  box-sizing: border-box;
}
.wp-block-image.has-custom-border img {
  box-sizing: border-box;
}
.wp-block-image.aligncenter {
  text-align: center;
}
.wp-block-image.alignfull img, .wp-block-image.alignwide img {
  height: auto;
  width: 100%;
}
.wp-block-image.alignleft, .wp-block-image.alignright, .wp-block-image.aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image .aligncenter {
  display: table;
}
.wp-block-image.alignleft > figcaption, .wp-block-image.alignright > figcaption, .wp-block-image.aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image .aligncenter > figcaption {
  display: table-caption;
  caption-side: bottom;
}
.wp-block-image .alignleft {
  /*rtl:ignore*/
  float: left;
  /*rtl:ignore*/
  margin-left: 0;
  margin-right: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.wp-block-image .alignright {
  /*rtl:ignore*/
  float: right;
  /*rtl:ignore*/
  margin-right: 0;
  margin-left: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}
.wp-block-image figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.wp-block-image.is-style-rounded img,
.wp-block-image .is-style-rounded img {
  border-radius: 9999px;
}
.wp-block-image.is-style-circle-mask img {
  border-radius: 9999px;
}
@supports (mask-image: none) or (-webkit-mask-image: none) {
  .wp-block-image.is-style-circle-mask img {
    /* stylelint-disable */
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    /* stylelint-enable */
    mask-mode: alpha;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    border-radius: 0;
  }
}
.wp-block-image :where(.has-border-color) {
  border-style: solid;
}
.wp-block-image :where([style*=border-top-color]) {
  border-top-style: solid;
}
.wp-block-image :where([style*=border-right-color]) {
  border-right-style: solid;
}
.wp-block-image :where([style*=border-bottom-color]) {
  border-bottom-style: solid;
}
.wp-block-image :where([style*=border-left-color]) {
  border-left-style: solid;
}
.wp-block-image :where([style*=border-width]) {
  border-style: solid;
}
.wp-block-image :where([style*=border-top-width]) {
  border-top-style: solid;
}
.wp-block-image :where([style*=border-right-width]) {
  border-right-style: solid;
}
.wp-block-image :where([style*=border-bottom-width]) {
  border-bottom-style: solid;
}
.wp-block-image :where([style*=border-left-width]) {
  border-left-style: solid;
}

.wp-block-image figure {
  margin: 0;
}

ol,
ul {
  box-sizing: border-box;
}
ol.has-background,
ul.has-background {
  padding: 1.25em 2.375em;
}

.wp-block-media-text {
  /*!rtl:begin:ignore*/
  direction: ltr;
  /*!rtl:end:ignore*/
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: auto;
  box-sizing: border-box;
}
.wp-block-media-text.has-media-on-the-right {
  grid-template-columns: 1fr 50%;
}

.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
  align-self: start;
}

.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media {
  align-self: center;
}

.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
  align-self: end;
}

.wp-block-media-text .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  margin: 0;
}

.wp-block-media-text .wp-block-media-text__content {
  direction: ltr;
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/
  padding: 0 8% 0 8%;
  word-break: break-word;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
}

.wp-block-media-text__media img,
.wp-block-media-text__media video {
  height: auto;
  max-width: unset;
  width: 100%;
  vertical-align: middle;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media {
  height: 100%;
  min-height: 250px;
  background-size: cover;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media > a {
  display: block;
  height: 100%;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
* Here we here not able to use a mobile first CSS approach.
* Custom widths are set using inline styles, and on mobile,
* we need 100% width, so we use important to overwrite the inline style.
* If the style were set on mobile first, on desktop styles,
* we would have no way of setting the style again to the inline style.
*/
@media (max-width: 600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 100% !important;
  }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    grid-column: 1;
    grid-row: 1;
  }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
    grid-column: 1;
    grid-row: 2;
  }
}
.is-small-text {
  font-size: 0.875em;
}

.is-regular-text {
  font-size: 1em;
}

.is-large-text {
  font-size: 2.25em;
}

.is-larger-text {
  font-size: 3em;
}

.has-drop-cap:not(:focus)::first-letter {
  float: left;
  font-size: 8.4em;
  line-height: 0.68;
  font-weight: 100;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
  font-style: normal;
}

p.has-drop-cap.has-background {
  overflow: hidden;
}

p.has-background {
  padding: 1.25em 2.375em;
}

:where(p.has-text-color:not(.has-link-color)) a {
  color: inherit;
}

.wp-block-post-featured-image {
  margin-left: 0;
  margin-right: 0;
}
.wp-block-post-featured-image a {
  display: block;
}
.wp-block-post-featured-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  box-sizing: border-box;
}
.wp-block-post-featured-image.alignwide img, .wp-block-post-featured-image.alignfull img {
  width: 100%;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim {
  position: absolute;
  inset: 0;
  background-color: #000;
}
.wp-block-post-featured-image {
  position: relative;
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-gradient {
  background-color: transparent;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-0 {
  opacity: 0;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-10 {
  opacity: 0.1;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-20 {
  opacity: 0.2;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-30 {
  opacity: 0.3;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-40 {
  opacity: 0.4;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-50 {
  opacity: 0.5;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-60 {
  opacity: 0.6;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-70 {
  opacity: 0.7;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-80 {
  opacity: 0.8;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-90 {
  opacity: 0.9;
}
.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-100 {
  opacity: 1;
}

.wp-block-post-title {
  word-break: break-word;
  box-sizing: border-box;
}
.wp-block-post-title a {
  display: inline-block;
}

.wp-block-preformatted {
  white-space: pre-wrap;
}

.wp-block-preformatted.has-background {
  padding: 1.25em 2.375em;
}

.wp-block-pullquote {
  margin: 0 0 1em 0;
  padding: 3em 0;
  text-align: center;
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.wp-block-pullquote p,
.wp-block-pullquote blockquote,
.wp-block-pullquote cite {
  color: inherit;
}
.wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
  max-width: 420px;
}
.wp-block-pullquote cite,
.wp-block-pullquote footer {
  position: relative;
}
.wp-block-pullquote .has-text-color a {
  color: inherit;
}

.wp-block-pullquote.has-text-align-left blockquote {
  text-align: left;
}

.wp-block-pullquote.has-text-align-right blockquote {
  text-align: right;
}

.wp-block-pullquote.is-style-solid-color {
  border: none;
}
.wp-block-pullquote.is-style-solid-color blockquote {
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
}
.wp-block-pullquote.is-style-solid-color blockquote p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2em;
}
.wp-block-pullquote.is-style-solid-color blockquote cite {
  text-transform: none;
  font-style: normal;
}

.wp-block-pullquote cite {
  color: inherit;
}

.wp-block-quote {
  box-sizing: border-box;
  overflow-wrap: break-word;
}
.wp-block-quote.is-style-large:not(.is-style-plain), .wp-block-quote.is-large:not(.is-style-plain) {
  margin-bottom: 1em;
  padding: 0 1em;
}
.wp-block-quote.is-style-large:not(.is-style-plain) p, .wp-block-quote.is-large:not(.is-style-plain) p {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.6;
}
.wp-block-quote.is-style-large:not(.is-style-plain) cite,
.wp-block-quote.is-style-large:not(.is-style-plain) footer, .wp-block-quote.is-large:not(.is-style-plain) cite,
.wp-block-quote.is-large:not(.is-style-plain) footer {
  font-size: 1.125em;
  text-align: right;
}

.wp-block-search__button {
  margin-left: 0.625em;
  word-break: normal;
}
.wp-block-search__button.has-icon {
  line-height: 0;
}
.wp-block-search__button svg {
  min-width: 1.5em;
  min-height: 1.5em;
  fill: currentColor;
  vertical-align: text-bottom;
}

:where(.wp-block-search__button) {
  border: 1px solid #ccc;
  padding: 0.375em 0.625em;
}

.wp-block-search__inside-wrapper {
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  max-width: 100%;
}

.wp-block-search__label {
  width: 100%;
}

.wp-block-search__input {
  padding: 8px;
  flex-grow: 1;
  margin-left: 0;
  margin-right: 0;
  min-width: 3em;
  border: 1px solid #949494;
  text-decoration: unset !important;
}

.wp-block-search.wp-block-search__button-only .wp-block-search__button {
  margin-left: 0;
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) {
  padding: 4px;
  border: 1px solid #949494;
}
:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input {
  border-radius: 0;
  border: none;
  padding: 0 0 0 0.25em;
}
:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input:focus {
  outline: none;
}
:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) :where(.wp-block-search__button) {
  padding: 0.125em 0.5em;
}

.wp-block-search.aligncenter .wp-block-search__inside-wrapper {
  margin: auto;
}

.wp-block-separator {
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  border-left: none;
  border-right: none;
}
.wp-block-separator.is-style-dots {
  background: none !important;
  border: none;
  text-align: center;
  line-height: 1;
  height: auto;
}
.wp-block-separator.is-style-dots::before {
  content: "···";
  color: currentColor;
  font-size: 1.5em;
  letter-spacing: 2em;
  /*rtl:ignore*/
  padding-left: 2em;
  font-family: serif;
}

.wp-block-social-links {
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  text-indent: 0;
  margin-left: 0;
  background: none;
}
.wp-block-social-links .wp-social-link a,
.wp-block-social-links .wp-social-link a:hover {
  text-decoration: none;
  border-bottom: 0;
  box-shadow: none;
}
.wp-block-social-links .wp-social-link a {
  padding: 0.25em;
}
.wp-block-social-links .wp-social-link svg {
  width: 1em;
  height: 1em;
}
.wp-block-social-links .wp-social-link span:not(.screen-reader-text) {
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 0.65em;
}
.wp-block-social-links.has-small-icon-size {
  font-size: 16px;
}
.wp-block-social-links, .wp-block-social-links.has-normal-icon-size {
  font-size: 24px;
}
.wp-block-social-links.has-large-icon-size {
  font-size: 36px;
}
.wp-block-social-links.has-huge-icon-size {
  font-size: 48px;
}
.wp-block-social-links.aligncenter {
  justify-content: center;
  display: flex;
}
.wp-block-social-links.alignright {
  justify-content: flex-end;
}

.wp-block-social-link {
  display: block;
  border-radius: 9999px;
  transition: transform 0.1s ease;
  height: auto;
}
@media (prefers-reduced-motion: reduce) {
  .wp-block-social-link {
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
.wp-block-social-link a {
  align-items: center;
  display: flex;
  line-height: 0;
  transition: transform 0.1s ease;
}
.wp-block-social-link:hover {
  transform: scale(1.1);
}

.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor, .wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:hover, .wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:active, .wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:visited,
.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor svg {
  color: currentColor;
  fill: currentColor;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
  background-color: #f0f0f0;
  color: #444;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
  background-color: #f90;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
  background-color: #1ea0c3;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
  background-color: #0757fe;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
  background-color: #1e1f26;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
  background-color: #02e49b;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
  background-color: #e94c89;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
  background-color: #4280ff;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
  background-color: #f45800;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
  background-color: #1778f2;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
  background-color: #000;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
  background-color: #0461dd;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
  background-color: #e65678;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
  background-color: #24292d;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
  background-color: #eceadd;
  color: #382110;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
  background-color: #ea4434;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
  background-color: #f00075;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
  background-color: #e21b24;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
  background-color: #0d66c2;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
  background-color: #3288d4;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
  background-color: #02ab6c;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
  background-color: #f6405f;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-patreon {
  background-color: #ff424d;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
  background-color: #e60122;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
  background-color: #ef4155;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
  background-color: #ff4500;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
  background-color: #0478d7;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
  background-color: #fefc00;
  color: #fff;
  stroke: #000;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
  background-color: #ff5600;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
  background-color: #1bd760;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-telegram {
  background-color: #2aabee;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tiktok {
  background-color: #000;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
  background-color: #011835;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
  background-color: #6440a4;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
  background-color: #1da1f2;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
  background-color: #1eb7ea;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
  background-color: #4680c2;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
  background-color: #3499cd;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-whatsapp {
  background-color: #25d366;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
  background-color: #d32422;
  color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
  background-color: #f00;
  color: #fff;
}

.wp-block-social-links.is-style-logos-only .wp-social-link {
  background: none;
}
.wp-block-social-links.is-style-logos-only .wp-social-link a {
  padding: 0;
}
.wp-block-social-links.is-style-logos-only .wp-social-link svg {
  width: 1.25em;
  height: 1.25em;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
  color: #f90;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
  color: #1ea0c3;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-behance {
  color: #0757fe;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
  color: #1e1f26;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
  color: #02e49b;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
  color: #e94c89;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
  color: #4280ff;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
  color: #f45800;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
  color: #1778f2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
  color: #000;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
  color: #0461dd;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
  color: #e65678;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-github {
  color: #24292d;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
  color: #382110;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-google {
  color: #ea4434;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
  color: #f00075;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
  color: #e21b24;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
  color: #0d66c2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
  color: #3288d4;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-medium {
  color: #02ab6c;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
  color: #f6405f;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-patreon {
  color: #ff424d;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
  color: #e60122;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
  color: #ef4155;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
  color: #ff4500;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-skype {
  color: #0478d7;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
  color: #fff;
  stroke: #000;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
  color: #ff5600;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
  color: #1bd760;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-telegram {
  color: #2aabee;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-tiktok {
  color: #000;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
  color: #011835;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
  color: #6440a4;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
  color: #1da1f2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
  color: #1eb7ea;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-vk {
  color: #4680c2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-whatsapp {
  color: #25d366;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
  color: #3499cd;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
  color: #d32422;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
  color: #f00;
}

.wp-block-social-links.is-style-pill-shape .wp-social-link {
  width: auto;
}
.wp-block-social-links.is-style-pill-shape .wp-social-link a {
  padding-left: 0.6666666667em;
  padding-right: 0.6666666667em;
}

.wp-block-spacer {
  clear: both;
}

.wp-block-table {
  overflow-x: auto;
}
.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}
.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word;
}
.wp-block-table.alignleft, .wp-block-table.aligncenter, .wp-block-table.alignright {
  display: table;
  width: auto;
}
.wp-block-table.alignleft td,
.wp-block-table.alignleft th, .wp-block-table.aligncenter td,
.wp-block-table.aligncenter th, .wp-block-table.alignright td,
.wp-block-table.alignright th {
  word-break: break-word;
}
.wp-block-table .has-subtle-light-gray-background-color {
  background-color: #f3f4f5;
}
.wp-block-table .has-subtle-pale-green-background-color {
  background-color: #e9fbe5;
}
.wp-block-table .has-subtle-pale-blue-background-color {
  background-color: #e7f5fe;
}
.wp-block-table .has-subtle-pale-pink-background-color {
  background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes {
  border-spacing: 0;
  border-collapse: inherit;
  background-color: transparent;
  border-bottom: 1px solid #f0f0f0;
}
.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}
.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
  background-color: #f3f4f5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
  background-color: #e9fbe5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
  background-color: #e7f5fe;
}
.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
  background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes th,
.wp-block-table.is-style-stripes td {
  border-color: transparent;
}
.wp-block-table .has-border-color > *,
.wp-block-table .has-border-color tr,
.wp-block-table .has-border-color th,
.wp-block-table .has-border-color td {
  border-color: inherit;
}
.wp-block-table table[style*=border-top-color] > *,
.wp-block-table table[style*=border-top-color] tr:first-child {
  border-top-color: inherit;
}
.wp-block-table table[style*=border-top-color] > * th,
.wp-block-table table[style*=border-top-color] > * td,
.wp-block-table table[style*=border-top-color] tr:first-child th,
.wp-block-table table[style*=border-top-color] tr:first-child td {
  border-top-color: inherit;
}
.wp-block-table table[style*=border-top-color] tr:not(:first-child) {
  border-top-color: currentColor;
}
.wp-block-table table[style*=border-right-color] > *,
.wp-block-table table[style*=border-right-color] tr,
.wp-block-table table[style*=border-right-color] th,
.wp-block-table table[style*=border-right-color] td:last-child {
  border-right-color: inherit;
}
.wp-block-table table[style*=border-bottom-color] > *,
.wp-block-table table[style*=border-bottom-color] tr:last-child {
  border-bottom-color: inherit;
}
.wp-block-table table[style*=border-bottom-color] > * th,
.wp-block-table table[style*=border-bottom-color] > * td,
.wp-block-table table[style*=border-bottom-color] tr:last-child th,
.wp-block-table table[style*=border-bottom-color] tr:last-child td {
  border-bottom-color: inherit;
}
.wp-block-table table[style*=border-bottom-color] tr:not(:last-child) {
  border-bottom-color: currentColor;
}
.wp-block-table table[style*=border-left-color] > *,
.wp-block-table table[style*=border-left-color] tr,
.wp-block-table table[style*=border-left-color] th,
.wp-block-table table[style*=border-left-color] td:first-child {
  border-left-color: inherit;
}
.wp-block-table table[style*=border-style] > *,
.wp-block-table table[style*=border-style] tr,
.wp-block-table table[style*=border-style] th,
.wp-block-table table[style*=border-style] td {
  border-style: inherit;
}
.wp-block-table table[style*=border-width] > *,
.wp-block-table table[style*=border-width] tr,
.wp-block-table table[style*=border-width] th,
.wp-block-table table[style*=border-width] td {
  border-width: inherit;
  border-style: inherit;
}

.wp-block-text-columns {
  display: flex;
}
.wp-block-text-columns.aligncenter {
  display: flex;
}
.wp-block-text-columns .wp-block-column {
  margin: 0 1em;
  padding: 0;
}
.wp-block-text-columns .wp-block-column:first-child {
  margin-left: 0;
}
.wp-block-text-columns .wp-block-column:last-child {
  margin-right: 0;
}
.wp-block-text-columns.columns-2 .wp-block-column {
  width: 50%;
}
.wp-block-text-columns.columns-3 .wp-block-column {
  width: 33.3333333333%;
}
.wp-block-text-columns.columns-4 .wp-block-column {
  width: 25%;
}

pre.wp-block-verse {
  font-family: inherit;
  overflow: auto;
  white-space: pre-wrap;
}

.wp-block-video {
  box-sizing: border-box;
}
.wp-block-video video {
  width: 100%;
}
@supports (position: sticky) {
  .wp-block-video [poster] {
    object-fit: cover;
  }
}
.wp-block-video.aligncenter {
  text-align: center;
}
.wp-block-video figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

/**
 * Element styles.
 */
.wp-element-button {
  cursor: pointer;
}

:root {
  /*
   * Our classes uses the same values we set for gradient value attributes,
   * and we can not use spacing because of WP multi site kses rule.
   */
  /* stylelint-disable function-comma-space-after */
  /* stylelint-enable function-comma-space-after */
  --wp--preset--font-size--normal: 16px;
  --wp--preset--font-size--huge: 42px;
}
:root .has-very-light-gray-background-color {
  background-color: #eee;
}
:root .has-very-dark-gray-background-color {
  background-color: #313131;
}
:root .has-very-light-gray-color {
  color: #eee;
}
:root .has-very-dark-gray-color {
  color: #313131;
}
:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: linear-gradient(135deg, rgb(0, 208, 132) 0%, rgb(6, 147, 227) 100%);
}
:root .has-purple-crush-gradient-background {
  background: linear-gradient(135deg, rgb(52, 226, 228) 0%, rgb(71, 33, 251) 50%, rgb(171, 29, 254) 100%);
}
:root .has-hazy-dawn-gradient-background {
  background: linear-gradient(135deg, rgb(250, 172, 168) 0%, rgb(218, 208, 236) 100%);
}
:root .has-subdued-olive-gradient-background {
  background: linear-gradient(135deg, rgb(250, 250, 225) 0%, rgb(103, 166, 113) 100%);
}
:root .has-atomic-cream-gradient-background {
  background: linear-gradient(135deg, rgb(253, 215, 154) 0%, rgb(0, 74, 89) 100%);
}
:root .has-nightshade-gradient-background {
  background: linear-gradient(135deg, rgb(51, 9, 104) 0%, rgb(49, 205, 207) 100%);
}
:root .has-midnight-gradient-background {
  background: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
}

.has-regular-font-size {
  font-size: 1em;
}

.has-larger-font-size {
  font-size: 2.625em;
}

.has-normal-font-size {
  font-size: var(--wp--preset--font-size--normal);
}

.has-huge-font-size {
  font-size: var(--wp--preset--font-size--huge);
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-left {
  /*rtl:ignore*/
  text-align: left;
}

.has-text-align-right {
  /*rtl:ignore*/
  text-align: right;
}

#end-resizable-editor-section {
  display: none;
}

.aligncenter {
  clear: both;
}

.items-justified-left {
  justify-content: flex-start;
}

.items-justified-center {
  justify-content: center;
}

.items-justified-right {
  justify-content: flex-end;
}

.items-justified-space-between {
  justify-content: space-between;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #ddd;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/**
 * The following provide a simple means of applying a default border style when
 * a user first makes a selection in the border block support panel.
 * This prevents issues such as where the user could set a border width
 * and see no border due there being no border style set.
 *
 * This is intended to be removed once intelligent defaults can be set while
 * making border selections via the block support.
 *
 * See: https://github.com/WordPress/gutenberg/pull/33743
 */
html :where(.has-border-color) {
  border-style: solid;
}

html :where([style*=border-top-color]) {
  border-top-style: solid;
}

html :where([style*=border-right-color]) {
  border-right-style: solid;
}

html :where([style*=border-bottom-color]) {
  border-bottom-style: solid;
}

html :where([style*=border-left-color]) {
  border-left-style: solid;
}

html :where([style*=border-width]) {
  border-style: solid;
}

html :where([style*=border-top-width]) {
  border-top-style: solid;
}

html :where([style*=border-right-width]) {
  border-right-style: solid;
}

html :where([style*=border-bottom-width]) {
  border-bottom-style: solid;
}

html :where([style*=border-left-width]) {
  border-left-style: solid;
}

/**
 * Provide baseline responsiveness for images.
 */
html :where(img[class*=wp-image-]) {
  height: auto;
  max-width: 100%;
}

/**
 * Reset user agent styles for figure element margins.
 */
figure {
  margin: 0 0 1em 0;
}

.BlogRow {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.BlogRow-item {
  width: 100%;
}
@media (min-width: 768px) {
  .BlogRow-item {
    width: calc(50% - 16px);
  }
}
.BlogRow-item:hover h2 {
  color: #005cb3;
}

.Card-content h2, .aside .menu h2 {
  font-size: 18px;
  line-height: 30px;
}

.Card-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.7);
}

.RMP-Post--CopyContainer {
  max-width: 768px;
}

.RMP-Social {
  display: block;
  text-align: center;
  margin: 4rem 0;
}
.RMP-Social a {
  margin: 0 8px;
}

.SocialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.RMP-PostTease-Image {
  width: 100%;
  height: 200px;
  background-color: #005cb3;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.RMP-PostTease-Image img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.has-icons-left {
  position: relative;
}
.has-icons-left .RMP-UserWidget--FormInput {
  padding-left: 32px;
}
.has-icons-left .RMP-UserWidget--Icon {
  position: absolute;
  top: 50%;
  transform: translate(8px, -50%);
}

.RMP-UserWidget--Form .RMP-UserWidget--Button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.has-icons-left {
  position: relative;
}
.has-icons-left .RMP-UserWidget--Icon {
  position: absolute;
  top: 50%;
  transform: translate(8px, -50%);
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.text-link::after,
.far,
.Form-selectDropDown::before,
.Form-search::before,
.select:after,
.Form-field .v-select .open-indicator:before,
.filter.checkboxes li.selected > a:before,
.Form-field--checkbox .Form-input:checked + .Form-label:before,
.field .checkbox input[type=checkbox]:checked + label:before,
.select-field:after,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-address-card:before {
  content: "\f2bb";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-apple:before {
  content: "\f179";
}

.fa-archive:before {
  content: "\f187";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-books:before {
  content: "\f5db";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clipboard-list-check:before {
  content: "\f737";
}

.fa-clock:before {
  content: "\f017";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cog:before {
  content: "\f013";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-alt-slash:before {
  content: "\f4a9";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comments:before {
  content: "\f086";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-edit:before {
  content: "\f044";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-flag:before {
  content: "\f024";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-heart:before {
  content: "\f004";
}

.fa-industry:before {
  content: "\f275";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-marker-smile:before {
  content: "\f60d";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-megaphone:before {
  content: "\f675";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-play:before {
  content: "\f04b";
}

.fa-plus:before {
  content: "\f067";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-presentation:before {
  content: "\f685";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-search:before {
  content: "\f002";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-spinner-third:before {
  content: "\f3f4";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-sync:before {
  content: "\f021";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-times:before {
  content: "\f00d";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-trophy-alt:before {
  content: "\f2eb";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-university:before {
  content: "\f19c";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-video:before {
  content: "\f03d";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/dist/fontawesome/fa-solid-900.eot");
  src: url("/dist/fontawesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/dist/fontawesome/fa-solid-900.woff2") format("woff2"), url("/dist/fontawesome/fa-solid-900.woff") format("woff"), url("/dist/fontawesome/fa-solid-900.ttf") format("truetype"), url("/dist/fontawesome/fa-solid-900.svg#fontawesome") format("svg");
}
.fa,
.fas,
.text-link::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/dist/fontawesome/fa-regular-400.eot");
  src: url("/dist/fontawesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/dist/fontawesome/fa-regular-400.woff2") format("woff2"), url("/dist/fontawesome/fa-regular-400.woff") format("woff"), url("/dist/fontawesome/fa-regular-400.ttf") format("truetype"), url("/dist/fontawesome/fa-regular-400.svg#fontawesome") format("svg");
}
.far, .Form-selectDropDown::before, .Form-search::before, .select:after, .Form-field .v-select .open-indicator:before, .filter.checkboxes li.selected > a:before, .Form-field--checkbox .Form-input:checked + .Form-label:before,
.field .checkbox input[type=checkbox]:checked + label:before, .select-field:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/dist/fontawesome/fa-brands-400.eot");
  src: url("/dist/fontawesome/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("/dist/fontawesome/fa-brands-400.woff2") format("woff2"), url("/dist/fontawesome/fa-brands-400.woff") format("woff"), url("/dist/fontawesome/fa-brands-400.ttf") format("truetype"), url("/dist/fontawesome/fa-brands-400.svg#fontawesome") format("svg");
}
.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.autoComplete_wrapper {
  position: relative;
}

.autoComplete_wrapper > input::placeholder {
  color: rgba(117, 117, 117, 0.5);
  transition: all 0.3s ease;
}

.autoComplete_wrapper > input:hover::placeholder {
  color: rgb(117, 117, 117);
  transition: all 0.3s ease;
}

.autoComplete_wrapper > input:focus::placeholder {
  padding: 0.1rem 0.6rem;
  font-size: 0.95rem;
  color: rgb(117, 117, 117);
}

.autoComplete_wrapper > input:focus::selection {
  background-color: #333;
}

.autoComplete_wrapper > input::selection {
  background-color: #333;
}

.autoComplete_wrapper > input:hover {
  color: #333;
  transition: all 0.3s ease;
}

.autoComplete_wrapper > input:focus {
  color: #333;
  border: 0.06rem solid #333;
}

.autoComplete_wrapper > ul {
  position: absolute;
  max-height: 226px;
  overflow-y: scroll;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin: 0.5rem 0 0 0;
  padding: 0;
  z-index: 1;
  list-style: none;
  border-radius: 0.6rem;
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, 0.07);
  box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
  outline: none;
}

.autoComplete_wrapper > ul[hidden],
.autoComplete_wrapper > ul:empty {
  display: block;
  opacity: 0;
  transform: scale(0);
}

.autoComplete_wrapper > ul > li {
  margin: 0.3rem;
  padding: 0.3rem 0.5rem;
  text-align: left;
  font-size: 1rem;
  color: #212121;
  border-radius: 0.35rem;
  background-color: rgb(255, 255, 255);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autoComplete_wrapper > ul > li mark {
  background-color: transparent;
  color: #005cb3;
  font-weight: bold;
}

.autoComplete_wrapper > ul > li:hover {
  cursor: pointer;
  background-color: #005cb3;
  color: #d2d0d0;
}
.autoComplete_wrapper > ul > li:hover mark {
  color: #FFF;
}

.autoComplete_wrapper > ul > li[aria-selected=true] {
  background-color: #005cb3;
  color: #d2d0d0;
}
.autoComplete_wrapper > ul > li[aria-selected=true] mark {
  color: #FFF;
}

/* RESET ============================================================================= */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #005cb3;
}
a:hover, a:focus {
  color: #32b056;
}

ol, ul {
  list-style: none;
  list-style-position: outside;
  margin-bottom: 1rem;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

audio[controls], canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/* Typography ========================================================================== */
abbr[title] {
  border-bottom: 0;
  text-decoration: none;
}

b, strong {
  font-weight: bold;
}

blockquote {
  margin: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  color: #000;
  font-weight: bold;
}

/* Redeclare monospace font family: en.wikipedia.org/wiki/User:Davidgothberg/Test59 */
pre, code, kbd, samp {
  font-family: monospace, monospace;
  _font-family: "courier new", monospace;
  font-size: 1em;
}

/* Improve readability of pre-formatted text in all browsers */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

/* Position subscript and superscript content without affecting line-height: gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/*  Lists  ========================================================================== */
dd {
  margin: 0;
}

nav ul, nav ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*  Figures  ========================================================================== */
figure {
  margin: 0;
}

/* Forms  ========================================================================== */
label {
  cursor: pointer;
}

button, input, select, textarea {
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input[type=button], input[type=reset], input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
}

input[type=search] {
  box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

/* apply a natural box layout model to all elements */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.page-wrapper {
  position: relative;
}
.page-wrapper::before, .page-wrapper::after {
  display: table;
  content: " ";
}
.page-wrapper::after {
  clear: both;
}

main {
  display: block;
  margin-bottom: 8em;
}
main::before, main::after {
  display: table;
  content: " ";
}
main::after {
  clear: both;
}
main.HomePageTwo {
  margin-bottom: 0;
}

html, body {
  width: 100%;
  max-width: 100%;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}
@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}
@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}
@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

h1, h2, h3, h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: aktiv-grotesk, Helvetica Neue, Helvetica, sans-serif;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 24px;
}

h2,
.h2 {
  font-size: 24px;
  letter-spacing: -0.16px;
  line-height: 40px;
  margin-bottom: 24px;
}

h3,
.h3 {
  font-size: 20px;
  letter-spacing: -0.16px;
  line-height: 30px;
  margin-bottom: 16px;
}

h4,
.h4 {
  font-size: 18px;
  letter-spacing: 0.22px;
  line-height: 24px;
  margin-bottom: 8px;
}

.leading-text {
  font-size: 18px;
  color: #222222;
  text-align: left;
  line-height: 27px;
}

p {
  font-size: 16px;
  color: #222222;
  letter-spacing: 0.23px;
  line-height: 24px;
  margin-bottom: 24px;
}

li {
  margin-bottom: 0.5rem;
  position: relative;
}

.SubHeader-small {
  font-size: 16px;
  color: #222222;
  letter-spacing: 0.23px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: normal;
}

.TitleHeader {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .TitleHeader {
    font-size: 24px;
    letter-spacing: -0.16px;
    line-height: 1.2;
  }
}

.u-justifyLeft {
  text-align: left;
}

.u-justifyRight {
  text-align: right;
}

.u-center {
  text-align: center;
}

.u-alignLeft {
  margin-right: auto;
  float: left;
}

.u-alignRight {
  margin-left: auto;
  float: right !important;
}

.u-colCenter {
  display: block;
  align-self: center;
  margin: auto;
  float: none !important;
}

.valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.clearfix::before, .Block.Block--profile::before, .SidebarRight-content::before, .no-flexbox .Row::before,
.no-flexbox .Column::before, .clearfix::after, .Block.Block--profile::after, .SidebarRight-content::after, .no-flexbox .Row::after,
.no-flexbox .Column::after {
  display: table;
  content: " ";
}
.clearfix::after, .Block.Block--profile::after, .SidebarRight-content::after, .no-flexbox .Row::after,
.no-flexbox .Column::after {
  clear: both;
}

.u-flex-spaceBetween {
  justify-content: space-between;
}

.u-flex-alignCenter {
  align-items: center;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex {
  display: flex !important;
}

.u-flex-grow {
  flex-grow: 1;
}

.u-flex-column {
  flex-direction: column;
}

.u-hidden-xs {
  display: none;
}
@media (min-width: 33.75em) {
  .u-hidden-xs {
    display: block;
  }
}

@media (min-width: 768px) {
  .u-hidden-sm {
    display: none;
  }
}

@media (min-width: 1024px) {
  .u-hidden-md {
    display: none;
  }
}

/*.u-hidden-l {
  display: block;
  @include respond-to(md) {
    display: none;
  }
  @include respond-to(lg) {
    display: block;
  }
}*/
.u-hidden-lg {
  display: block;
}
@media (min-width: 1380px) {
  .u-hidden-lg {
    display: none;
  }
}

.js .hidden {
  display: none;
}

.u-noMargin {
  margin: 0 !important;
}

.u-noMarginTop {
  margin-top: 0 !important;
}

.u-noMarginBottom {
  margin-bottom: 0 !important;
}

.u-noMarginLeft {
  margin-left: 0 !important;
}

.u-noMarginRight {
  margin-right: 0 !important;
}

.u-noPadding {
  padding: 0 !important;
}

.u-noPaddingTop {
  padding-top: 0 !important;
}

.u-noPaddingBottom {
  padding-bottom: 0 !important;
}

.u-noPaddingLeft {
  padding-left: 0 !important;
}

.u-noPaddingRight {
  padding-right: 0 !important;
}

[v-cloak] .v-cloak--block {
  display: block;
}

[v-cloak] .v-cloak--inline {
  display: inline;
}

[v-cloak] .v-cloak--inlineBlock {
  display: inline-block;
}

[v-cloak] .v-cloak--hidden {
  display: none;
}

[v-cloak] .v-cloak--invisible {
  visibility: hidden;
}

.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
  display: none;
}

.Bg-blue,
.inner-blue {
  background: #005cb3;
}

.c-primary {
  color: #005cb3;
}

.c-green {
  color: #32b056;
}

.c-red {
  color: #ff5043;
}

.color-primary {
  color: #005cb3;
}

html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-family: aktiv-grotesk, Helvetica Neue, Helvetica, sans-serif;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: aktiv-grotesk, Helvetica Neue, Helvetica, sans-serif;
}

body {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #005cb3;
  cursor: pointer;
  text-decoration: none;
}

hr {
  background-color: #DDDDDD;
  border: none;
  display: block;
  height: 1px;
  margin-top: 64px;
  margin-bottom: 64px;
}

img {
  height: auto;
  max-width: 100%;
}

input[type=checkbox],
input[type=radio] {
  vertical-align: baseline;
}

small {
  font-size: 13px;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: 600;
}

fieldset {
  border: none;
}

table,
td,
th {
  vertical-align: top;
}
table:not([align]),
td:not([align]),
th:not([align]) {
  text-align: inherit;
}

#breadcrumbs li:not(:last-child):after {
  content: "/";
}

.color-primary {
  color: #005cb3;
}

.spacer-md {
  height: 16px;
  width: 16px;
  display: block;
}

.Card.has-hover, .aside .has-hover.menu,
.has-hover {
  border: 2px solid transparent;
  box-shadow: none;
}
.Card.has-hover:hover,
.has-hover:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12);
}

/*------------------------------------*\
    GRID FUNCTION
\*------------------------------------*/
/**
 * Space between columns
 */
/**
 * Number of total columns
 */
/* PANEL ============================================================================= */
/*
$styleguide

$title Grid

12 Column Flexbox grid system with float fallback, Todo - extend to Grid future proof

Container =
  .Container
Grid Row =
  .Row
Columns
  @mixin Col-lg-(column number) desktop, Col-md-(number) medium size screen, .Col-sm-(number) mobile / small size screen <br>

<div class='Container'>
       <div class='Row'>
           <div class='Col-sm-12'>
               <div class='box-row'> 12 </div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-1'>
               <div class='box-row'> 1 </div>
           </div>
           <div class='Col-sm-11'>
               <div class='box-row'> 11 </div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-2'>
               <div class='box-row'> 2 </div>
           </div>
           <div class='Col-sm-10'>
               <div class='box-row'>10</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-3'>
               <div class='box-row'>3</div>
           </div>
           <div class='Col-sm-9'>
               <div class='box-row'>9</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-4'>
               <div class='box-row'>4</div>
           </div>
           <div class='Col-sm-8'>
               <div class='box-row'>8</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-9 Col-sm-5'>
               <div class='box-row'>5</div>
           </div>
           <div class='Col-sm3 Col-sm-7'>
               <div class='box-row'>7</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-6 Col-md-6'>
               <div class='box-row'>6</div>
           </div>
           <div class='Col-6 Col-md-6'>
              <div class='box-row'>6</div>
           </div>
       </div>
</div>

*/
[class^=Col-],
[class*=Col-] {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  /*  @include respond-to(md) {
      padding: $space-default;
    }*/
  box-sizing: border-box;
}
[class^=Col-].Col--pullRight,
[class*=Col-].Col--pullRight {
  margin-left: auto;
  float: Right;
}
[class^=Col-].Col--pullLeft,
[class*=Col-].Col--pullLeft {
  margin-left: auto;
  float: left;
}

@media (min-width: 33.75em) {
  .Col-xs-offset-0 {
    margin-left: 0%;
  }
  .Col-xs-1 {
    width: 8.3333333333%;
  }
  .Col-xs-offset-1 {
    margin-left: 8.3333333333%;
  }
  .Col-xs-2 {
    width: 16.6666666667%;
  }
  .Col-xs-offset-2 {
    margin-left: 16.6666666667%;
  }
  .Col-xs-3 {
    width: 25%;
  }
  .Col-xs-offset-3 {
    margin-left: 25%;
  }
  .Col-xs-4 {
    width: 33.3333333333%;
  }
  .Col-xs-offset-4 {
    margin-left: 33.3333333333%;
  }
  .Col-xs-5 {
    width: 41.6666666667%;
  }
  .Col-xs-offset-5 {
    margin-left: 41.6666666667%;
  }
  .Col-xs-6 {
    width: 50%;
  }
  .Col-xs-offset-6 {
    margin-left: 50%;
  }
  .Col-xs-7 {
    width: 58.3333333333%;
  }
  .Col-xs-offset-7 {
    margin-left: 58.3333333333%;
  }
  .Col-xs-8 {
    width: 66.6666666667%;
  }
  .Col-xs-offset-8 {
    margin-left: 66.6666666667%;
  }
  .Col-xs-9 {
    width: 75%;
  }
  .Col-xs-offset-9 {
    margin-left: 75%;
  }
  .Col-xs-10 {
    width: 83.3333333333%;
  }
  .Col-xs-offset-10 {
    margin-left: 83.3333333333%;
  }
  .Col-xs-11 {
    width: 91.6666666667%;
  }
  .Col-xs-offset-11 {
    margin-left: 91.6666666667%;
  }
  .Col-xs-12 {
    width: 100%;
  }
  .Col-xs-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .Col-sm-offset-0 {
    margin-left: 0%;
  }
  .Col-sm-1 {
    width: 8.3333333333%;
  }
  .Col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .Col-sm-2 {
    width: 16.6666666667%;
  }
  .Col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .Col-sm-3 {
    width: 25%;
  }
  .Col-sm-offset-3 {
    margin-left: 25%;
  }
  .Col-sm-4 {
    width: 33.3333333333%;
  }
  .Col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .Col-sm-5 {
    width: 41.6666666667%;
  }
  .Col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .Col-sm-6 {
    width: 50%;
  }
  .Col-sm-offset-6 {
    margin-left: 50%;
  }
  .Col-sm-7 {
    width: 58.3333333333%;
  }
  .Col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .Col-sm-8 {
    width: 66.6666666667%;
  }
  .Col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .Col-sm-9 {
    width: 75%;
  }
  .Col-sm-offset-9 {
    margin-left: 75%;
  }
  .Col-sm-10 {
    width: 83.3333333333%;
  }
  .Col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .Col-sm-11 {
    width: 91.6666666667%;
  }
  .Col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .Col-sm-12 {
    width: 100%;
  }
  .Col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1024px) {
  .Col-md-offset-0 {
    margin-left: 0%;
  }
  .Col-md-1 {
    width: 8.3333333333%;
  }
  .Col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .Col-md-2 {
    width: 16.6666666667%;
  }
  .Col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .Col-md-3 {
    width: 25%;
  }
  .Col-md-offset-3 {
    margin-left: 25%;
  }
  .Col-md-4 {
    width: 33.3333333333%;
  }
  .Col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .Col-md-5 {
    width: 41.6666666667%;
  }
  .Col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .Col-md-6 {
    width: 50%;
  }
  .Col-md-offset-6 {
    margin-left: 50%;
  }
  .Col-md-7 {
    width: 58.3333333333%;
  }
  .Col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .Col-md-8 {
    width: 66.6666666667%;
  }
  .Col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .Col-md-9 {
    width: 75%;
  }
  .Col-md-offset-9 {
    margin-left: 75%;
  }
  .Col-md-10 {
    width: 83.3333333333%;
  }
  .Col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .Col-md-11 {
    width: 91.6666666667%;
  }
  .Col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .Col-md-12 {
    width: 100%;
  }
  .Col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1380px) {
  .Col-lg-offset-0 {
    margin-left: 0%;
  }
  .Col-lg-1 {
    width: 8.3333333333%;
  }
  .Col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .Col-lg-2 {
    width: 16.6666666667%;
  }
  .Col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .Col-lg-3 {
    width: 25%;
  }
  .Col-lg-offset-3 {
    margin-left: 25%;
  }
  .Col-lg-4 {
    width: 33.3333333333%;
  }
  .Col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .Col-lg-5 {
    width: 41.6666666667%;
  }
  .Col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .Col-lg-6 {
    width: 50%;
  }
  .Col-lg-offset-6 {
    margin-left: 50%;
  }
  .Col-lg-7 {
    width: 58.3333333333%;
  }
  .Col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .Col-lg-8 {
    width: 66.6666666667%;
  }
  .Col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .Col-lg-9 {
    width: 75%;
  }
  .Col-lg-offset-9 {
    margin-left: 75%;
  }
  .Col-lg-10 {
    width: 83.3333333333%;
  }
  .Col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .Col-lg-11 {
    width: 91.6666666667%;
  }
  .Col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .Col-lg-12 {
    width: 100%;
  }
  .Col-lg-offset-12 {
    margin-left: 100%;
  }
}
.Container {
  display: block;
  width: 100%;
  max-width: 1344px;
}
@media (min-width: 33.75em) {
  .Container {
    margin: auto;
  }
}
@media (min-width: 1380px) {
  .Container {
    margin: auto;
  }
}
@media (min-width: 1380px) {
  .Container {
    margin: auto;
  }
}

.Row {
  flex-direction: row;
  width: 100%;
}
.Row.Row--reverse {
  flex-direction: row-reverse;
}
.Row.Row--centerItem {
  justify-content: center;
}

.Column {
  flex-direction: column;
}
.Column.Column--reverse {
  flex-direction: column-reverse;
}

.no-flexbox .Row,
.no-flexbox .Column {
  display: block;
}

.Row,
.Column {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.Row.no-wrap,
.Column.no-wrap {
  flex-wrap: nowrap;
}
.Row.no-padding [class^=Col-],
.Row.no-padding [class*=Col-],
.Column.no-padding [class^=Col-],
.Column.no-padding [class*=Col-] {
  padding: 0;
}
.Row > .Row-title,
.Column > .Row-title {
  display: block;
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
}
.Row .box-row,
.Column .box-row {
  position: relative;
  height: 100%;
  min-height: 40px;
  background: #ddd;
}
.Row.no-gutter,
.Column.no-gutter {
  margin: 0;
}
.Row.no-gutter [class*=Col-], .Row.no-gutter [class^=Col-],
.Column.no-gutter [class*=Col-],
.Column.no-gutter [class^=Col-] {
  margin-bottom: 1rem;
  padding: 0;
}
.Row.no-gutter [class*=Col-]:last-child, .Row.no-gutter [class^=Col-]:last-child,
.Column.no-gutter [class*=Col-]:last-child,
.Column.no-gutter [class^=Col-]:last-child {
  margin-bottom: 0;
}

body {
  background: #fff;
}
body.page-dashboard, body.control-panel {
  background: #fff;
}

.SearchFilters {
  width: 25% \9 ;
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 1024px) {
  .SearchFilters {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.SearchFilters .search-filteration {
  padding: 1em \9 ;
  background: #fff;
}
@media (min-width: 1024px) {
  .SearchFilters .search-filteration {
    height: 100%;
    padding: 1em;
  }
}

.MegaSearch {
  margin-bottom: 0;
}

.content.page-company-az .MegaSearch .top-header {
  margin: 0;
}

@media screen and (max-width: 600px) {
  .list-view .Col-xs-12,
.Col-sm-12 {
    padding-right: 0;
    padding-left: 0;
  }
  .list-view .Col-xs-12 > .Block,
.Col-sm-12 > .Block {
    border-right: none;
    border-left: none;
  }
}
.Header {
  background-color: #004b92;
}
@media (max-width: 768px) {
  .Header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 8;
  }
}
.Header .Container {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
}

.Header-homeLink {
  font-size: 0;
}
@media (min-width: 1130px) {
  .Header-homeLink {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .Header-logo {
    width: 160px;
  }
}
@media (min-width: 768px) {
  .Header-logo {
    width: 240px;
  }
}
@media (min-width: 1250px) {
  .Header-logo {
    width: 300px;
  }
}

.Navigation {
  display: flex;
  align-items: center;
}

.Navigation-items {
  display: flex;
}

.Navigation-item {
  position: relative;
  margin: 0;
}

.Navigation-link {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  height: 70px;
  padding: 0 0.5em;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.Navigation-link:focus, .Navigation-link:active, .Navigation-link:hover {
  background: #005cb3;
  color: #fff;
}
@media (max-width: 768px) {
  .Navigation-link {
    height: 50px;
  }
}
.Navigation-link span {
  margin-left: 4px;
}

.Navigation-subnav {
  display: none;
  background: #004b92;
  border: 1px solid #004b92;
  left: 0;
  position: absolute;
  top: 70px;
  width: 150px;
  z-index: 4;
}
.Navigation-subnav .Navigation-link {
  height: auto;
  padding: 10px;
  display: block;
}
.Navigation-subnav .Navigation-link span {
  margin-left: 0;
}

.Navigation-authentication .Navigation-items {
  display: flex;
  align-items: center;
}

.Navigation-twoColumns {
  width: 450px;
}
.Navigation-twoColumns .Navigation-item {
  display: inline-block;
  float: none;
  margin-right: -4px;
  vertical-align: top;
  width: 50%;
}

.Has-subnav:hover .Navigation-subnav {
  display: block;
}

.mobile-nav,
.mobile-menu {
  display: block;
  color: #fff;
}
@media screen and (min-width: 1131px) {
  .mobile-nav,
.mobile-menu {
    display: none;
  }
}

.MenuOverlay {
  display: none;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  top: 50px;
}
@media (min-width: 768px) {
  .MenuOverlay {
    top: 70px;
  }
}
.MenuOverlay.menu-open {
  display: block;
}
@media (min-width: 1130px) {
  .MenuOverlay.menu-open {
    display: none;
  }
}

.Navigation--main {
  width: 610px;
}
@media (max-width: 1130px) {
  .Navigation--main {
    display: none;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow: scroll;
    z-index: 4;
    background: #004b92;
    flex-direction: column;
    max-width: 100%;
    width: 400px;
  }
  .Navigation--main .Navigation-subnav {
    width: 100%;
    position: relative;
    top: 0;
    display: block;
  }
  .Navigation--main .Navigation-subnav .Navigation-link {
    height: 40px;
  }
  .Navigation--main .Navigation-link {
    height: 40px;
    padding-left: 16px;
  }
  .Navigation--main span {
    display: none;
  }
  .Navigation--main.menu-open {
    display: block;
  }
}
@media only screen and (max-width: 1130px) and (min-width: 768px) {
  .Navigation--main {
    top: 70px;
  }
}
@media (max-width: 768px) {
  .Navigation--main {
    top: 50px;
  }
}

@media (min-width: 768px) {
  .AccountNav-login {
    margin-left: 4px;
  }
}
.AccountNav-login .Navigation-link {
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  height: auto;
  line-height: 1;
  padding: 8px 11px;
  text-transform: uppercase;
  white-space: pre;
}
.AccountNav-login .Navigation-link:hover {
  background: #005cb3;
}

.AccountNav-register {
  display: none;
  margin-left: 1rem;
}
.AccountNav-register .Navigation-link {
  border: none;
  height: auto;
  line-height: 1;
  padding: 9px 11px;
  font-size: 14px;
}
@media (min-width: 768px) {
  .AccountNav-register {
    display: block;
  }
}

.Notification-trigger {
  padding: 0.9rem;
  cursor: pointer;
  text-align: center;
  width: 47px;
}
.Notification-trigger .span {
  margin: 0;
}

.Notification-count {
  position: absolute;
  top: 50%;
  transform: translate(-4px, -18px);
  line-height: 1;
  left: 26px;
  padding: 2px 4px;
  border-radius: 6px;
  background: #FF3E3E;
  color: #fff;
  font-size: 12px;
  border: 1px solid #005cb3;
  font-weight: 700;
  transition: ease-in-out 0.3s;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.Notification-count.Notification--countZero {
  font-size: 0;
  height: 8px;
  width: 8px;
  padding: 0;
  top: 26px;
}

.Notification-triggerIcon {
  display: block;
  color: #fff;
  font-size: 1.25rem;
  margin: 0 !important;
}

.Notification {
  font-size: 1rem;
  position: absolute;
  top: 100%;
  width: 350px;
  z-index: 15;
  right: -50px;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  background: #f3f3f3;
  transform-origin: top center;
  animation: 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}
.Notification:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  right: 62px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}
@media (max-width: 33.75em) {
  .Notification {
    max-height: calc(100vh - 60px);
    position: fixed;
    top: 54px;
    width: auto;
    left: 10px;
    right: 10px;
  }
  .Notification:before {
    right: 60px;
  }
}

.Notification-ctaClose {
  display: none;
  text-align: center;
  margin-right: 16px;
  width: 100px;
}
@media (max-width: 33.75em) {
  .Notification-ctaClose {
    display: block;
  }
}

.Notification-contentWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 600px;
  max-height: calc(100vh - 100px);
}
@media (max-width: 33.75em) {
  .Notification-contentWrapper {
    max-height: calc(100vh - 60px);
  }
}

.Notification-content {
  width: 100%;
  flex-grow: 1;
  max-height: calc(100% - 100px);
  overflow-y: scroll;
}

.Notification-cta {
  border-top: 1px solid #e2e2e2;
  padding: 1rem;
  text-align: center;
  align-items: center;
  background: white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
}

.Notification-cta .Button, .Notification-cta .RMP-MainContent .wp-block-button__link, .RMP-MainContent .Notification-cta .wp-block-button__link, .Notification-cta .RMP-UserWidget--Button {
  width: 100%;
}
.Notification-cta .Notification-ctaClose {
  width: 160px;
}

.ScaleIn-enter-active,
.ScaleIn-leave-active {
  animation-name: scaleIn;
  transition: 0.2s;
}

.ScaleIn-leave-active {
  animation-direction: reverse;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(1, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
}

.ScaleIn-leave-active {
  animation-direction: reverse;
  opacity: 0;
  transform: translateX(60px) scaleY(0.5);
}

.HeaderCount-container {
  width: auto;
}

.UserIcon {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-left: 2px;
  font-size: 0.8rem;
  text-align: center;
  vertical-align: middle;
  color: #fff;
}

.userDetails {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  vertical-align: middle;
  padding-top: 9px;
}
@media (max-width: 768px) {
  .userDetails .ProgressBar,
.userDetails .name {
    display: none !important;
  }
}

.UserLoggedIn {
  display: flex;
  align-items: center;
  gap: 4px;
}
@media (max-width: 768px) {
  .UserLoggedIn {
    width: auto;
  }
}
.UserLoggedIn .Navigation-subnav {
  flex-direction: column;
  top: 100%;
  right: 0;
  left: auto;
}
.UserLoggedIn .Navigation-item {
  width: 100%;
}

header#pageheader .AccountNav .UserMenu {
  font-size: 17px;
  display: inline-block;
  line-height: 10px;
  position: relative;
  top: 3px;
}
header#pageheader .AccountNav li.subnav {
  padding: 26px 5px;
}
header#pageheader .AccountNav li.subnav span {
  margin: 0;
}
header#pageheader .AccountNav li.subnav span.Icon-r Icon-angle-down {
  display: inline-block;
  vertical-align: middle;
}
header#pageheader .AccountNav li.subnav span.name {
  display: inline-block;
  min-width: 24px;
  max-width: 80px;
  margin-right: 6px;
  font-size: 12px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
header#pageheader .AccountNav li.subnav ul {
  right: 0;
  left: auto;
  width: 160px;
}
header#pageheader .AccountNav li.subnav ul a {
  display: block;
  padding: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}
header#pageheader .AccountNav li.subnav.UserLoggedIn {
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}

.ProgressBar {
  text-align: center;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #e1e1e1;
  z-index: 0;
}

.ProgressBar-percentage {
  display: block;
  transition: 0.2s;
  transition-delay: 0.8s;
  border-radius: 8px;
  background: #32b056;
  top: 0 !important;
}

.ProgressBar-figure {
  left: 100%;
  color: #32b056;
  font-size: 0.875rem;
}

.userDetails-name {
  display: flex;
  align-items: center;
}
.userDetails-name .UserMenu {
  margin-left: 2px;
  margin-top: -4px;
}
@media (min-width: 768px) {
  .userDetails-name .UserMenu {
    margin-top: 2px;
  }
}

.Breadcrumbs .nav {
  margin: 1em 1em 0 0;
}
.Breadcrumbs li {
  color: #333;
  display: inline-block;
  padding-right: 8px;
}

@media (min-width: 1024px) {
  .u-pullRight.aside {
    padding-left: 0;
    margin-left: auto;
  }
}

.small-promo.recruiters {
  padding: 0;
  background: #005cb3;
}

.small-promo.recruiters img {
  margin-bottom: 8px;
}

.unit {
  width: 100%;
  margin-bottom: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid #efede9;
  border-bottom: 1px solid #efede9;
}
.unit:last-child {
  border-bottom: none;
}
.unit > .text-link,
.unit > h4 {
  padding: 0 0.5em;
}
.unit .long-advert {
  margin-bottom: 15px;
}
.unit.similar-reviews .score--value, .unit.more-reviews .score--value {
  position: absolute;
  right: -8px;
  bottom: -8px;
}

.aside h4 {
  margin-bottom: 1em;
  color: #434a4f;
}

.aside h5 {
  margin-bottom: 0.25em;
  color: #434a4f;
  font-weight: 500;
}

.aside h5 {
  font-size: 1em;
}

.aside .unit li {
  display: inline-block;
  width: 100%;
  padding: 0.5em;
  clear: left;
  transition: all 0.3s;
  border: 1px solid transparent;
}
.aside .unit li.long-advert {
  padding: 0;
}
.aside .unit li:hover {
  border-color: rgba(0, 0, 0, 0.06);
  background: #fff;
  box-shadow: 0 8px 15px 1px, rgba(0, 0, 0, 0.15);
}

.aside .unit li .sidebar-company-logo {
  position: relative;
  margin-right: 15px;
  float: left;
}

.aside .unit li .sidebar-company-logo {
  width: 50px;
  height: 50px;
  float: left;
  border: 1px solid #e1e1e1;
  background: #fff;
}
.aside .unit li .sidebar-company-logo img {
  height: auto;
}

.aside .unit li .sidebar-company-logo + div {
  width: calc(100% - 90px);
  float: left;
}

.aside .unit li .sidebar-company-logo + div .text-link {
  float: left;
}

.aside .unit p {
  color: #838789;
}

.aside .long-advert .new-banner-advert {
  width: 100%;
  height: 180px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.panel {
  display: inline-block;
  width: 100%;
}

.SidebarRight.panel {
  padding-top: 0;
  padding-bottom: 0;
}
.SidebarRight.panel .unit {
  padding: 0;
  background: none;
}
.SidebarRight.panel .sidebar-signup-modal {
  padding: 1em;
}

.SidebarRight-content {
  height: 100%;
  padding: 16px;
  background: #fff;
}
.SidebarRight-content .unit {
  margin-bottom: 0;
  padding-bottom: 0;
}
.SidebarRight-content .mini-advert-list {
  padding-left: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .company-profile2 .aside {
    overflow: hidden;
  }
}
/* CONTACT ============================================================================= */
.ReportProblem {
  margin-top: 32px;
}

.ContactUsForm {
  margin-bottom: 4em !important;
}

.terms section li {
  margin: 0 0 0 50px;
  list-style: disc;
  list-style-position: outside;
}

.terms table td {
  padding: 10px;
  font-weight: 100;
}

.about section,
.team section {
  display: inline-block;
  width: calc(100% - 40px);
  margin: 20px 20px;
}

.full-banner {
  background-color: #005cb3;
  margin-bottom: 20px;
  text-align: center;
  height: 350px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 1rem;
}
.full-banner h1, .full-banner p {
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}
.full-banner .contact-header {
  background-image: url("https://d180puc5tc84nj.cloudfront.net/css/misc/contact-us.jpg");
}

.AboutUser {
  display: flex;
}
@media (max-width: 768px) {
  .AboutUser {
    flex-wrap: wrap;
  }
}
.AboutUser .Card, .AboutUser .aside .menu, .aside .AboutUser .menu {
  margin-right: 1rem;
}
.AboutUser .Card:last-child, .AboutUser .aside .menu:last-child, .aside .AboutUser .menu:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .AboutUser .Card, .AboutUser .aside .menu, .aside .AboutUser .menu {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.ResponsiveVideo {
  margin-bottom: 1rem;
}

.ResponsiveVideo-container {
  position: relative;
  height: 0;
  padding-top: 25px;
  padding-bottom: 56.25%;
}

.ResponsiveVideo-iframe {
  position: absolute;
  top: 0;
  left: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* FOOTER ============================================================================= */
#pagefooter {
  text-align: center;
}
#pagefooter .Footer-links {
  margin-top: -1px;
  margin-bottom: 0;
  background: #004b92;
}
#pagefooter .Footer-links p,
#pagefooter .Footer-links ul {
  display: inline-block;
  float: none;
  vertical-align: middle;
}
#pagefooter .Footer-links p {
  padding: 15px 20px;
}
#pagefooter .Footer-links li a {
  display: block;
  padding: 0.5em 0;
}
#pagefooter .Footer-links li a:hover, #pagefooter .Footer-links li a:focus {
  background: #005cb3;
}
@media (min-width: 33.75em) {
  #pagefooter .Footer-links li a {
    display: inline-block;
    padding: 15px 8px;
  }
}
@media (min-width: 1024px) {
  #pagefooter .Footer-links li a {
    padding: 15px 20px;
  }
}
#pagefooter .Footer-declaration {
  background: #005cb3;
}
#pagefooter .Footer-declaration .Copyright {
  margin-top: 0;
  padding-top: 0.5em;
}
#pagefooter .Footer-declaration p {
  margin: 1em;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
}
#pagefooter .Footer-declaration p,
#pagefooter .Footer-declaration .Footer-links {
  background: transparent;
}
@media (min-width: 768px) {
  #pagefooter .Footer-declaration p,
#pagefooter .Footer-declaration .Footer-links {
    display: inline-block;
    vertical-align: middle;
  }
}
#pagefooter ul .Footer-link {
  width: 100%;
  float: none;
  margin: 0;
}
#pagefooter ul .Footer-link:last-child {
  margin-bottom: 0;
}
@media (max-width: 33.75em) {
  #pagefooter ul .Footer-link {
    padding: 0.2rem 0;
  }
}
@media (min-width: 33.75em) {
  #pagefooter ul .Footer-link {
    display: inline-block;
    width: auto;
  }
}
#pagefooter ul a span {
  font-size: 1.8rem;
}
#pagefooter .Footer-links li a {
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
}

#disclaimer {
  clear: both;
}
#disclaimer p {
  margin-bottom: 0;
  padding: 10px 20px;
  font-size: 13px;
}

@media (max-width: 768px) {
  .company-profile2 .page-header {
    display: none;
  }
  .MegaSearch {
    margin-top: 50px;
  }
}
.Card, .aside .menu {
  background: #FFFFFF;
  display: block;
  border: 1px solid #DDDDDD;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
}
.Card.has-no-border, .aside .has-no-border.menu {
  border-color: transparent;
  box-shadow: 0 4px 6px 0 transparent;
}
.Card.is-link:hover, .aside .is-link.menu:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12);
}
.Card .text-link, .aside .menu .text-link {
  font-weight: 700;
}

.Card-content, .aside .menu {
  padding: 24px;
}
.Card-content p:last-child, .aside .menu p:last-child {
  margin-bottom: 0;
}

.Card-subheader {
  opacity: 0.7;
  font-size: 16px;
  margin-bottom: 16px;
  letter-spacing: 0.1px;
}

.Card-logo {
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  height: 60px;
  width: 60px;
  overflow: hidden;
  margin-bottom: 8px;
}
.Card-logo img {
  object-fit: fill;
}

.Card-footer {
  display: flex;
  align-items: center;
  padding: 0 16px 24px;
  margin-top: auto;
}
.Card-footer .text-link::after {
  content: none;
}

.CompanySearchListing .Card-footer {
  padding-top: 16px;
}

.ShortlistIcon-icon .ShortlistIcon-label, .ShortlistIcon-register .ShortlistIcon-label, .FollowIcon-icon .ShortlistIcon-label {
  display: inline;
  font-size: inherit;
}
.ShortlistIcon-icon.shortlisted, .ShortlistIcon-register.shortlisted, .FollowIcon-icon.shortlisted {
  color: #ff5043;
}
.ShortlistIcon-icon.Button--iconOnly, .ShortlistIcon-register.Button--iconOnly, .FollowIcon-icon.Button--iconOnly {
  margin-left: auto !important;
  font-size: 24px;
}
.ShortlistIcon-icon.Button--iconOnly .Shortlist-label, .ShortlistIcon-register.Button--iconOnly .Shortlist-label, .FollowIcon-icon.Button--iconOnly .Shortlist-label {
  display: none;
}

.RelatedContent {
  position: relative;
  overflow: hidden;
}

.RelatedContent-items {
  display: flex;
  margin-bottom: 32px;
  flex-wrap: nowrap;
  position: relative;
}
@media screen and (max-width: 768px) {
  .RelatedContent-items {
    display: block;
  }
}

.JobCard, .EventCard, .ArticleCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 280px;
  width: 100%;
}
.JobCard ul,
.JobCard li, .EventCard ul,
.EventCard li, .ArticleCard ul,
.ArticleCard li {
  margin: 0;
  margin-bottom: 4px;
  color: #222222;
}
.JobCard li, .EventCard li, .ArticleCard li {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.JobCard:hover, .EventCard:hover, .ArticleCard:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12);
}
.JobCard:hover .text-link, .EventCard:hover .text-link, .ArticleCard:hover .text-link {
  color: #32b056;
}

.CaseStudyCard, .ArticleCard {
  display: inline-block;
  flex: 1;
  margin-right: 24px;
}
.CaseStudyCard:hover, .ArticleCard:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12) !important;
}
.CaseStudyCard:hover .text-link, .ArticleCard:hover .text-link {
  color: #32b056;
}

.hidden {
  visibility: hidden;
}

.HomepageHotjobsSlider .RelatedJobsSlider-controls,
.HomepageReviewsSlider .RelatedJobsSlider-controls,
.HomepageCaseStudiesSlider .RelatedJobsSlider-controls,
.RelatedJobsSlider .RelatedJobsSlider-controls,
.RelatedCaseStudiesSlider .RelatedJobsSlider-controls,
.RelatedEventsSlider .RelatedJobsSlider-controls,
.RelatedReviewsSlider .RelatedJobsSlider-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
}
.HomepageHotjobsSlider .splide__arrows,
.HomepageReviewsSlider .splide__arrows,
.HomepageCaseStudiesSlider .splide__arrows,
.RelatedJobsSlider .splide__arrows,
.RelatedCaseStudiesSlider .splide__arrows,
.RelatedEventsSlider .splide__arrows,
.RelatedReviewsSlider .splide__arrows {
  position: absolute;
  right: 0;
  top: 7px;
  display: flex;
  width: 70px;
  align-items: flex-start;
  justify-content: space-between;
  text-align: right;
}
@media (max-width: 33.75em) {
  .HomepageHotjobsSlider .splide__arrows,
.HomepageReviewsSlider .splide__arrows,
.HomepageCaseStudiesSlider .splide__arrows,
.RelatedJobsSlider .splide__arrows,
.RelatedCaseStudiesSlider .splide__arrows,
.RelatedEventsSlider .splide__arrows,
.RelatedReviewsSlider .splide__arrows {
    display: none;
  }
}
.HomepageHotjobsSlider .splide__arrows .splide__arrow--prev, .HomepageHotjobsSlider .splide__arrows .splide__arrow--next,
.HomepageReviewsSlider .splide__arrows .splide__arrow--prev,
.HomepageReviewsSlider .splide__arrows .splide__arrow--next,
.HomepageCaseStudiesSlider .splide__arrows .splide__arrow--prev,
.HomepageCaseStudiesSlider .splide__arrows .splide__arrow--next,
.RelatedJobsSlider .splide__arrows .splide__arrow--prev,
.RelatedJobsSlider .splide__arrows .splide__arrow--next,
.RelatedCaseStudiesSlider .splide__arrows .splide__arrow--prev,
.RelatedCaseStudiesSlider .splide__arrows .splide__arrow--next,
.RelatedEventsSlider .splide__arrows .splide__arrow--prev,
.RelatedEventsSlider .splide__arrows .splide__arrow--next,
.RelatedReviewsSlider .splide__arrows .splide__arrow--prev,
.RelatedReviewsSlider .splide__arrows .splide__arrow--next {
  display: inline;
  transform: none;
  font-weight: bold;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #EBECED;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
  position: static;
}
.HomepageHotjobsSlider .splide__arrows .splide__arrow--next,
.HomepageReviewsSlider .splide__arrows .splide__arrow--next,
.HomepageCaseStudiesSlider .splide__arrows .splide__arrow--next,
.RelatedJobsSlider .splide__arrows .splide__arrow--next,
.RelatedCaseStudiesSlider .splide__arrows .splide__arrow--next,
.RelatedEventsSlider .splide__arrows .splide__arrow--next,
.RelatedReviewsSlider .splide__arrows .splide__arrow--next {
  margin-right: 0;
}

.splide {
  position: static;
}

.splide__slide {
  height: auto;
  margin: 0;
}

.splide__slides {
  overflow: visible;
}

.splide__track {
  overflow: visible;
}

.Pagination {
  display: flex;
  margin: 2rem 0 1rem 0;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .Pagination {
    justify-content: center;
  }
}

.Pagination-item {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
}

.Pagination--pageNumber {
  display: none;
}
@media (min-width: 1024px) {
  .Pagination--pageNumber {
    display: block;
  }
}

.Pagination-button {
  width: 40px;
  height: 40px;
  background: #F2F2F7;
  text-align: center;
  line-height: 40px;
  flex-shrink: 0;
  display: block;
  margin-left: -1px;
  border: 1px solid #e7e7ef;
  overflow-anchor: none;
}
.Pagination-button.Pagination--arrowButton {
  width: 80px;
}
.Pagination-button:hover, .Pagination-button:focus {
  background: #e7e7ef;
  color: #32b056;
}
.Pagination-button.is-active, .Pagination-button.is-active:hover {
  background: #005cb3;
  border-color: #005cb3;
  color: #fff;
}

.Pagination--right {
  border-radius: 0 4px 4px 0;
}

.Pagination--left {
  border-radius: 4px 0 0 4px;
}

.disabled {
  background: #F2F2F7;
  color: #e7e7ef;
}
.disabled:hover, .disabled:focus {
  color: #e7e7ef;
  background: #e7e7ef;
}

/* TABLE PAGINATION ============================================================================= */
.pagination, .dataTables_paginate {
  width: 100%;
  padding: 1rem;
  float: left;
  text-align: center;
}
.pagination:after, .pagination:before, .dataTables_paginate:after, .dataTables_paginate:before {
  display: table;
  width: 100%;
  clear: both;
  content: "";
}

.profile-content2 .pagination, .profile-content2 .dataTables_paginate {
  border: none;
}

.pagination li, .pagination li a, .dataTables_paginate a, .pagination li > span {
  display: inline-block;
  vertical-align: middle;
}

.pagination li a, .pagination li.active .dataTables_paginate a {
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 0.5em 0 0 0;
  border: 1px solid #e1e1e1;
  background: #F5F5F5;
  font-size: 0.8125em;
}

.pagination li a:focus, .pagination li a:hover, .dataTables_paginate a {
  background: #e1e1e1;
}

.pagination li.active, .dataTables_paginate a.current {
  border: 1px solid #005cb3;
  background: #0069cd;
}

.pagination li.active span, .dataTables_paginate a.current {
  color: #fff;
}

.pagination li, .dataTables_paginate a, .dataTables_paginate span span {
  margin-right: 0.25em;
}

.pagination li.pagination-first, .dataTables_paginate a.previous {
  float: left;
}

.pagination li.pagination-last, .dataTables_paginate a.next {
  margin-right: 0;
  float: right;
}

.pagination li.pagination-first span, .pagination li.pagination-last span {
  font-size: 0.6875em;
}

.pagination li.active span {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

/* DATATABLE PAGINATION ============================================================================= */
.dataTables_paginate a {
  padding: 10px;
  cursor: pointer;
}

.dataTables_paginate a.disabled {
  display: none;
}

@media screen and (max-width: 600px) {
  .pagination li {
    margin-bottom: 20px;
    text-align: center;
  }
  .pagination li.active span {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .pagination li a {
    width: 27px;
    height: 27px;
    padding: 0;
    line-height: 27px;
  }
  .pagination li.pagination-last, .pagination li.pagination-first {
    float: left;
  }
}
.Button, .RMP-MainContent .wp-block-button__link, .RMP-UserWidget--Button {
  height: 48px;
  min-width: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.12px;
  font-weight: 700;
  justify-content: center;
  display: inline-flex;
  align-items: center;
}

.Button--long {
  width: 100%;
}

.Button--green,
.Button--primary,
.RMP-UserWidget--Button {
  background: #32b056;
  text-transform: uppercase;
  color: #fff;
}
.Button--green:focus, .Button--green:hover,
.Button--primary:focus,
.RMP-UserWidget--Button:focus,
.Button--primary:hover,
.RMP-UserWidget--Button:hover {
  background: #239544;
  color: #fff;
}

.Button--danger {
  background: #ff5043;
  text-transform: uppercase;
  color: #fff;
}
.Button--danger:focus, .Button--danger:hover {
  background: #d5362b;
  color: #fff;
}

.Button--blue,
.Button--secondary {
  background: #F2F2F7;
  color: #282C34;
  text-transform: uppercase;
}
.Button--blue:focus, .Button--blue:hover,
.Button--secondary:focus,
.Button--secondary:hover {
  background: #e7e7ef;
}

.Button--tertiary {
  background: #fff;
  border: 1px solid #32b056;
  color: #32b056;
  line-height: 1.2;
  text-transform: uppercase;
}
.Button--tertiary:focus, .Button--tertiary:hover {
  background: rgba(50, 176, 86, 0.1);
}

.Button--grey,
.Button--subtle {
  background: rgba(255, 255, 255, 0.4);
  color: #282C34;
  line-height: 48px;
}
.Button--grey:focus, .Button--grey:hover,
.Button--subtle:focus,
.Button--subtle:hover {
  background: #F2F2F7;
}

.Button--disabled {
  background: #e1e1e1;
  color: #848281;
  border: none;
  cursor: auto;
}
.Button--disabled:focus, .Button--disabled:hover {
  background: #e1e1e1;
  color: #848281;
}

.Button-iconLeft {
  font-size: 24px;
  margin-right: 8px;
}

.Button-iconRight {
  font-size: 24px;
  margin-left: 8px;
}

.Button-icon {
  font-size: 24px;
}

.Button + .Button, .RMP-MainContent .wp-block-button__link + .Button, .RMP-MainContent .Button + .wp-block-button__link, .RMP-MainContent .wp-block-button__link + .wp-block-button__link, .RMP-UserWidget--Button + .Button, .RMP-MainContent .RMP-UserWidget--Button + .wp-block-button__link, .Button + .RMP-UserWidget--Button, .RMP-MainContent .wp-block-button__link + .RMP-UserWidget--Button, .RMP-UserWidget--Button + .RMP-UserWidget--Button {
  margin-left: 16px;
}

.Button--iconOnly .Shortlist-label {
  display: none;
}

.text-link {
  text-decoration: none;
  color: #005cb3;
}
.text-link::after {
  position: relative;
  font-weight: 400;
  top: 1px;
  left: 10px;
  content: "\f061";
  transition: left 0.2s ease-out;
}
.text-link:hover::after {
  left: 15px;
}

.Button.text-link::after, .RMP-MainContent .text-link.wp-block-button__link::after, .text-link.RMP-UserWidget--Button::after {
  content: "";
}

header#pageheader .AccountNav li.AccountNav-login a {
  border-radius: 8px;
}

.Button--small {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.Button--small span {
  font-size: 16px;
}

.Buttons {
  text-align: right;
  display: flex;
}

.spinner,
.loading {
  position: relative;
}
.spinner:after,
.loading:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: inherit;
  height: 100%;
  border-radius: inherit;
}

.spinner::before,
.loading::before {
  content: "";
  border: 4px solid inherit;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  opacity: 0.8;
  border-radius: 50%;
  animation: loadingSpinner 0.7s infinite linear;
  margin-top: -10px;
  margin-left: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.m-fullWidth {
  width: 100%;
}
@media (min-width: 33.75em) {
  .m-fullWidth {
    width: auto;
  }
}

.AuthSwitcher {
  width: 100%;
}

.AuthSwitcher-type {
  margin-bottom: 0.5rem;
  display: inline-flex;
  width: calc(50% - 0.7em);
  margin-bottom: 1rem;
}
.AuthSwitcher-type .Button, .AuthSwitcher-type .RMP-MainContent .wp-block-button__link, .RMP-MainContent .AuthSwitcher-type .wp-block-button__link, .AuthSwitcher-type .RMP-UserWidget--Button {
  width: 100%;
}
.AuthSwitcher-type:last-child {
  margin-left: 1em;
}

.AuthSwitcher-link {
  border: 2px solid #e7e7ef;
  color: #000;
}
.AuthSwitcher-link.is-active {
  background: #eef3f8;
  border: 2px solid #005cb3;
  color: #005cb3;
}

.Has-icon {
  position: relative;
  width: 100%;
}
.Has-icon::before {
  position: absolute;
  color: #848281;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}
.Has-icon .Form-input,
.Has-icon .Form-select,
.Has-icon .Form-textArea {
  padding-left: 42px;
}
.Has-icon.Icon-right::before {
  right: 16px;
  left: auto;
}
.Has-icon.Icon-right .Form-input,
.Has-icon.Icon-right .Form-select,
.Has-icon.Icon-right .Form-textArea {
  padding-right: 2rem;
  padding-left: 1rem;
}
.Has-icon.Icon-left::before {
  left: 16px;
}
.Has-icon.Icon-left .Form-input,
.Has-icon.Icon-left .Form-select,
.Has-icon.Icon-left .Form-textArea {
  padding-left: 2rem;
}
.Has-icon.Form--error::before {
  color: #ff5043;
}
.Has-icon.Form--valid::before {
  color: #32b056;
}

.Form-label {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.Form-input,
input {
  display: block;
  min-width: 40px;
  padding: 0 1rem;
  border: 1px solid #9d9c9b;
  background: #fff;
  color: #333;
  border-radius: 8px;
  font-size: 1em;
  -webkit-appearance: none;
  height: 48px;
  line-height: 46px;
  margin: 0;
  padding: 0 1em;
  font-family: aktiv-grotesk, Helvetica Neue, Helvetica, sans-serif;
}
.Form-input.error,
input.error {
  border-color: #fb5f54;
}
.Form-input:focus,
input:focus {
  border-color: #1a90ff !important;
  box-shadow: 0 0 0 2px #1a90ff;
}
.Form-input.disabled, .Form-input:disabled,
input.disabled,
input:disabled {
  background: #e7e7ef;
  border-color: #e7e7ef;
  color: #848281;
}
.Form-input.disabled:hover, .Form-input:disabled:hover,
input.disabled:hover,
input:disabled:hover {
  cursor: not-allowed;
}

.Form-select,
select {
  display: block;
  min-width: 40px;
  padding: 0 1rem;
  border: 1px solid #9d9c9b;
  background: #fff;
  color: #333;
  border-radius: 8px;
  font-size: 1em;
  -webkit-appearance: none;
  height: 48px;
  line-height: 46px;
  font-family: aktiv-grotesk, Helvetica Neue, Helvetica, sans-serif;
}
.Form-select.error,
select.error {
  border-color: #fb5f54;
}
.Form-select:focus,
select:focus {
  border-color: #1a90ff !important;
  box-shadow: 0 0 0 2px #1a90ff;
}
.Form-select.disabled, .Form-select:disabled,
select.disabled,
select:disabled {
  background: #e7e7ef;
  border-color: #e7e7ef;
  color: #848281;
}
.Form-select.disabled:hover, .Form-select:disabled:hover,
select.disabled:hover,
select:disabled:hover {
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

.select-field {
  position: relative;
}

.select-field:after {
  position: absolute;
  right: 20px;
  color: #838789;
  line-height: 1;
  content: "\f107";
}

.Form-textarea,
textarea {
  display: block;
  min-width: 40px;
  padding: 0 1rem;
  border: 1px solid #9d9c9b;
  background: #fff;
  color: #333;
  border-radius: 8px;
  font-size: 1em;
  -webkit-appearance: none;
  padding: 1em;
  font-family: aktiv-grotesk, Helvetica Neue, Helvetica, sans-serif;
}
.Form-textarea.error,
textarea.error {
  border-color: #fb5f54;
}
.Form-textarea:focus,
textarea:focus {
  border-color: #1a90ff !important;
  box-shadow: 0 0 0 2px #1a90ff;
}
.Form-textarea.disabled, .Form-textarea:disabled,
textarea.disabled,
textarea:disabled {
  background: #e7e7ef;
  border-color: #e7e7ef;
  color: #848281;
}
.Form-textarea.disabled:hover, .Form-textarea:disabled:hover,
textarea.disabled:hover,
textarea:disabled:hover {
  cursor: not-allowed;
}

.Form-field {
  margin-bottom: 2rem;
  text-align: left;
}
.Form-field::before, .Form-field::after {
  display: table;
  content: " ";
}
.Form-field::after {
  clear: both;
}

.field {
  width: 100%;
  float: left;
}

.field input, .field select, .field textarea, .Form-field input, .Form-field select, .Form-field textarea {
  width: 100%;
}

.FormGroup {
  display: flex;
  width: 100%;
  float: left;
}
.FormGroup .Button, .FormGroup .RMP-MainContent .wp-block-button__link, .RMP-MainContent .FormGroup .wp-block-button__link, .FormGroup .RMP-UserWidget--Button {
  float: left;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.FormGroup select,
.FormGroup input {
  width: 100%;
  float: left;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Form-fieldGroup {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -1px;
}
.Form-fieldGroup::before, .Form-fieldGroup::after {
  display: table;
  content: " ";
}
.Form-fieldGroup::after {
  clear: both;
}
.Form-fieldGroup label {
  position: relative;
}
.Form-fieldGroup label:after {
  content: "i";
  opacity: 0;
}
.Form-fieldGroup input,
.Form-fieldGroup select,
.Form-fieldGroup .v-select .dropdown-toggle,
.Form-fieldGroup .Form-input {
  border-radius: 0;
}
.Form-fieldGroup input:focus,
.Form-fieldGroup select:focus,
.Form-fieldGroup .v-select .dropdown-toggle:focus,
.Form-fieldGroup .Form-input:focus {
  z-index: 1;
}
.Form-fieldGroup .Form-Content {
  width: 50%;
  float: left;
}
.Form-fieldGroup .Form-field:not(:first-child),
.Form-fieldGroup .Form-Content:not(:first-child) {
  margin-left: -1px;
}
.Form-fieldGroup .Form-field:nth-child(1) input,
.Form-fieldGroup .Form-field:nth-child(1) select,
.Form-fieldGroup .Form-field:nth-child(1) .v-select .dropdown-toggle,
.Form-fieldGroup .Form-field:nth-child(1) .Form-input,
.Form-fieldGroup .Form-Content:nth-child(1) input,
.Form-fieldGroup .Form-Content:nth-child(1) select,
.Form-fieldGroup .Form-Content:nth-child(1) .v-select .dropdown-toggle,
.Form-fieldGroup .Form-Content:nth-child(1) .Form-input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.Form-fieldGroup .Form-field:last-child input,
.Form-fieldGroup .Form-field:last-child select,
.Form-fieldGroup .Form-field:last-child .v-select .dropdown-toggle,
.Form-fieldGroup .Form-field:last-child .Form-input,
.Form-fieldGroup .Form-Content:last-child input,
.Form-fieldGroup .Form-Content:last-child select,
.Form-fieldGroup .Form-Content:last-child .v-select .dropdown-toggle,
.Form-fieldGroup .Form-Content:last-child .Form-input {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Form-groupSpaced {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1024px) {
  .Form-groupSpaced {
    flex-wrap: wrap;
    flex-wrap: -ms-flex-wrap;
  }
}
.Form-groupSpaced .Form-field:nth-child(1) {
  margin-left: 0;
}
.Form-groupSpaced .Form-input {
  width: 100%;
  border-radius: 3px;
}
.Form-groupSpaced .Form-field {
  display: inline-block;
  flex-grow: 1;
  width: calc(50% - 1rem);
  vertical-align: top;
}
@media (max-width: 1024px) {
  .Form-groupSpaced .Form-field {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .Form-groupSpaced .Form-field {
    margin-left: 1rem;
  }
}

.no-flexbox .Form-groupSpaced {
  display: block;
  width: 100%;
}
.no-flexbox .Form-groupSpaced .Form-field {
  display: block;
  width: 100%;
  margin-left: 0;
  clear: both;
}

.field .radio input[type=radio],
.Form-field--radio .Form-input {
  display: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.field .radio label:before,
.Form-field--radio .Form-label:before {
  width: 20px;
  height: 20px;
  border-color: #e2e0dc;
  background: #e2e0dc;
  line-height: 20px;
  content: "";
  border-radius: 20px;
  margin-right: 0.5rem;
  margin-top: 2px;
  float: left;
  border: 6px solid transparent;
  content: "";
}

.Form-field--radio .Form-input:checked + .Form-label:before,
.field .radio input[type=radio]:checked + label:before {
  border-color: #005cb3;
  background: #fff !important;
}

.Form-field--radioButton {
  display: inline-block;
  text-align: center;
}
.Form-field--radioButton .Form-label {
  width: 100%;
  border: 2px solid #848281;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  color: #333;
}
.Form-field--radioButton .Form-label:before {
  display: none;
}

.Form-field--radioButton .Form-input:checked + .Form-label {
  color: #005cb3;
  border-color: #005cb3;
}

.Form-field--radioGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.Form-field--radioGroup .Form-field--radioButton {
  flex-grow: 1;
  margin-right: 1rem;
  flex-basis: 0;
}
.Form-field--radioGroup .Form-field--radioButton:last-child {
  margin-right: 0;
}

.Form-field--checkbox,
.checkboxes {
  margin: 1rem 0;
}

.checkbox input[type=checkbox],
.Form-field--checkbox .Form-input {
  display: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.checkbox label:before,
.Form-field--checkbox .Form-label:before {
  width: 20px;
  height: 20px;
  border-color: #e2e0dc;
  background: #e2e0dc;
  line-height: 20px;
  content: "";
  margin-right: 0.5rem;
  float: left;
  font-size: 1rem;
  text-align: center;
  content: "";
  border-radius: 3px;
}

.Form-input:checked + .Form-label,
.checkbox input[type=checkbox]:checked + label {
  color: #005cb3;
}

.Form-field--checkbox .Form-input:checked + .Form-label:before,
.field .checkbox input[type=checkbox]:checked + label:before {
  border-color: #005cb3;
  background: #005cb3;
  color: #fff;
  line-height: 20px;
  content: "\f00c";
}

.filter.checkboxes li.selected > a:before {
  border-color: #005cb3;
  background: #005cb3;
  color: #fff;
  line-height: 20px;
  content: "\f00c";
}

.Form-toggle {
  display: flex;
}
.Form-toggle input {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px;
}

.Form-toggleBall {
  position: absolute;
  width: 19px;
  top: 3px;
  left: 3px;
  height: 19px;
  border-radius: 13px;
  background: #fff;
  transition: 0.3s;
}

.Form--toggleLabel {
  display: flex;
  align-items: center;
}

.Form-checkboxInput:checked + .Form-label .Form-toggleBall,
.Form-toggle--checked .Form-toggleBall {
  left: calc(100% - 23px);
}

.Form-checkboxInput:checked + .Form-label .Form-toggleContainer,
.Form-toggle--checked .Form-toggleContainer {
  background: #39B546;
}

.Toggle-toggleText {
  display: inline-block;
}

.Form-toggleContainer {
  transition: 0.3s;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: #e7e7ef;
  width: 40px;
  margin-right: 0.5rem;
  height: 25px;
  border-radius: 20px;
}

/* Option Picker (replacement for selects) */
.js #content .optionpicker {
  overflow: hidden;
  zoom: 1;
}

.js #content .optionpicker li {
  margin-bottom: 4px;
  margin-left: 0;
  float: left;
  list-style: none;
}

.js #content .optionpicker span {
  display: inline-block;
  margin-right: 4px;
  padding: 4px 6px 6px;
  border-radius: 4px;
  background-color: #eee;
  color: #434a4f;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-right: 4px;
  background-color: #F2F2F7;
  color: #848281;
}

.js #content .optionpicker span:hover {
  background-color: #F2F2F7;
  color: #0a3555;
  text-decoration: underline;
}

.js #content .optionpicker .selected span {
  background-color: #005cb3;
  color: #fff;
  text-decoration: none;
}

.js #content .optionpicker .first.selected span {
  background-color: #ff5043;
  color: #fff;
}

.control-panel select {
  margin: 0;
}

/*== Form Validation Error ========*/
.error-message {
  width: 100%;
  padding: 8px 15px;
  float: left;
  color: #fff;
}
.error-message p {
  margin: 0;
  color: #fff;
}

textarea.error,
input.error {
  background: none;
  color: #434a4f !important;
}

.Validation {
  padding-top: 0.25rem;
  font-size: 0.875rem;
}

.Validation--error {
  color: #ff5043;
}

.Form-field.error input, .Form-field.error select, .Form-field.error textarea,
.field.error input,
.field.error select,
.field.error textarea {
  border: 1px solid #ff5043 !important;
}
.Form-field.success input, .Form-field.success select, .Form-field.success textarea,
.field.success input,
.field.success select,
.field.success textarea {
  border: 1px solid #32b056 !important;
}

.Validation--error,
.validation-error, .error-message {
  position: relative;
  margin-top: 6px;
  padding: 8px 15px;
  clear: both;
  border-radius: 4px;
  background: #fb5f54;
  color: #fff !important;
}
.Validation--error p,
.validation-error p, .error-message p {
  color: #fff !important;
}
.Validation--error:before,
.validation-error:before, .error-message:before {
  margin-bottom: -1px;
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ff5043;
  border-left: 5px solid transparent;
}

.Hint {
  margin: 0.25rem 0 0;
  color: #848281;
  font-size: 13px;
}

.Hint-icon {
  margin-right: 0.25rem;
}

.Hint--warning .Hint-icon {
  margin-right: 0.25rem;
}

.register.new-signup .select2-container,
.page-write-review .select2-container,
.page-dashboard .select2-container {
  margin: 0;
  border: 1px solid #838789;
  border-radius: 4px;
}

.dropdown-wrapper {
  z-index: 1;
}

.select2-dropdown {
  width: calc(100% + 2px);
}

.select2-container {
  margin-top: -68px;
}
.select2-container.select2-container--open {
  border-color: transparent;
  box-shadow: 0 0 0 3px #1a90ff;
}

.select2-container--default .select2-selection--single {
  padding: 20px 20px;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border-color: #838789 !important;
  box-shadow: none;
}

.multiselector-contents {
  z-index: 5;
}
.multiselector-contents .sector-title {
  width: 100%;
  float: left;
}
.multiselector-contents .sector-title h4 {
  color: #005cb3 !important;
}

.js .multiselector .checkboxes {
  display: none;
}

.js .multiselector .checkbox {
  width: 50%;
  float: left;
}

.js .multiselector .label {
  width: 100%;
  margin-bottom: 8px;
  float: left;
}

.js .multiselector h3 {
  width: 100%;
  margin-bottom: 10px;
  float: left;
}

.js .multiselector div.select-controls {
  width: 30%;
  margin-top: 4px;
  float: right;
  text-align: right;
}

.js .multiselector a {
  margin-left: 6px;
}

.js .multiselector p {
  margin-left: 122px;
  clear: both;
}

.js .multiselector p.change-selection {
  display: inline-block;
  width: 100%;
  margin: 0;
  margin-top: 5px;
  font-size: 0.875rem;
}

.js .multiselector p.change-selection a {
  margin-left: 0;
}

.js .multiselector-display {
  width: 100%;
  margin-bottom: 0 !important;
  float: left;
  overflow: hidden;
  zoom: 1;
}

.js .multiselector-display li {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 8px 14px 6px;
  background-color: #ff9b32;
  color: #fff;
  line-height: 1;
  cursor: pointer;
}

.js .multiselector-display li:hover,
.js .multiselector-display li:focus {
  background-color: #ffaa32;
}

.js .multiselector-display li.nothing-selected {
  background-color: #ff5043;
  color: #fff;
}

.js .multiselector-contents {
  display: none;
  position: fixed;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  max-width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  z-index: 3;
  box-shadow: 0 8px 15px 1px, rgba(0, 0, 0, 0.15);
  overflow: hidden;
  zoom: 1;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .js .multiselector-contents {
    top: 175px;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 600px;
    margin-left: -300px;
    transform: none;
  }
}
.js .multiselector-contents h3, .js .multiselector-contents h2, .js .multiselector-contents h1 {
  margin: 0;
  padding: 0.5em 1em;
  background: #333;
  color: #fff;
}
.js .multiselector-contents .Button, .js .multiselector-contents .RMP-MainContent .wp-block-button__link, .RMP-MainContent .js .multiselector-contents .wp-block-button__link, .js .multiselector-contents .RMP-UserWidget--Button {
  margin: 0.5em 1em;
}

.js .multiselector-fields {
  width: 100%;
  height: calc(100% - 130px);
  padding: 0 1em;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow: scroll;
  overflow-x: hidden;
  zoom: 1;
}
@media (min-width: 768px) {
  .js .multiselector-fields {
    height: 234px;
  }
}
.js .multiselector-fields .list {
  display: flex;
  flex-wrap: wrap;
  clear: both;
}
.js .multiselector-fields .list .checkbox {
  display: flex;
  width: 100%;
}
@media (min-width: 33.75em) {
  .js .multiselector-fields .list .checkbox {
    width: 50%;
  }
}

#application_requirements-outer-wrapper {
  width: 100%;
}

.js .multiselector-fields .sector-title {
  margin-top: 1em;
  clear: left;
}

.js .multiselector-fields label,
.js .multiselector-fields input {
  cursor: pointer;
}

/* -- Autocompletes -- */
div.autocomplete {
  display: block;
  clear: both;
}

div.autocomplete-select {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  z-index: 4;
}

.autocomplete span.highlight-word {
  background-color: #ffb932;
  color: #000;
}

.realtime-results-wrapper {
  position: relative;
  width: 100%;
}

div.realtime-results {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px 0;
  border: 1px solid #ccc;
  background: #fff;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  z-index: 4;
}

div.realtime-results ul {
  margin: 0;
}

div.realtime-results ul li {
  display: block;
  margin-left: 0;
  padding: 3px 6px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

div.realtime-results li.hover {
  background: #e7e7ef;
  color: #0a3555;
}

.AutoComplete-image {
  display: inline-block;
  width: 30px;
  overflow: hidden;
  vertical-align: middle;
}

.Form-autoComplete .dropdown li a {
  display: block;
  width: 100%;
}

.SearchPage .aa-Panel {
  border: 1px solid #1a90ff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 0 0 2px #1a90ff;
  margin: 0;
  max-height: 400px;
}
.SearchPage .aa-Panel .aa-PanelLayout {
  padding: 0 !important;
}
.SearchPage .aa-Panel .aa-Panel-body {
  padding: 0;
}
.SearchPage .aa-Panel .aa-Item {
  padding: 0;
  border-radius: 0;
}
.SearchPage .aa-Panel div.aa-Item {
  padding-left: 12px;
}
.SearchPage .aa-Panel div.aa-Item:hover {
  background: #005cb3 !important;
}
.SearchPage .aa-Panel div.aa-Item:hover a {
  color: #fff;
}

:root {
  --aa-panel-max-height: 400px !important;
}

.SearchInputSearchLocation input {
  width: 100%;
}

.Form-inputBox,
.SearchInput {
  position: relative;
}
.Form-inputBox .LoadingScreen,
.SearchInput .LoadingScreen {
  width: 30px;
  height: 30px;
  background: transparent;
  top: 7px;
  left: auto;
  right: 90px;
}
.Form-inputBox .LoadingScreen-spinner,
.SearchInput .LoadingScreen-spinner {
  position: relative;
  top: 0;
  transform: none;
  display: block;
  left: 0;
}
.Form-inputBox .Button, .Form-inputBox .RMP-MainContent .wp-block-button__link, .RMP-MainContent .Form-inputBox .wp-block-button__link, .Form-inputBox .RMP-UserWidget--Button,
.SearchInput .Button,
.SearchInput .RMP-MainContent .wp-block-button__link,
.RMP-MainContent .SearchInput .wp-block-button__link,
.SearchInput .RMP-UserWidget--Button {
  position: absolute;
  right: 0px;
  top: 0;
  box-shadow: none;
  border-radius: 0 4px 4px 0;
  padding: 0 1rem;
}
.Form-inputBox .Button:focus, .Form-inputBox .RMP-MainContent .wp-block-button__link:focus, .RMP-MainContent .Form-inputBox .wp-block-button__link:focus, .Form-inputBox .RMP-UserWidget--Button:focus, .Form-inputBox .Button:hover, .Form-inputBox .RMP-MainContent .wp-block-button__link:hover, .RMP-MainContent .Form-inputBox .wp-block-button__link:hover, .Form-inputBox .RMP-UserWidget--Button:hover,
.SearchInput .Button:focus,
.SearchInput .RMP-MainContent .wp-block-button__link:focus,
.RMP-MainContent .SearchInput .wp-block-button__link:focus,
.SearchInput .RMP-UserWidget--Button:focus,
.SearchInput .Button:hover,
.SearchInput .RMP-MainContent .wp-block-button__link:hover,
.RMP-MainContent .SearchInput .wp-block-button__link:hover,
.SearchInput .RMP-UserWidget--Button:hover {
  transform: none;
  color: #fff;
}
.Form-inputBox .Button span, .Form-inputBox .RMP-MainContent .wp-block-button__link span, .RMP-MainContent .Form-inputBox .wp-block-button__link span, .Form-inputBox .RMP-UserWidget--Button span,
.SearchInput .Button span,
.SearchInput .RMP-MainContent .wp-block-button__link span,
.RMP-MainContent .SearchInput .wp-block-button__link span,
.SearchInput .RMP-UserWidget--Button span {
  font-size: 20px;
  line-height: 45px;
}

.Form-inputBox .LoadingScreen {
  transform: none;
  right: 0.5rem;
}

.ResetInput {
  position: absolute;
  top: 0;
  width: 30px;
  height: 40px;
  z-index: 4;
  right: 0;
  text-align: center;
  line-height: 40px;
}
.ResetInput span {
  font-size: 14px;
  color: #a39696;
}

.avatar {
  height: 25px;
  width: 25px;
  border-radius: 20px;
  margin-right: 10px;
}

.aa-PanelLayout .autosuggest__results_title {
  border-bottom: 1px solid #e7e7ef;
  color: #848281;
  font-size: 13px;
  margin-left: 0;
  padding: 15px 13px 5px;
  text-align: left;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  margin: 0;
  top: 100%;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow: auto;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results_item {
  cursor: pointer;
  padding: 0.5rem 15px;
  color: #000;
}
.autosuggest__results .autosuggest__results_item strong {
  color: #005cb3;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: #000;
  font-weight: 700;
  font-size: 1.2em;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-bottom: 1px solid #e7e7ef;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results_item.autosuggest__results_item-highlighted {
  background-color: #005cb3;
}
.autosuggest__results .autosuggest__results_item:active span,
.autosuggest__results .autosuggest__results_item:hover span,
.autosuggest__results .autosuggest__results_item:focus span,
.autosuggest__results_item.autosuggest__results_item-highlighted span {
  color: #d2d0d0;
}
.autosuggest__results .autosuggest__results_item:active strong,
.autosuggest__results .autosuggest__results_item:hover strong,
.autosuggest__results .autosuggest__results_item:focus strong,
.autosuggest__results_item.autosuggest__results_item-highlighted strong {
  color: #fff;
}

/*
$styleguide

$title Forms

<div class='field'>
  <input class='Input' placeholder='input'>
</div>

<div class='field'>
  <div class='select'>
    <select>
      <option>Option 1</option>
      <option>Option 2</option>
      <option>Option 3</option>
    </select>
  </div>
</div>

<div class='field'>
  <div class='checkbox'>
    <input type='checkbox' id='checkbox'></input>
    <label for='checkbox'> Checkbox </label>
  </div>
</div>

<div class='field'>
  <div class='radio'>
    <input type='radio' id='radio' name='radio'></input>
    <label for='radio'> Checkbox </label>
  </div>
  <div class='radio'>
    <input type='radio' id='radio2' name='radio'></input>
    <label for='radio2'> Checkbox </label>
  </div>
</div>

 <textarea></textarea>

 */
::-webkit-input-placeholder {
  color: #838789;
}

:-moz-input-placeholder { /* Firefox 18- */
  color: #838789;
}

::-moz-input-placeholder { /* Firefox 19+ */
  color: #838789;
}

:-ms-input-placeholder {
  color: #838789;
}

select::-ms-expand {
  display: none;
}

/**

 */
.v-select.vs--searchable .vs__dropdown-toggle {
  border: 1px solid #9d9c9b;
}
.v-select.vs--searchable.vs--open .vs__dropdown-menu,
.v-select.vs--searchable.vs--open .vs__dropdown-toggle {
  border-color: #1a90ff !important;
  box-shadow: 0 0 0 2px #1a90ff;
}

.Form-field .v-select .open-indicator {
  margin-right: 4px;
}
.Form-field .v-select .open-indicator:before {
  content: "\f078";
  border: none;
  transform: none;
  display: inline-block;
  height: 15px;
  text-align: center;
  line-height: 15px;
  width: 15px;
}

.Form-field .v-select.open .open-indicator:before {
  transform: rotate(180deg);
}

.v-select.vs--searchable .vs__dropdown-toggle {
  min-height: 40px;
  height: auto;
}
.v-select.vs--searchable .vs__dropdown-toggle .vs__selected-options {
  align-items: center;
  border: none !important;
}
.v-select.vs--searchable .vs__dropdown-toggle input {
  margin-top: -1px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.Form-field input, .Form-field select {
  margin: 0;
}

.select:after {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  color: #838789;
  font-size: 0.75rem;
  content: "\f107";
}

#pageheader {
  border-bottom: 1px solid #004b92;
}

@media (min-width: 768px) {
  .Search-buttonIcon {
    display: none;
  }
}

.HomePageTwo .Search-buttonIcon {
  display: none;
}

.SuperSearch-grid {
  position: relative;
  padding: 80px 15px 15px;
  text-align: center;
}
@media (min-width: 768px) {
  .SuperSearch-grid {
    padding: 40px 130px;
  }
}

.SuperSearch-supText {
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 768px) {
  .SuperSearch .Button, .SuperSearch .RMP-MainContent .wp-block-button__link, .RMP-MainContent .SuperSearch .wp-block-button__link, .SuperSearch .RMP-UserWidget--Button,
.SuperSearch .Form-select,
.SuperSearch .Form-input {
    border-radius: 8px;
  }
  .SuperSearch .Form-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .SuperSearch .Form-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .SuperSearch .Button, .SuperSearch .RMP-MainContent .wp-block-button__link, .RMP-MainContent .SuperSearch .wp-block-button__link, .SuperSearch .RMP-UserWidget--Button,
.SuperSearch .Form-selectDropDown,
.SuperSearch .Form-input {
    margin-bottom: 1rem;
  }
}
.SuperSearch.noHeroBanner {
  padding-bottom: 0;
}
@media (min-width: 33.75em) {
  .SuperSearch {
    background-position: top -120px right -50px;
    background-size: auto;
  }
}
.SuperSearch h1 {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .SuperSearch h1 {
    font-size: 50px;
  }
}

.MegaSearch {
  background: #fff;
  border-bottom: 1px solid #e7e7ef;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 51px;
}
@media (min-width: 768px) {
  .MegaSearch {
    margin-top: 0;
  }
}
.MegaSearch .Col-md-12 {
  padding-bottom: 0;
}
.MegaSearch .Form-selectDropDown {
  height: 48px;
  margin-left: 0;
  line-height: 48px;
}
.MegaSearch .SiteSearch-form {
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  .MegaSearch .SiteSearch-form span.twitter-typeahead .tt-menu {
    right: -40px;
    left: auto !important;
    width: calc(100% + 148px);
  }
  .MegaSearch .SiteSearch-form .Form-search {
    flex-shrink: 0;
    width: calc(100% - 148px);
  }
  .MegaSearch .SiteSearch-form .Form-selectDropDown {
    flex-shrink: 0;
    width: 100px;
    margin: 0;
  }
}
.MegaSearch .SiteSearch-form .Search-buttonText {
  display: block;
}
@media (max-width: 768px) {
  .MegaSearch .SiteSearch-form .Search-buttonText {
    display: none;
  }
}
.MegaSearch .SiteSearch-form .Search-buttonIcon {
  display: none;
}
@media (max-width: 768px) {
  .MegaSearch .SiteSearch-form .Search-buttonIcon {
    display: block;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .MegaSearch .SiteSearch-form {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .MegaSearch .SiteSearch-form .Button, .MegaSearch .SiteSearch-form .RMP-MainContent .wp-block-button__link, .RMP-MainContent .MegaSearch .SiteSearch-form .wp-block-button__link, .MegaSearch .SiteSearch-form .RMP-UserWidget--Button,
.MegaSearch .SiteSearch-form .Form-search,
.MegaSearch .SiteSearch-form .Form-selectDropDown {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.SiteSearch-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}
.SiteSearch-form button {
  flex-grow: 1;
  width: 120px;
  height: 48px;
  margin: 0;
  float: left;
  font-size: 18px;
  line-height: 48px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
@media (max-width: 768px) {
  .SiteSearch-form button {
    display: none;
  }
}
.SiteSearch-form .Form-input,
.SiteSearch-form .Form-select {
  min-width: 40px;
  margin: 0;
  height: 48px;
  background-color: #fff;
  font-size: 1em;
}
.SiteSearch-form .Form-input:active,
.SiteSearch-form .Form-select:active {
  z-index: 4;
}
@media (min-width: 768px) {
  .SiteSearch-form .Form-input,
.SiteSearch-form .Form-select {
    height: 48px;
  }
}
.SiteSearch-form .Form-select {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.SiteSearch-form .Form-input {
  border-radius: 0;
}

.Form-search {
  position: relative;
  width: 100%;
  margin: 0;
  flex-grow: 1;
}
.Form-search input {
  width: 100%;
}
@media (min-width: 768px) {
  .Form-search {
    width: calc(100% - 328px);
    height: auto;
    float: left;
  }
  .Form-search::before {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(50%, -50%);
    content: "\f002";
    z-index: 1;
  }
  .Form-search .Form-input {
    padding-left: 45px;
  }
}

.Form-selectDropDown {
  position: relative;
  margin: 0;
}
.Form-selectDropDown::before {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(50%, -50%);
  content: "\f107";
}
@media (min-width: 768px) {
  .Form-selectDropDown {
    width: 160px;
    height: auto;
    float: left;
  }
}
.Form-selectDropDown .Form-select {
  width: 100%;
}

.SuperSearch-supText a,
.SuperSearch-supText {
  border: none;
  font-size: 1em;
  font-weight: 700;
}
@media (min-width: 1024px) {
  .SuperSearch-supText a,
.SuperSearch-supText {
    font-size: 1.5em;
  }
}

.SuperSearch-supText a:hover {
  color: #32b056;
}

.FeaturedTopEmployer {
  display: flex;
  bottom: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  background: #005cb3;
  text-align: left;
}
.FeaturedTopEmployer .Container {
  margin: 0 auto;
}
.FeaturedTopEmployer .Logo {
  display: inline-block;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 1em;
  border: 1px solid #fff;
  vertical-align: top;
}
.FeaturedTopEmployer span {
  color: #fff;
}
.FeaturedTopEmployer .text-link {
  align-self: center;
  margin-right: 10px;
  margin-left: auto;
  float: right;
  color: #fff;
}

.FeaturedTopEmployer-name {
  display: block;
  float: left;
  clear: both;
  font-size: 18px;
  font-weight: 700;
}

.FeaturedTopEmployer-copy {
  display: inline-block;
  width: calc(100% - 90px);
}
@media (min-width: 33.75em) {
  .FeaturedTopEmployer-copy {
    width: calc(100% - 330px);
  }
}

.FeaturedTopEmployer-tagline {
  float: left;
  clear: both;
  font-size: 14px;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .FeaturedTopEmployer-tagline {
    font-size: 16px;
  }
}

.PremiumEmployer {
  display: flex;
  flex-wrap: wrap;
}
.PremiumEmployer .Shortlist {
  margin-top: 0.5em;
  float: right;
}
.PremiumEmployer .Shortlist .FollowIcon-label {
  font-size: 14px;
}
@media (min-width: 768px) {
  .PremiumEmployer .Shortlist {
    float: right;
  }
}

.Container.BannerWithInternal {
  position: relative;
  margin-top: -140px;
  margin-bottom: 1em;
}
.Container.BannerWithInternal.noHeroBlock {
  margin-top: 0;
}

.QuickSearch {
  width: 844px;
  max-width: 100%;
  margin: 2px auto;
  padding: 2px 16px;
  border-radius: 4px;
  text-align: center;
}
@media (min-width: 768px) {
  .QuickSearch {
    width: calc(850px - 1rem);
    margin: 0.5em auto;
  }
}

.QuickSearch-title,
.QuickSearch-link {
  display: inline-block;
  margin: 0;
  font-size: 1em;
}

.QuickSearch-title {
  font-weight: 700;
  margin-right: 0.5em;
}

.QuickSearch-link {
  text-decoration: underline;
}
.QuickSearch-link:focus, .QuickSearch-link:hover {
  color: #32b056;
}

.Form-search .LoadingScreen {
  top: 0;
  right: 0;
  left: auto;
  width: 30px;
  height: 100%;
  background: transparent;
}
.Form-search .LoadingScreen-spinner {
  position: absolute;
  right: 1rem;
  left: auto;
  transform: translateY(-50%);
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  margin: 0;
  top: 100%;
  position: absolute;
  z-index: 3;
  width: 100%;
  border: 1px solid #1a90ff;
  box-shadow: 0 0 0 2px #1a90ff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results_item {
  cursor: pointer;
  padding: 0.5rem 15px;
  color: #000;
  text-align: left;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: #848281;
  font-size: 13px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid #e7e7ef;
  text-align: left;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results .autosuggest__results_item.autosuggest__results_item-highlighted {
  background-color: #005cb3;
  color: #fff;
}

.RecruiterShout {
  display: none;
  position: fixed;
  right: 2em;
  bottom: 0;
  transition: 0.3s;
  border-radius: 4px 4px 0 0;
  background: #005cb3;
  z-index: 3;
}
@media (min-width: 768px) {
  .RecruiterShout {
    display: block;
  }
}
.RecruiterShout:hover {
  right: 1.5em;
  padding: 0.5em;
}

.RecruiterShout-link {
  padding: 0.3333333333em 0.5em;
  color: #fff;
  font-size: 15px;
}
.RecruiterShout-link span {
  font-weight: 700;
}

.SearchPage .RecruiterShout {
  display: none;
}

.HotJob {
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
}
@media (min-width: 33.75em) {
  .HotJob {
    margin-top: 1px;
    margin-left: 1px;
  }
}

.HotJob-job {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 0.5em;
  float: left;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  overflow: hidden;
  /*  &:focus,
    &:hover {
      z-index: 1;
      @include dropShadow;
    }
    */
}
.HotJob-job.is-featured::before {
  display: block;
  position: absolute;
  top: -10px;
  left: 10px;
  width: 0;
  height: 0;
  transform: rotate(-90deg);
  border-top: 20px solid #ff9b32;
  border-bottom: 20px solid transparent;
  border-left: 20px solid transparent;
  content: "";
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}
@media (min-width: 33.75em) {
  .HotJob-job {
    overflow: visible;
  }
  .HotJob-job:nth-child(1) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
  }
  .HotJob-job:nth-child(10) {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
@media (min-width: 768px) {
  .HotJob-job {
    margin-bottom: 4px;
  }
  .HotJob-job:nth-child(2n+2) {
    margin-left: 4px;
  }
  .HotJob-job:nth-child(1) {
    border-top-left-radius: 6px;
    border-top-right-radius: 0;
    overflow: hidden;
  }
  .HotJob-job:nth-child(2) {
    border-top-right-radius: 6px;
  }
  .HotJob-job:nth-child(9) {
    border-bottom-left-radius: 6px;
  }
  .HotJob-job:nth-child(10) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 6px;
  }
}
@media (min-width: 768px) {
  .HotJob-job {
    width: calc(50% - 4px);
    transition: 0.3s ease;
  }
  .HotJob-job.is-featured::before {
    top: -13px;
    left: 12px;
    border-top: 25px solid #ff9b32;
    border-bottom: 25px solid transparent;
    border-left: 25px solid transparent;
  }
}
.HotJob-job .Shortlist-label {
  display: none;
}

.HotJob-job .ShortlistIcon {
  align-self: center;
  margin-top: 10px \9 ;
  margin-left: auto;
}

.HotJob-specs {
  float: left;
  text-align: left;
}

.HotJob-link {
  display: flex;
  width: calc(100% - 75px);
  padding: 1em 0 1em 1em;
  float: left;
  /*  &:focus,
    &:hover {
      .Job-title,
      .companyName {
        color: color($primary);
      }
    }*/
}

.HotJob-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1em;
  text-align: left;
  line-height: 1.5;
}

.companyName {
  display: block;
}

.Logo {
  display: none;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border: 1px solid #e1e1e1;
}
@media (min-width: 768px) {
  .Logo {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 1em;
    float: left;
  }
}

.HotJob-heart {
  align-self: center;
  margin-right: 1.6rem;
  margin-left: auto;
}

.AdviceBlock-title {
  display: flex;
  align-items: center;
}

.AdviceBlock-content {
  margin-right: 0.25em;
}

.top-employer-tag {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0;
}

.Block {
  display: block;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: #fff;
}
.Block .TopEmployer-icon {
  display: none;
}
@media (min-width: 768px) {
  .Block .TopEmployer-icon {
    display: block;
    position: absolute;
    top: 0;
    left: -11px;
  }
}
@media (min-width: 768px) {
  .Block.Block--profile {
    padding-bottom: 6em !important;
  }
}
@media (min-width: 768px) {
  .Block.Block--profile:not(.hasTopBorder) {
    border-top: none;
  }
}
@media (min-width: 768px) {
  .Block {
    padding: 1rem;
  }
}
.Block.Block--transparent {
  background: transparent;
}
.Block.Block--fit-content-height {
  height: auto;
}
.Block.Block--rounded {
  border-radius: 6px;
}
.Block.Block--shadowed {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
}
.Block.Block--light * {
  color: #fff;
}
.Block.Block--stroke {
  border: 1px solid #e1e1e1;
}

.Explore .Col-6 {
  display: flex;
  flex-wrap: wrap;
}
.Explore .Col-6 .Block {
  flex-grow: 1;
}

/*a.Block {
  &:focus,
  &:hover {
    @include dropShadow;
  }
}*/
.Row-title {
  margin-bottom: 0;
  color: #434a4f;
  font-size: 1.12em;
}

.SmallBlocks .Block {
  height: auto;
  min-height: 220px;
  margin-bottom: 1rem;
  border: 1px solid #e1e1e1;
  background-position: center top;
  background-size: auto 100%;
}
.SmallBlocks .Block:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .SmallBlocks .Block {
    flex-grow: 1;
    width: calc(50% - 30px);
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .SmallBlocks .Block:last-child {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .SmallBlocks .Block {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .SmallBlocks .Block:last-child {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .SmallBlocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.no-touchevents .FeatureEmployer-items .FeatureEmployer-item:focus, .no-touchevents .FeatureEmployer-items .FeatureEmployer-item:hover {
  transform: scale(1) translate3d(0, 0, 0);
  box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
}
.no-touchevents a.Block:focus, .no-touchevents a.Block:hover {
  box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
}
.no-touchevents .HotJob-job:focus, .no-touchevents .HotJob-job:hover {
  box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
  -webkit-box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
}
.no-touchevents .HotJob-job:focus .companyName,
.no-touchevents .HotJob-job:focus .HotJob-title, .no-touchevents .HotJob-job:hover .companyName,
.no-touchevents .HotJob-job:hover .HotJob-title {
  color: #005cb3;
}

.duration-zone {
  margin-top: 0;
}

.Article .cta {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.Article .HotJob {
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  .Article-contentContainer {
    display: block;
    align-self: center;
    margin: auto;
  }
}

.Article-content h1, .Article-content h2, .Article-content h3, .Article-content h4, .Article-content h5 {
  margin: 1.5em 0px 0.8em;
}
.Article-content .Article-grid h3:first-child {
  margin-top: 0;
}
.Article-content li, .Article-content blockquote,
.Article-content p {
  font-size: 18px;
  letter-spacing: 0.12px;
  line-height: 1.6;
}
.Article-content .HotJob h3 {
  margin-top: 0;
  font-size: 1em;
  margin-bottom: 1rem;
}
.Article-content .Subheading {
  font-size: 24px;
  line-height: 40px;
}
.Article-content .Button, .Article-content .RMP-MainContent .wp-block-button__link, .RMP-MainContent .Article-content .wp-block-button__link, .Article-content .RMP-UserWidget--Button {
  line-height: 1.2;
}
.Article-content ul {
  list-style: disc;
  margin: 1rem;
}

.quote {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.quote svg {
  min-width: 55px;
}
@media screen and (max-width: 540px) {
  .quote svg {
    display: none;
  }
}
.quote blockquote {
  color: black;
  letter-spacing: 0.26px;
  text-align: left;
  font-style: italic;
  margin-bottom: 1rem;
}
.quote blockquote cite {
  display: block;
  font-style: normal;
  font-weight: bold;
  margin-top: 0.5rem;
}
.quote blockquote cite a {
  color: black;
}

.Article-banner {
  background: #17274F;
  padding-top: 2rem;
  margin-bottom: 4rem;
}
@media (min-width: 1024px) {
  .Article-banner {
    padding-bottom: 2rem;
  }
}
.Article-banner h1 {
  color: white;
  margin-top: 0;
}
@media (min-width: 768px) {
  .Article-banner h1 {
    font-size: 48px;
    line-height: 60px;
  }
}
.Article-banner p {
  color: white;
}

.Article-bannerContent {
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .Article-bannerContent {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
  }
}

.Article-image,
.Article-bannerCopy {
  width: 100%;
  padding: 0;
}
@media (min-width: 1024px) {
  .Article-image,
.Article-bannerCopy {
    width: 50%;
  }
}

.Article-bannerCopy {
  padding-right: 1rem;
}
@media (max-width: 1024px) {
  .Article-bannerCopy {
    padding: 1rem;
  }
}

.Article-image {
  border: 4px solid #0999ff;
  font-size: 0;
  overflow: hidden;
}
@media (min-width: 33.75em) {
  .Article-image {
    border-radius: 8px;
  }
}
.Article-image img {
  width: 100%;
  height: 470px;
  object-fit: cover;
}

/*------------------------------------*\
    GRID FUNCTION
\*------------------------------------*/
/**
 * Space between columns
 */
/**
 * Number of total columns
 */
/**** COLOURS ****/
/* $start color */
/* $end color */
/**** SHADOWS ****/ /**** CDN Image with automatic query strings for caching ****/ /**
 * Clearfix
 */
/*------------------------------------*\
    GRID FUNCTION
\*------------------------------------*/
/**
 * Space between columns
 */
/**
 * Number of total columns
 */
.aside .wp-block-latest-posts {
  padding-left: 0;
}
.aside .menu {
  margin: 2rem 0;
  box-shadow: none;
}
.aside .menu-label {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 24px;
  color: #000000;
  font-weight: bold;
}
.aside a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.aside .wp-block-search__inside-wrapper input {
  flex-grow: 1;
}
.aside .Button, .aside .RMP-MainContent .wp-block-button__link, .RMP-MainContent .aside .wp-block-button__link, .aside .RMP-UserWidget--Button {
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .u-pullRight.aside {
    padding-left: 2rem !important;
  }
}

.RMP-Post--Author {
  background-color: #F5F5F5;
  padding: 16px;
  display: flex;
  gap: 16px;
}
.RMP-Post--Author .is-size-4 {
  margin-top: 0;
}
.RMP-Post--Author .media-left {
  flex-shrink: 0;
}

body {
  padding: 0 !important;
}

.blog {
  margin-bottom: 2rem;
}
.blog main {
  padding-top: 40px;
}
.blog .RMP-Post-FeaturedImage {
  margin-bottom: 20px;
}
.blog .RMP-Post-FeaturedImage img, .blog .RMP-Post-FeaturedImage picture {
  object-fit: cover;
  width: 100%;
  height: 400px;
}
.blog .RMP-Post-Date {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .blog .RMP-Post-FeaturedImage img, .blog .RMP-Post-FeaturedImage picture {
    height: 225px;
  }
}
.blog .RMP-PostTease .RMP-PostTease-Title {
  line-height: 1.2;
  padding-top: 10px;
  margin-bottom: 10px;
}
.blog .RMP-PostTease .RMP-PostTease-Title a {
  color: #434a4f;
}
.blog .RMP-PostTease .RMP-PostTease-Meta {
  font-size: 80%;
}
.blog .RMP-PostTease .RMP-PostTease-MetaItem {
  margin-right: 10px;
}
.blog .RMP-PostTease .RMP-PostTease-Excerpt {
  margin-bottom: 0;
}
.blog .RMP-PostTease .RMP-PostTease-Excerpt.is-api p {
  margin-bottom: 0;
}
.blog .RMP-PostTease .RMP-PostTease-Image img, .blog .RMP-PostTease .RMP-PostTease-Image picture {
  object-fit: cover;
  width: 100%;
  height: 225px;
}
@media screen and (max-width: 768px) {
  .blog .RMP-PostTease .RMP-PostTease-Image img, .blog .RMP-PostTease .RMP-PostTease-Image picture {
    height: 150px;
  }
  .blog .RMP-PostTease .p-lg {
    padding: 1rem;
  }
}
.blog .RMP-PostContent ul {
  list-style-type: disc;
  margin-left: 20px;
}

.wp-block-search__label {
  display: none;
}

.breadcrumb {
  font-size: 14px;
  padding: 16px 0;
}
.breadcrumb .Col-sm-12 {
  padding-bottom: 0;
}

.BlogSearchResults .Card, .BlogSearchResults .aside .menu, .aside .BlogSearchResults .menu {
  margin-bottom: 1rem;
  display: block;
  width: 100%;
}

.Article {
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.Article ul {
  list-style: disc;
  padding-left: 20px;
}
.Article ol {
  list-style: decimal;
  padding-left: 20px;
}

@media (min-width: 768px) {
  #pageheader .HeaderCount {
    padding: 19px 12px !important;
    cursor: pointer;
  }
}

.wp-block-button__link {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px !important;
}

.Article .wp-block-embed__wrapper {
  text-align: center;
}

.RMP-JobsWidget {
  margin: 1rem 0;
}

.RMP-JobsWidget--Results {
  display: flex;
  flex-wrap: wrap;
}

.RMP-JobsWidget--Job {
  border-radius: 6px !important;
}

a:where(:not(.wp-element-button)) {
  text-decoration: none !important;
}