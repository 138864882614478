// Variables
// --------------------------

$fa-font-path:         "../webfonts" !default;
$fa-font-size-base:    16px !default;
$fa-font-display:      block !default;
$fa-css-prefix:        fa !default;
$fa-version:           "5.15.4" !default;
$fa-border-color:      #eee !default;
$fa-inverse:           #fff !default;
$fa-li-width:          2em !default;
$fa-fw-width:          (20em / 16);
$fa-primary-opacity:   1 !default;
$fa-secondary-opacity: .4 !default;

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-address-card: \f2bb;
$fa-var-align-left: \f036;
$fa-var-amazon: \f270;
$fa-var-angle-double-left: \f100;
$fa-var-angle-double-right: \f101;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-apple: \f179;
$fa-var-archive: \f187;
$fa-var-arrow-left: \f060;
$fa-var-arrow-right: \f061;
$fa-var-ban: \f05e;
$fa-var-bars: \f0c9;
$fa-var-bell: \f0f3;
$fa-var-books: \f5db;
$fa-var-briefcase: \f0b1;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-clipboard-list: \f46d;
$fa-var-clipboard-list-check: \f737;
$fa-var-clock: \f017;
$fa-var-cloud-download: \f0ed;
$fa-var-cloud-upload-alt: \f382;
$fa-var-cog: \f013;
$fa-var-coins: \f51e;
$fa-var-columns: \f0db;
$fa-var-comment: \f075;
$fa-var-comment-alt: \f27a;
$fa-var-comment-alt-slash: \f4a9;
$fa-var-comment-dots: \f4ad;
$fa-var-comments: \f086;
$fa-var-compass: \f14e;
$fa-var-copy: \f0c5;
$fa-var-database: \f1c0;
$fa-var-desktop: \f108;
$fa-var-edit: \f044;
$fa-var-ellipsis-h: \f141;
$fa-var-envelope: \f0e0;
$fa-var-envelope-open-text: \f658;
$fa-var-exclamation-circle: \f06a;
$fa-var-exclamation-triangle: \f071;
$fa-var-external-link: \f08e;
$fa-var-external-link-alt: \f35d;
$fa-var-eye: \f06e;
$fa-var-facebook: \f09a;
$fa-var-file: \f15b;
$fa-var-file-alt: \f15c;
$fa-var-file-export: \f56e;
$fa-var-file-import: \f56f;
$fa-var-filter: \f0b0;
$fa-var-flag: \f024;
$fa-var-globe-americas: \f57d;
$fa-var-google: \f1a0;
$fa-var-graduation-cap: \f19d;
$fa-var-heart: \f004;
$fa-var-industry: \f275;
$fa-var-info: \f129;
$fa-var-info-circle: \f05a;
$fa-var-layer-group: \f5fd;
$fa-var-linkedin: \f08c;
$fa-var-list: \f03a;
$fa-var-list-ul: \f0ca;
$fa-var-lock: \f023;
$fa-var-lock-open: \f3c1;
$fa-var-long-arrow-down: \f175;
$fa-var-long-arrow-up: \f176;
$fa-var-map-marker: \f041;
$fa-var-map-marker-alt: \f3c5;
$fa-var-map-marker-smile: \f60d;
$fa-var-map-pin: \f276;
$fa-var-megaphone: \f675;
$fa-var-money-bill: \f0d6;
$fa-var-pen: \f304;
$fa-var-pencil: \f040;
$fa-var-play: \f04b;
$fa-var-plus: \f067;
$fa-var-podcast: \f2ce;
$fa-var-pound-sign: \f154;
$fa-var-presentation: \f685;
$fa-var-question-circle: \f059;
$fa-var-redo: \f01e;
$fa-var-save: \f0c7;
$fa-var-search: \f002;
$fa-var-searchengin: \f3eb;
$fa-var-share-alt: \f1e0;
$fa-var-smile-beam: \f5b8;
$fa-var-sort: \f0dc;
$fa-var-sort-down: \f0dd;
$fa-var-sort-up: \f0de;
$fa-var-spinner-third: \f3f4;
$fa-var-star: \f005;
$fa-var-star-half-alt: \f5c0;
$fa-var-sticky-note: \f249;
$fa-var-suitcase: \f0f2;
$fa-var-sync: \f021;
$fa-var-table: \f0ce;
$fa-var-tachometer-alt: \f3fd;
$fa-var-th-large: \f009;
$fa-var-thumbs-down: \f165;
$fa-var-thumbs-up: \f164;
$fa-var-thumbtack: \f08d;
$fa-var-times: \f00d;
$fa-var-trash: \f1f8;
$fa-var-trash-alt: \f2ed;
$fa-var-trophy: \f091;
$fa-var-trophy-alt: \f2eb;
$fa-var-twitter: \f099;
$fa-var-university: \f19c;
$fa-var-user: \f007;
$fa-var-user-cog: \f4fe;
$fa-var-user-graduate: \f501;
$fa-var-users: \f0c0;
$fa-var-video: \f03d;
$fa-var-whatsapp: \f232;
$fa-var-whatsapp-square: \f40c;
