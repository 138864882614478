/**** COLOURS ****/
//Don't use root variables - for style guide generator

/* $start color */


/* $end color */

$black: #000;
$white: #fff;

$darkblue: #004b92;
$mediumblue: #005cb3;
$lightblue: #0069cd;
$borderblue: #b2cee8;
$homepageBlue: #17274F;
$homepageLine1: #E7007C;


$mediumyellow: #ffaa32;
$lightyellow: #ffb932;

$darkestgrey: #333;
$darkgrey: #434a4f;
$mediumgrey: #838789;
$lightgrey: #e1e1e1;
$lightestgrey: #F5F5F5;
$breadcrumb: #333;

$borderColor: #e1e1e1;

$darkgreen: #239544;
$mediumgreen: #32b056;
$lightgreen: #35c85f;

$darkred: #d5362b;
$mediumred: #ff5043;
$lightred: #fb5f54;

$magenta: #e5007d;
$stepmeter: #99acbe;

//NEW
/// Colors
$colors: (
  'black': (
    'base': #000,
  ),
  'white': (
    'base': #fff
  ),
  'red': (
    'dark': #d5362b,
    'base': #ff5043,
    'light': #fb5f54,
  ),
  'magenta': (
    'base': #e5007d,
    'light': #f3abd2,
    'lightest': #E9CCDA,
  ),
  'orange': (
    'base': #e78042,
    'light': #fda977,
    'darkest': #F1892A
  ),
  'yellow': (
    'dark': #ff9b32,
    'base': #ffaa32,
    'light': #ffb932,
  ),
  'blue': (
    'dark': #004b92,
    'base': #005cb3,
    'light': #0069cd,
    'lightest': #b2cee8,
  ),
  'green': (
    'dark': #239544,
    'base': #32b056,
    'light': #35c85f,
  ),
  'grey': (
    'darkest': #333,
    'dark': #434a4f,
    'base': #848281,
    'light': #e7e7ef,
    'lightest': #F2F2F7,
  ),
  // RMA COLOURS GETTING USE Errors in rate
  // Renabled error warning to show colours that can be used
  'turquoise': (
    'base': #00c5b1,
  ),
);

/// Returns the color for a given color name and group.
///
/// @param {String} $hue - The color’s hue.
/// @param {String} $value ['base'] - The darkness/lightness of the color. Defaults to
/// base.
/// @return {Color} The color value.
/// @example
///   color: color('yellow', 'light');
///   background: color('green')
@function color($hue, $value: base) {
  $fetched-color: $hue;

  @if map-has-key($colors, $fetched-color) {
    $fetched-color: map-get(
      map-get($colors, $fetched-color),
      $value
    );
  }

  @if type-of($fetched-color) == color {

    @return $fetched-color;
  } @else {
    // stylelint-disable string-no-newline
    // prettier-ignore
     @error 'Color `#{$hue} - #{$value}` not found.  Make sure arguments are strings - GOOD: color(\'yellow\'), BAD: color(' yellow '). Available options: #{available-names($colors)}';
    // stylelint-enable
  }
}
