/* -- Autocompletes -- */

div.autocomplete {
  display: block;
  clear: both;
}

div.autocomplete-select {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  @include z-index(dropdown);
}

.autocomplete span.highlight-word {
  background-color: color('yellow', 'light');
  color: $black;
}

.realtime-results-wrapper {
  position: relative;
  width: 100%;
}

div.realtime-results {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px 0;
  border: 1px solid #ccc;
  background: $white;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  @include z-index(dropdown);
}

div.realtime-results ul {
  margin: 0;
}

div.realtime-results ul li {
  display: block;
  margin-left: 0;
  padding: 3px 6px;
  font-weight: $font-weight-heavy;
  text-decoration: none;
  cursor: pointer;
}

div.realtime-results li.hover {
  background: color('grey', 'light');
  color: #0a3555;
}


// Vue select
.AutoComplete-image {
  display: inline-block;
  width: 30px;
  overflow: hidden;
  vertical-align: middle;
}

.Form-autoComplete {
  .dropdown li a {
    display: block;
    width: 100%;
  }
}

// Algolia search

.SearchPage {
  .aa-Panel { 

    border: 1px solid #1a90ff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 0 0 2px #1a90ff;
    margin: 0;
    max-height: 400px;

    .aa-PanelLayout {
      padding: 0 !important;
    }

    .aa-Panel-body {
      padding: 0;
    }

    .aa-Item {
      padding: 0;
      border-radius: 0;
    }

    div.aa-Item {
      padding-left: 12px;


      &:hover {
        background: #005cb3 !important;
        a {
          color: #fff;
        }
      }
      
  }
  }
}

// Overide default variables
// https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-theme-classic/
:root {
  --aa-panel-max-height: 400px !important;
}