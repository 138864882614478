@mixin radio-check-input() {
  display: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

/// radio-check-pseudo
/// default styling for both radio and checkbox, do not have to provide any parameters
/// @example
///   @include radio-check-pseudo($size: 20px, $bg:#e2e0dc, $border: #e2e0dc);
/// @param {type} $type [none] - `$size`, `$bg`, `$border
@mixin radio-check-pseudo($size: 20px, $bg: #e2e0dc, $border: #e2e0dc) {
  width: $size;
  height: $size;
  border-color: $border;
  background: $bg;
  line-height: $size;
  content: '';
}

/// Checked
/// Applies relevant :checked styles for either radio or checkbox
/// @example
///   @include checked('radio');
/// @param {type} $type [none] - `checkbox`, `radio`
@mixin checked($type) {
  @if ($type == 'radio') {
    border-color: color($primary);
    background: color('white') !important;
  }
  @else if ($type == 'checkbox') {
    @extend .far;
    border-color: color($primary);
    background: color($primary);
    color: color('white');
    line-height: 20px;
    content: fa-content($fa-var-check);
  }
}

@mixin input-default() {
  //margin-bottom: 1em;
  display: block;
  min-width: 40px;
  padding: 0 1rem;
  border: 1px solid lighten(color('grey'), 10%);
  background: #fff;
  color: color('grey', 'darkest');
  border-radius: radius(md);
  font-size: 1em;
  -webkit-appearance: none;
}

@mixin input-error() {
  &.error {
    border-color: color('red', 'light');
  }
}

@mixin input-focus() {
  &:focus {
    border-color: lighten(color($primary), 20%) !important;
    box-shadow: 0 0 0 2px lighten(color($primary), 20%);
  }
}

@mixin disabled() {
  &.disabled,
  &:disabled{
    background: color('grey', 'light');
    border-color:  color('grey', 'light');
    color:  color('grey');
    &:hover {
      cursor: not-allowed;
    }
  }
}
