.Button {
  height: 48px;
  min-width: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: radius(md);
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  letter-spacing: -.12px;
  font-weight: $font-weight-heavy;
  //display: inline-block;
  justify-content: center;
  display: inline-flex;
  align-items: center;
}

.Button--long {
  width: 100%;
}

.Button--green,
.Button--primary {
  background: $primary-button-background;
  text-transform: uppercase;
  color: $primary-button-text-color;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);
  &:focus,
  &:hover {
    background: $primary-button-background-hover;
    color: $primary-button-text-color;
  }
}

.Button--danger {
  background: $danger-button-background;
  text-transform: uppercase;
  color: $danger-button-text-color;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);
  &:focus,
  &:hover {
    background: $danger-button-background-hover;
    color: $danger-button-text-color;
  }
}

.Button--blue,
.Button--secondary {
  background: $secondary-button-background;
  color: $secondary-button-text-color;
  text-transform: uppercase;
  &:focus,
  &:hover {
    background: $secondary-button-background-hover;
  }
}

.Button--tertiary {
  background: $tertiary-button-background;
  border: 1px solid $tertiary-button-border-colour;
  color: $tertiary-button-text-color;
  line-height: 1.2;
  text-transform: uppercase;
  &:focus,
  &:hover {
    background: $tertiary-button-background-hover;
  }
}

.Button--grey,
.Button--subtle {
  background: $subtle-button-background;
  color: $subtle-button-text-color;
  line-height: 48px;
  &:focus,
  &:hover {
    background: $subtle-button-background-hover;
  }
}

.Button--disabled {
  background: $lightgrey;
  color: color('grey');
  border: none;
  cursor: auto;

  &:focus,
  &:hover {
    background: $lightgrey;
    color: color('grey');
  }
}

.Button-iconLeft {
  font-size: 24px;
  margin-right: 8px;
}

.Button-iconRight {
  font-size: 24px;
  margin-left: 8px;
}

.Button-icon {
  font-size: 24px;
}


.Button + .Button {
  margin-left: 16px;
}

.Button--iconOnly {
  .Shortlist-label {
    display: none;
  }
}

.text-link {
  text-decoration: none;
  color: $text-link;

  &::after {
    position: relative;
    @extend .fas;
    font-weight: 400;
    top: 1px;
    left: 10px;
    content: "\F061";
    transition: left .2s ease-out;
  }

  &:hover {
    &::after {
      left: 15px;
    }
  }
}

.Button.text-link::after {
  content: '';
}

//Override
header#pageheader .AccountNav li.AccountNav-login a {
  border-radius: 8px;
}

.Button--small {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;

  span {
    font-size: 16px;
  }
}

.Buttons {
  text-align: right;
  display: flex;
}

// ------------- loading spinner -------------

$loadingSpinnerDiameter: 28px;
$loadingSpinnerStrokeWidth: 4px;
$loadingSpinnerStrokeColor: inherit;
$loadingSpinnerOpacity: .8;
$loadingSpinnerStrokeStyle: solid;
$loadingSpinnerSpeed: .7s;

.spinner, //legacy delete when no longer referenced
.loading {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: inherit;
    height: 100%;
    border-radius: inherit;
  }

}

.spinner::before, //legacy delete when no longer referenced
.loading::before {
  content: '';
  border: $loadingSpinnerStrokeWidth $loadingSpinnerStrokeStyle $loadingSpinnerStrokeColor;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  opacity: $loadingSpinnerOpacity;
  border-radius: 50%;
  animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
  margin-top: -10px;
  margin-left: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(360deg)
  }
}


//Move
.m-fullWidth {
  width: 100%;
  @include respond-to(xs) {
    width: auto;
  }
}

// Auth switcher
// Todo make it generic


.AuthSwitcher {
  width: 100%;
}
.AuthSwitcher-type {
  margin-bottom: .5rem;
  display: inline-flex;
  width: calc(50% - .7em);
  margin-bottom: spacing();

  .Button {
    width: 100%;
  }

  &:last-child {
    margin-left: 1em;
  }
}

.AuthSwitcher-link {
  border: 2px solid #e7e7ef;
  color: #000;

  &.is-active {
    background: #eef3f8;
    border: 2px solid #005cb3;
    color: #005cb3;
  }
}