#pageheader {
  border-bottom: 1px solid color($primary, 'dark');
}

.Search-buttonIcon {
  @include respond-to(sm) {
    display: none;
  }
}

.HomePageTwo {
  .Search-buttonIcon {
    display: none;
  }
}

.SuperSearch-grid {
  //min-height: 400px;
  position: relative;
  padding: 80px 15px 15px;
  text-align: center;
  @include respond-to(sm) {
    padding: 40px 130px;
  }
}

.SuperSearch-supText {
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
}

.SuperSearch {
  @include respond-to(sm, max-width) {
    .Button,
    .Form-select,
    .Form-input {
      border-radius: border-radius(xl);
    }

    .Form-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .Form-select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .Button,
    .Form-selectDropDown,
    .Form-input {
      margin-bottom: spacing();
    }
  }

  &.noHeroBanner {
    padding-bottom: 0;
  }

  @include respond-to(xs) {
    background-position: top -120px right -50px;
    background-size: auto;
  }

  h1 {
    color: #fff;
    font-size: 25px;
    font-weight: $font-weight-heavy;
    text-shadow: 0px 0px 4px rgb(0, 0, 0, 0.5);

    text-transform: uppercase;
    @include respond-to(sm) {
      font-size: 50px;
    }
  }
}

.MegaSearch {
  background: color('white');
  border-bottom: 1px solid color('grey', 'light');
  padding-top: spacing();
  padding-bottom: spacing();
  margin-top: 51px;
  @include respond-to(sm) {
    margin-top: 0;
  }

  .Col-md-12 {
    padding-bottom: 0;
  }

  .Form-selectDropDown {
    height: 48px;
    margin-left: 0;
    line-height: 48px;
  }

  //.Button {
  //  height: 40px;
  //  margin-right: 0;
  //  font-size: 16px;
  //  line-height: 40px;
  //}

  .SiteSearch-form {
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    @include respond-to(sm, max-width) {
      span.twitter-typeahead .tt-menu {
        right: -40px;
        left: auto !important;
        width: calc(100% + 148px);
      }

      .Form-search {
        flex-shrink: 0;
        width: calc(100% - 148px);
      }

      .Form-selectDropDown {
        flex-shrink: 0;
        width: 100px;
        margin: 0;
      }
    }

    .Search-buttonText {
      display: block;
      @include respond-to(sm, max-width) {
        display: none;
      }
    }

    .Search-buttonIcon {
      display: none;
      @include respond-to(sm, max-width) {
        display: block;
        margin: 0;
      }
    }

    @include respond-to(sm) {
      flex-wrap: wrap;
    }

    .Button,
    .Form-search,
    .Form-selectDropDown {
      @include respond-to(sm) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.SiteSearch-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;

  button {
    flex-grow: 1;
    width: 120px;
    height: 48px;
    margin: 0;
    float: left;
    font-size: 18px;
    line-height: 48px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    @include respond-to(sm, max-width) {
      display: none;
    }
  }

  .Form-input,
  .Form-select {
    min-width: 40px;
    margin: 0;
    height: 48px;
    background-color: color('white');
    font-size: 1em;

    &:active {
      z-index: 4;
    }

    @include respond-to(sm) {
      height: 48px;
    }
  }

  .Form-select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  .Form-input {
    border-radius: 0;
  }
}

.Form-search {
  position: relative;
  width: 100%;
  margin: 0;
  flex-grow: 1;

  input {
    width: 100%;
  }

  &::before {
    @extend .far;
  }

  @include respond-to(sm) {
    width: calc(100% - 328px);
    height: auto;
    float: left;
    &::before {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(50%, -50%);
      content: fa-content($fa-var-search);
      z-index: 1;
    }
    .Form-input {
      padding-left: 45px;
    }
  }

}

.Form-selectDropDown {
  position: relative;
  margin: 0;

  &::before {
    position: absolute;
    @extend .far;
    top: 50%;
    right: 20px;
    transform: translate(50%, -50%);
    content: fa-content($fa-var-angle-down);
  }

  @include respond-to(sm) {
    width: 160px;
    height: auto;
    float: left;
  }

  .Form-select {
    width: 100%;
  }
}

.SuperSearch-supText a,
.SuperSearch-supText {
  border: none;
  font-size: 1em;
  font-weight: $font-weight-heavy;
  @include respond-to(md) {
    font-size: 1.5em;
  }
}

.SuperSearch-supText a:hover {
  color: color('green');
}

// Feature emplpyer
.FeaturedTopEmployer {
  //position: absolute;
  display: flex;
  bottom: 30px;
  border-top: 1px solid rgba(255, 255, 255, .3);
  background: color($primary);
  text-align: left;

  .Container {
    margin: 0 auto;
  }

  .Logo {
    display: inline-block;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin-right: $space-default;
    border: 1px solid color('white');
    vertical-align: top;
  }

  span {
    color: #fff;
  }

  .text-link {
    align-self: center;
    margin-right: 10px;
    margin-left: auto;
    float: right;
    color: #fff;
  }
}

.FeaturedTopEmployer-name {
  display: block;
  float: left;
  clear: both;
  font-size: 18px;
  font-weight: $font-weight-heavy;
}

.FeaturedTopEmployer-copy {
  display: inline-block;
  width: calc(100% - 90px);
  @include respond-to(xs) {
    width: calc(100% - 330px);
  }
}

.FeaturedTopEmployer-tagline {
  float: left;
  clear: both;
  font-size: 14px;
  line-height: 1.2;
  @include respond-to(sm) {
    font-size: 16px;
  }
}

.PremiumEmployer {
  display: flex;
  flex-wrap: wrap;

  .Shortlist {
    margin-top: $space-s;
    float: right;

    .FollowIcon-label {
      //color: $mediumgreen;
      font-size: 14px;
    }
  }

  @include respond-to(sm) {
    .Shortlist {
      float: right;
      //margin-top: -19px;
    }
  }
}

.Container.BannerWithInternal {
  position: relative;
  margin-top: -140px;
  margin-bottom: $space-default;

  &.noHeroBlock {
    margin-top: 0;
  }
}

// Quick search
.QuickSearch {
  width: 844px;
  max-width: 100%;
  margin: 2px auto;
  padding: 2px 16px;
  border-radius: 4px;
  text-align: center;
  @include respond-to(sm) {
    width: calc(850px - 1rem);
    margin: $space-s auto;
  }
}

.QuickSearch-title,
.QuickSearch-link {
  display: inline-block;
  margin: 0;
  font-size: 1em;
}

.QuickSearch-title {
  font-weight: $font-weight-heavy;
  margin-right: $space-s;
}

.QuickSearch-link {
  text-decoration: underline;

  &:focus, &:hover {
    color: color('green');
  }
}

.Form-search {
  .LoadingScreen {
    top: 0;
    right: 0;
    left: auto;
    width: 30px;
    height: 100%;
    background: transparent;
  }

  .LoadingScreen-spinner {
    position: absolute;
    right: spacing();
    left: auto;
    transform: translateY(-50%);
  }
}


//NEW
#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  margin: 0;
  top: 100%;
  position: absolute;
  @include z-index(overlay);
  width: 100%;
  border: 1px solid lighten(color($primary), 20%);
  box-shadow: 0 0 0 2px lighten(color($primary), 20%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results_item {
  cursor: pointer;
  padding: .5rem 15px;
  color: #000;
  text-align: left;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: color('grey');
  font-size: 13px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid color('grey', 'light');
  text-align: left;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results
.autosuggest__results_item.autosuggest__results_item-highlighted {
  background-color: color($primary);
  color: #fff;
}
