//$body-background-color: #fff //$scheme-main !default;
//$body-size: 16px !default;
//$body-min-width: 300px !default;
//$body-rendering: optimizeLegibility !default;
//$body-family: $family-primary !default;
//$body-overflow-x: hidden !default;
//$body-overflow-y: scroll !default;
//$body-color: $text !default;
//$body-font-size: 1em !default;
//$body-weight: $weight-normal !default;
//$body-line-height: 1.5 !default;
//$code-family: $family-code !default;
//$code-padding: 0.25em 0.5em 0.25em !default;
//$code-weight: normal !default;
//$code-size: 0.875em !default;
//$small-font-size: 0.875em !default;
//$hr-background-color: $background !default;
//$hr-height: 2px !default;
//$hr-margin: 1.5rem 0 !default;
//$strong-color: $text-strong !default;
//$strong-weight: $weight-bold !default;
//$pre-font-size: 0.875em !default;
//$pre-padding: 1.25rem 1.5rem !default;
//$pre-code-font-size: 1em !default;

//@import url("https://use.typekit.net/csv1ana.css");

html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-family:  aktiv-grotesk, Helvetica Neue,Helvetica, sans-serif;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block
}

body,
button,
input,
select,
textarea {
  font-family:  aktiv-grotesk, Helvetica Neue,Helvetica, sans-serif;
}


body {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

// Inline

a {
  color: #005cb3;
  cursor: pointer;
  text-decoration: none
}


hr {
  background-color: #DDDDDD;
  border: none;
  display: block;
  height: 1px;
  margin-top: 64px;
  margin-bottom: 64px;
}

img {
  height: auto;
  max-width: 100%
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline
}

small {
  font-size: 13px;
}

span {
  font-style: inherit;
  font-weight: inherit
}

strong {
  //color: #000;
  font-weight:  600;
}

// Block

fieldset {
  border: none
}

table,
td,
th {
  vertical-align: top;

  &:not([align]) {
    text-align: inherit
  }
}

#breadcrumbs li:not(:last-child):after{
  content: '/'
}



.color-primary {
  color: color('blue');
}
.spacer-md{
  height: 16px;
  width: 16px;
  display: block;
}

.Card.has-hover,
.has-hover {
  border:  2px solid transparent;
  box-shadow: none;
  &:hover {
    //border-color: color($primary);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12);
  }
}
