/// @group Spacing
$space-xs: 0.25em; // 4px
/// @group Spacing
$space-s: 0.5em; // 8px
/// @group Spacing
$space-default: 1em; // 16px
/// @group Spacing
$space-l: 2em; // 32
/// @group Spacing
$space-xl: 4em; // 64px

$unit: 4px;

$spacings: (
  none: 0,
  xs: toRem($unit),
  sm: toRem($unit * 2),
  base: toRem($unit * 4),
  lg: toRem($unit * 8),
  xl: toRem($unit * 16),
);

/// Returns the spacing value for a given variant.
///
/// @param {String} $variant - The key for the given variant.
/// @return {Number} The spacing for the variant.
@function spacing($space: base) {
  $fetched-value: map-get($spacings, $space);

  @if type-of($fetched-value) == number {
    @return $fetched-value;
  } @else {
    @error 'Spacing variant `#{$space}` not found. Available variants: #{available-names($spacings)}';
  }
}
