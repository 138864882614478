.multiselector-contents {
  @include z-index(modal);
  .sector-title {
    width: 100%;
    float: left;
    h4 {
      color: color($primary) !important;
    }
  }
}

.js .multiselector .checkboxes {
  display: none;
}

.js .multiselector .checkbox {
  width: 50%;
  float: left;
}

.js .multiselector .label {
  width: 100%;
  margin-bottom: 8px;
  float: left;
}

.js .multiselector h3 {
  width: 100%;
  margin-bottom: 10px;
  float: left;
}

.js .multiselector div.select-controls {
  width: 30%;
  margin-top: 4px;
  float: right;
  text-align: right;
}

.js .multiselector a {
  margin-left: 6px;
}

.js .multiselector p {
  margin-left: 122px;
  clear: both;
}

.js .multiselector p.change-selection {
  display: inline-block;
  width: 100%;
  margin: 0;
  margin-top: 5px;
  font-size: 0.875rem;
}

.js .multiselector p.change-selection a {
  margin-left: 0;
}

.js .multiselector-display {
  width: 100%;
  margin-bottom: 0 !important;
  float: left;
  overflow: hidden;
  zoom: 1;
}

.js .multiselector-display li {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 8px 14px 6px;
  background-color: color('yellow', 'dark');
  color: $white;
  line-height: 1;
  cursor: pointer;
}

.js .multiselector-display li:hover,
.js .multiselector-display li:focus {
  background-color: $mediumyellow;
}

.js .multiselector-display li.nothing-selected {
  background-color: $mediumred;
  color: #fff;
}

.js .multiselector-contents {
  display: none;
  position: fixed;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  max-width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  @include z-index(overlay);
  box-shadow: 0 8px 15px 1px, rgba(0, 0, 0, 0.15);
  overflow: hidden;
  zoom: 1;
  @include borderRadius(6px);
  @include respond-to(sm) {
    top: 175px;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 600px;
    margin-left: -300px;
    transform: none;
  }

  h3, h2, h1 {
    margin: 0;
    padding: $space-s $space-default;
    background: $darkestgrey;
    color: #fff;
  }

  .Button {
    margin: $space-s $space-default;
  }
}

.js .multiselector-fields {
  width: 100%;
  height: calc(100% - 130px);
  padding: 0 $space-default;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow: scroll;
  //background: #f3f3f3;
  overflow-x: hidden;
  zoom: 1;
  @include respond-to(sm) {
    height: 234px;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    clear: both;
    .checkbox {
      display: flex;
      width: 100%;
      @include respond-to(xs) {
        width: 50%;
      }
    }
  }
}

#application_requirements-outer-wrapper {
  width: 100%;
}

.js .multiselector-fields .sector-title {
  margin-top: 1em;
  clear: left;
}

.js .multiselector-fields label,
.js .multiselector-fields input {
  cursor: pointer;
}
