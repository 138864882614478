/* CONTACT ============================================================================= */
.ReportProblem{
  margin-top: 32px;
}
.ContactUsForm {
  margin-bottom: $space-xl !important;
}

.terms section li {
  margin: 0 0 0 50px;
  list-style: disc;
  list-style-position: outside;
}

.terms table td {
  padding: 10px;
  font-weight: 100;
}

.about section,
.team section {
  display: inline-block;
  width: calc(100% - 40px);
  margin: 20px 20px;
}

.full-banner {
  background-color: color($primary);
  margin-bottom: 20px;
  text-align: center;
  height: 350px;
  border-radius: radius();
  overflow: hidden;
  margin-top: spacing();

  h1, p {
    color: #fff;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 70%);
  }

  .contact-header {
    @include cdn-background-image('/css/misc/contact-us.jpg');

  }
}


.AboutUser{
  display: flex;
  @include respond-to(sm, 'max-width') {
    flex-wrap: wrap;
  }
  .Card{
    margin-right: spacing();
    &:last-child{
      margin-right: 0;
    }
    @include respond-to(sm, 'max-width') {
      width: 100%;
      margin-right: 0;
      margin-bottom: spacing();
    }
  }
}











