.AdviceBlock-title {
  display: flex;
  align-items: center;
}

.AdviceBlock-content {
  margin-right: $space-xs;
}

.top-employer-tag {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0;
}

.Block {
  display: block;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: spacing();;
  //transition: ease .25s;

  .TopEmployer-icon {
    display: none;
    @include respond-to(sm) {
      display: block;
      position: absolute;
      top: 0;
      left: -11px;
    }
  }

  &.Block--profile {
    @include respond-to(sm) {
      padding-bottom: 6em !important;
    }
    @extend .clearfix;
    &:not(.hasTopBorder) {
      @include respond-to(sm) {
        border-top: none;
      }
    }

  }

  @include respond-to(sm) {
    padding: spacing();;
  }
  background: #fff;

  &.Block--transparent {
    background: transparent;
  }

  &.Block--fit-content-height {
    height: auto;
  }

  &.Block--rounded {
    @include borderRadius();
  }

  &.Block--shadowed {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  }

  &.Block--light {
    * {
      color: #fff;
    }
  }

  &.Block--stroke {
    border: 1px solid $lightgrey;
  }
}

.Explore {
  .Col-6 {
    display: flex;
    flex-wrap: wrap;

    .Block {
      flex-grow: 1;
    }
  }
}

/*a.Block {
  &:focus,
  &:hover {
    @include dropShadow;
  }
}*/

.Row-title {
  margin-bottom: 0;
  color: $darkgrey;
  font-size: 1.12em;
}

.SmallBlocks {
  .Block {
    height: auto;
    min-height: 220px;
    margin-bottom: spacing();
    border: 1px solid $borderColor;
    background-position: center top;
    background-size: auto 100%; //Default

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-to(sm) {
      flex-grow: 1;
      width: calc(50% - 30px);
      margin-right: spacing();
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }

    @include respond-to(md) {
      width: 100%;
      margin-right: 0;
      margin-bottom: spacing();
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include respond-to(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.no-touchevents {
  .FeatureEmployer-items .FeatureEmployer-item {
    &:focus, &:hover {
      transform: scale(1) translate3d(0, 0, 0);
      box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
    }
  }

  a.Block:focus, a.Block:hover {
    box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
  }

  .HotJob-job:focus, .HotJob-job:hover {
    box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);
    z-index: 1;
    -webkit-box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.15);

    .companyName,
    .HotJob-title {
      color: color($primary);
    }
  }
}

//*{
//outline: 1px solid red !important;
//background: #000 !important;
//color:#000 !important;
//}
