/*
$styleguide

$title Grid

12 Column Flexbox grid system with float fallback, Todo - extend to Grid future proof

Container =
  .Container
Grid Row =
  .Row
Columns
  @mixin Col-lg-(column number) desktop, Col-md-(number) medium size screen, .Col-sm-(number) mobile / small size screen <br>

<div class='Container'>
       <div class='Row'>
           <div class='Col-sm-12'>
               <div class='box-row'> 12 </div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-1'>
               <div class='box-row'> 1 </div>
           </div>
           <div class='Col-sm-11'>
               <div class='box-row'> 11 </div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-2'>
               <div class='box-row'> 2 </div>
           </div>
           <div class='Col-sm-10'>
               <div class='box-row'>10</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-3'>
               <div class='box-row'>3</div>
           </div>
           <div class='Col-sm-9'>
               <div class='box-row'>9</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-4'>
               <div class='box-row'>4</div>
           </div>
           <div class='Col-sm-8'>
               <div class='box-row'>8</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-sm-9 Col-sm-5'>
               <div class='box-row'>5</div>
           </div>
           <div class='Col-sm3 Col-sm-7'>
               <div class='box-row'>7</div>
           </div>
       </div>
       <div class='Row'>
           <div class='Col-6 Col-md-6'>
               <div class='box-row'>6</div>
           </div>
           <div class='Col-6 Col-md-6'>
              <div class='box-row'>6</div>
           </div>
       </div>
</div>

*/


[class^='Col-'],
[class*='Col-'] {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  /*  @include respond-to(md) {
      padding: $space-default;
    }*/
  box-sizing: border-box;

  &.Col--pullRight {
    margin-left: auto;
    float: Right;
  }

  &.Col--pullLeft {
    margin-left: auto;
    float: left;
  }
}


@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);
  @include respond-to($name) {
    @include grid-loop($name);
  }
}
