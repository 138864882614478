/* FOOTER ============================================================================= */
#pagefooter {
  text-align: center;

  .Footer-links {
    margin-top: -1px;
    margin-bottom: 0;
    background:  color($primary, 'dark');
    p,
    ul {
      display: inline-block;
      float: none;
      vertical-align: middle;
    }

    p {
      padding: 15px 20px;

    }

    li a {
      display: block;
      padding: $space-s 0;
      &:hover, &:focus {
        background: color($primary);
      }

      @include respond-to(xs) {
        display: inline-block;
        padding: 15px 8px;
      }

      @include respond-to(md) {
        padding: 15px 20px;
      }
    }
  }

  .Footer-declaration {
    background: color($primary);

    .Copyright {
      margin-top: 0;
      padding-top: 0.5em
    }
    p {
      margin: 1em;
      color: #fff;
      font-size: 0.875rem;
      text-transform: uppercase;
    }

    p,
    .Footer-links {
      background: transparent;
      @include respond-to(sm) {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  ul .Footer-link {
    width: 100%;
    float: none;
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-to(xs, 'max-width') {
      padding: 0.2rem 0;
    }

    @include respond-to(xs) {
      display: inline-block;
      width: auto;
    }
  }

  ul a span {
    font-size: 1.8rem;
  }

  .Footer-links {
    li a {
      color: #fff;
      font-size: 0.875rem;
      text-transform: uppercase;
    }
  }
}

#disclaimer {
  clear: both;
  p {
    margin-bottom: 0;
    padding: 10px 20px;
    font-size: 13px;
  }
}
