/// border radius
$border-radius-data: (
        base: 3px,
        large: 6px,
        xl: 8px,
);

/// Returns the border radius of the specified size.
/// @param {String} $size ['large' or 'empty'] - The border radius’s size.
/// @return {Number} ['3px', '6px' or '8px']The border radius value.
@function border-radius($size: base) {
  @return map-get($border-radius-data, $size);
}

// old
$radius: 6px;
@mixin borderRadius($radius: 6px) {
  border-radius: $radius;
}

@mixin border-radius($radius: 3px) {
  border-radius: $radius;
}
