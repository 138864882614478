
// theming
// Reference to colours above
$primary: 'blue';
$homepagePrimary: $homepageBlue;
$border-color: #DDDDDD !default;
//$accent: 'blue';

// BUTTONS
// Primary Button
$primary-button-background: color('green')!default;
$primary-button-background-hover: color('green', 'dark')!default;
$primary-button-text-color: color('white')!default;
// Secondary Button
$secondary-button-background: color('grey', 'lightest') !default;
$secondary-button-background-hover: color('grey', 'light') !default;
$secondary-button-text-color: #282C34 !default;
// Danger Button
$danger-button-background: color('red') !default;
$danger-button-background-hover: color('red', 'dark') !default;
$danger-button-text-color: color('white') !default;
// Secondary Button
$tertiary-button-background: color('white') !default;
$tertiary-button-background-hover:rgba(50, 176, 86, 0.1) !default;
$tertiary-button-border-colour: color('green') !default;
$tertiary-button-text-color: color('green') !default;
// Subtle Button
$subtle-button-background: rgba(255, 255, 255, 0.4) !default;
$subtle-button-background-hover:color('grey', 'lightest') !default;
$subtle-button-text-color: #282C34 !default;

// Text Link
$text-link: color($primary) !default;
$text-hover: color('green') !default;
// Border
