/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-address-card:before { content: fa-content($fa-var-address-card); }
.#{$fa-css-prefix}-align-left:before { content: fa-content($fa-var-align-left); }
.#{$fa-css-prefix}-amazon:before { content: fa-content($fa-var-amazon); }
.#{$fa-css-prefix}-angle-double-left:before { content: fa-content($fa-var-angle-double-left); }
.#{$fa-css-prefix}-angle-double-right:before { content: fa-content($fa-var-angle-double-right); }
.#{$fa-css-prefix}-angle-down:before { content: fa-content($fa-var-angle-down); }
.#{$fa-css-prefix}-angle-left:before { content: fa-content($fa-var-angle-left); }
.#{$fa-css-prefix}-angle-right:before { content: fa-content($fa-var-angle-right); }
.#{$fa-css-prefix}-apple:before { content: fa-content($fa-var-apple); }
.#{$fa-css-prefix}-archive:before { content: fa-content($fa-var-archive); }
.#{$fa-css-prefix}-arrow-left:before { content: fa-content($fa-var-arrow-left); }
.#{$fa-css-prefix}-arrow-right:before { content: fa-content($fa-var-arrow-right); }
.#{$fa-css-prefix}-ban:before { content: fa-content($fa-var-ban); }
.#{$fa-css-prefix}-bars:before { content: fa-content($fa-var-bars); }
.#{$fa-css-prefix}-bell:before { content: fa-content($fa-var-bell); }
.#{$fa-css-prefix}-books:before { content: fa-content($fa-var-books); }
.#{$fa-css-prefix}-briefcase:before { content: fa-content($fa-var-briefcase); }
.#{$fa-css-prefix}-bug:before { content: fa-content($fa-var-bug); }
.#{$fa-css-prefix}-building:before { content: fa-content($fa-var-building); }
.#{$fa-css-prefix}-calendar:before { content: fa-content($fa-var-calendar); }
.#{$fa-css-prefix}-calendar-alt:before { content: fa-content($fa-var-calendar-alt); }
.#{$fa-css-prefix}-chalkboard-teacher:before { content: fa-content($fa-var-chalkboard-teacher); }
.#{$fa-css-prefix}-check:before { content: fa-content($fa-var-check); }
.#{$fa-css-prefix}-check-circle:before { content: fa-content($fa-var-check-circle); }
.#{$fa-css-prefix}-chevron-down:before { content: fa-content($fa-var-chevron-down); }
.#{$fa-css-prefix}-chevron-left:before { content: fa-content($fa-var-chevron-left); }
.#{$fa-css-prefix}-chevron-right:before { content: fa-content($fa-var-chevron-right); }
.#{$fa-css-prefix}-chevron-up:before { content: fa-content($fa-var-chevron-up); }
.#{$fa-css-prefix}-clipboard-list:before { content: fa-content($fa-var-clipboard-list); }
.#{$fa-css-prefix}-clipboard-list-check:before { content: fa-content($fa-var-clipboard-list-check); }
.#{$fa-css-prefix}-clock:before { content: fa-content($fa-var-clock); }
.#{$fa-css-prefix}-cloud-download:before { content: fa-content($fa-var-cloud-download); }
.#{$fa-css-prefix}-cloud-upload-alt:before { content: fa-content($fa-var-cloud-upload-alt); }
.#{$fa-css-prefix}-cog:before { content: fa-content($fa-var-cog); }
.#{$fa-css-prefix}-coins:before { content: fa-content($fa-var-coins); }
.#{$fa-css-prefix}-columns:before { content: fa-content($fa-var-columns); }
.#{$fa-css-prefix}-comment:before { content: fa-content($fa-var-comment); }
.#{$fa-css-prefix}-comment-alt:before { content: fa-content($fa-var-comment-alt); }
.#{$fa-css-prefix}-comment-alt-slash:before { content: fa-content($fa-var-comment-alt-slash); }
.#{$fa-css-prefix}-comment-dots:before { content: fa-content($fa-var-comment-dots); }
.#{$fa-css-prefix}-comments:before { content: fa-content($fa-var-comments); }
.#{$fa-css-prefix}-compass:before { content: fa-content($fa-var-compass); }
.#{$fa-css-prefix}-copy:before { content: fa-content($fa-var-copy); }
.#{$fa-css-prefix}-database:before { content: fa-content($fa-var-database); }
.#{$fa-css-prefix}-desktop:before { content: fa-content($fa-var-desktop); }
.#{$fa-css-prefix}-edit:before { content: fa-content($fa-var-edit); }
.#{$fa-css-prefix}-ellipsis-h:before { content: fa-content($fa-var-ellipsis-h); }
.#{$fa-css-prefix}-envelope:before { content: fa-content($fa-var-envelope); }
.#{$fa-css-prefix}-envelope-open-text:before { content: fa-content($fa-var-envelope-open-text); }
.#{$fa-css-prefix}-exclamation-circle:before { content: fa-content($fa-var-exclamation-circle); }
.#{$fa-css-prefix}-exclamation-triangle:before { content: fa-content($fa-var-exclamation-triangle); }
.#{$fa-css-prefix}-external-link:before { content: fa-content($fa-var-external-link); }
.#{$fa-css-prefix}-external-link-alt:before { content: fa-content($fa-var-external-link-alt); }
.#{$fa-css-prefix}-eye:before { content: fa-content($fa-var-eye); }
.#{$fa-css-prefix}-facebook:before { content: fa-content($fa-var-facebook); }
.#{$fa-css-prefix}-file:before { content: fa-content($fa-var-file); }
.#{$fa-css-prefix}-file-alt:before { content: fa-content($fa-var-file-alt); }
.#{$fa-css-prefix}-file-export:before { content: fa-content($fa-var-file-export); }
.#{$fa-css-prefix}-file-import:before { content: fa-content($fa-var-file-import); }
.#{$fa-css-prefix}-filter:before { content: fa-content($fa-var-filter); }
.#{$fa-css-prefix}-flag:before { content: fa-content($fa-var-flag); }
.#{$fa-css-prefix}-globe-americas:before { content: fa-content($fa-var-globe-americas); }
.#{$fa-css-prefix}-google:before { content: fa-content($fa-var-google); }
.#{$fa-css-prefix}-graduation-cap:before { content: fa-content($fa-var-graduation-cap); }
.#{$fa-css-prefix}-heart:before { content: fa-content($fa-var-heart); }
.#{$fa-css-prefix}-industry:before { content: fa-content($fa-var-industry); }
.#{$fa-css-prefix}-info:before { content: fa-content($fa-var-info); }
.#{$fa-css-prefix}-info-circle:before { content: fa-content($fa-var-info-circle); }
.#{$fa-css-prefix}-layer-group:before { content: fa-content($fa-var-layer-group); }
.#{$fa-css-prefix}-linkedin:before { content: fa-content($fa-var-linkedin); }
.#{$fa-css-prefix}-list:before { content: fa-content($fa-var-list); }
.#{$fa-css-prefix}-list-ul:before { content: fa-content($fa-var-list-ul); }
.#{$fa-css-prefix}-lock:before { content: fa-content($fa-var-lock); }
.#{$fa-css-prefix}-lock-open:before { content: fa-content($fa-var-lock-open); }
.#{$fa-css-prefix}-long-arrow-down:before { content: fa-content($fa-var-long-arrow-down); }
.#{$fa-css-prefix}-long-arrow-up:before { content: fa-content($fa-var-long-arrow-up); }
.#{$fa-css-prefix}-map-marker:before { content: fa-content($fa-var-map-marker); }
.#{$fa-css-prefix}-map-marker-alt:before { content: fa-content($fa-var-map-marker-alt); }
.#{$fa-css-prefix}-map-marker-smile:before { content: fa-content($fa-var-map-marker-smile); }
.#{$fa-css-prefix}-map-pin:before { content: fa-content($fa-var-map-pin); }
.#{$fa-css-prefix}-megaphone:before { content: fa-content($fa-var-megaphone); }
.#{$fa-css-prefix}-money-bill:before { content: fa-content($fa-var-money-bill); }
.#{$fa-css-prefix}-pen:before { content: fa-content($fa-var-pen); }
.#{$fa-css-prefix}-pencil:before { content: fa-content($fa-var-pencil); }
.#{$fa-css-prefix}-play:before { content: fa-content($fa-var-play); }
.#{$fa-css-prefix}-plus:before { content: fa-content($fa-var-plus); }
.#{$fa-css-prefix}-podcast:before { content: fa-content($fa-var-podcast); }
.#{$fa-css-prefix}-pound-sign:before { content: fa-content($fa-var-pound-sign); }
.#{$fa-css-prefix}-presentation:before { content: fa-content($fa-var-presentation); }
.#{$fa-css-prefix}-question-circle:before { content: fa-content($fa-var-question-circle); }
.#{$fa-css-prefix}-redo:before { content: fa-content($fa-var-redo); }
.#{$fa-css-prefix}-save:before { content: fa-content($fa-var-save); }
.#{$fa-css-prefix}-search:before { content: fa-content($fa-var-search); }
.#{$fa-css-prefix}-searchengin:before { content: fa-content($fa-var-searchengin); }
.#{$fa-css-prefix}-share-alt:before { content: fa-content($fa-var-share-alt); }
.#{$fa-css-prefix}-smile-beam:before { content: fa-content($fa-var-smile-beam); }
.#{$fa-css-prefix}-sort:before { content: fa-content($fa-var-sort); }
.#{$fa-css-prefix}-sort-down:before { content: fa-content($fa-var-sort-down); }
.#{$fa-css-prefix}-sort-up:before { content: fa-content($fa-var-sort-up); }
.#{$fa-css-prefix}-spinner-third:before { content: fa-content($fa-var-spinner-third); }
.#{$fa-css-prefix}-star:before { content: fa-content($fa-var-star); }
.#{$fa-css-prefix}-star-half-alt:before { content: fa-content($fa-var-star-half-alt); }
.#{$fa-css-prefix}-sticky-note:before { content: fa-content($fa-var-sticky-note); }
.#{$fa-css-prefix}-suitcase:before { content: fa-content($fa-var-suitcase); }
.#{$fa-css-prefix}-sync:before { content: fa-content($fa-var-sync); }
.#{$fa-css-prefix}-table:before { content: fa-content($fa-var-table); }
.#{$fa-css-prefix}-tachometer-alt:before { content: fa-content($fa-var-tachometer-alt); }
.#{$fa-css-prefix}-th-large:before { content: fa-content($fa-var-th-large); }
.#{$fa-css-prefix}-thumbs-down:before { content: fa-content($fa-var-thumbs-down); }
.#{$fa-css-prefix}-thumbs-up:before { content: fa-content($fa-var-thumbs-up); }
.#{$fa-css-prefix}-thumbtack:before { content: fa-content($fa-var-thumbtack); }
.#{$fa-css-prefix}-times:before { content: fa-content($fa-var-times); }
.#{$fa-css-prefix}-trash:before { content: fa-content($fa-var-trash); }
.#{$fa-css-prefix}-trash-alt:before { content: fa-content($fa-var-trash-alt); }
.#{$fa-css-prefix}-trophy:before { content: fa-content($fa-var-trophy); }
.#{$fa-css-prefix}-trophy-alt:before { content: fa-content($fa-var-trophy-alt); }
.#{$fa-css-prefix}-twitter:before { content: fa-content($fa-var-twitter); }
.#{$fa-css-prefix}-university:before { content: fa-content($fa-var-university); }
.#{$fa-css-prefix}-user:before { content: fa-content($fa-var-user); }
.#{$fa-css-prefix}-user-cog:before { content: fa-content($fa-var-user-cog); }
.#{$fa-css-prefix}-user-graduate:before { content: fa-content($fa-var-user-graduate); }
.#{$fa-css-prefix}-users:before { content: fa-content($fa-var-users); }
.#{$fa-css-prefix}-video:before { content: fa-content($fa-var-video); }
.#{$fa-css-prefix}-whatsapp:before { content: fa-content($fa-var-whatsapp); }
.#{$fa-css-prefix}-whatsapp-square:before { content: fa-content($fa-var-whatsapp-square); }
