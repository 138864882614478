.aside {
  .wp-block-latest-posts {
    padding-left: 0;
  }

  .menu {
    margin: spacing('lg') 0;
    @extend .Card;
    @extend .Card-content;
    box-shadow: none;
  }
  .menu-label{
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 24px;
    color: #000000;
    font-weight: bold;
  }

  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    //color: #000000;
  }
  .wp-block-search__inside-wrapper input{
    flex-grow: 1;
  }
  .Button{
    flex-shrink: 0;
  }
}

.u-pullRight.aside {
  @include respond-to('md') {
    padding-left: spacing(lg) !important;
  }
}

