//@import "../../../../../node_modules/@wordpress/block-library/src/archives/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/audio/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/button/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/buttons/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/calendar/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/categories/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/code/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/columns/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/comments-pagination/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/comment-template/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/cover/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/embed/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/file/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/gallery/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/group/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/heading/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/image/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/latest-comments/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/latest-posts/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/list/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/media-text/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/navigation/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/navigation-link/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/page-list/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/paragraph/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/post-author/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/post-comments/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/post-comments-form/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/post-excerpt/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/post-featured-image/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/post-terms/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/post-title/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/preformatted/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/pullquote/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/post-template/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/query-pagination/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/quote/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/read-more/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/rss/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/search/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/separator/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/site-logo/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/social-links/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/spacer/style.scss";
//@import "../../../../../node_modules/@wordpress/block-library/src/tag-cloud/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/table/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/text-columns/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/verse/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/video/style.scss";
@import "../../../../../node_modules/@wordpress/block-library/src/common.scss";


.BlogRow {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.BlogRow-item {
  width: 100%;
  @include respond-to(sm, 'min-width') {
    width: calc(50% - 16px);
  }

  &:hover {
    h2 {
      color: color($primary);
    }
  }
}

.Card-content {
  h2 {
    font-size: 18px;
    line-height: 30px;
  }
}

.Card-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.7);
}


.breadcrumb {
}

.RMP-Post--CopyContainer {
  max-width: 768px;
}


.RMP-Social {
  display: block;
  text-align: center;
  margin: spacing(xl) 0;

  a {
    margin: 0 8px;
  }
}

.SocialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.RMP-PostTease-Image {
  width: 100%;
  height: 200px;
  background-color: color($primary);
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}

// input with icons
.has-icons-left {
  position: relative;

  .RMP-UserWidget--FormInput {
    padding-left: 32px;
  }

  .RMP-UserWidget--Icon {
    position: absolute;
    top: 50%;
    transform: translate(8px, -50%);
  }
}

//Button
.RMP-UserWidget--Button {
  @extend .Button;
  @extend .Button--primary;
}

.RMP-UserWidget--Form {
  .RMP-UserWidget--Button {
    margin-top: spacing();
    margin-bottom: spacing();
    width: 100%;
  }
}

.has-icons-left {
  position: relative;
  .RMP-UserWidget--Icon{
    position: absolute;
    top: 50%;
    transform: translate(8px, -50%);
  }
}