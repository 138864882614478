// Weird padding issues on profile/job pages
@include respond-to(sm, max-width) {
  .company-profile2 .page-header {
    display: none;
  }

  .MegaSearch {
    margin-top: 50px;
  }
}

