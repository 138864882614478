.FormGroup {
  display: flex;
  width: 100%;
  //width: 'calc(50% - 40px)';
  float: left;

  .Button {
    //width: 40px;
    float: left;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  select,
  input {
    width: 100%;
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.Form-fieldGroup {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -1px;
  // Not Bem but required due to plugins
  @include clearfix;

  label {
    position: relative;

    &:after {
      content: 'i';
      opacity: 0;
    }
  }

  input,
  select,
  .v-select .dropdown-toggle,
  .Form-input {
    border-radius: 0;

    &:focus {
      z-index: 1;
    }
  }

  .Form-Content {
    //flex-grow: 1;
    //flex-shrink: 0;
    width: 50%;
    float: left;
  }

  .Form-field:not(:first-child),
  .Form-Content:not(:first-child) {
    margin-left: -1px;
  }

  .Form-field:nth-child(1),
  .Form-Content:nth-child(1) {
    input,
    select,
    .v-select .dropdown-toggle,
    .Form-input {
      border-top-left-radius: border-radius();
      border-bottom-left-radius: border-radius();
    }
  }

  .Form-field:last-child ,
  .Form-Content:last-child {
    input,
    select,
    .v-select .dropdown-toggle,
    .Form-input {
      border-top-right-radius: border-radius();
      border-bottom-right-radius: border-radius();
    }
  }
}

.Form-groupSpaced {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @include respond-to(md, max-width) {
    flex-wrap: wrap;
    flex-wrap: -ms-flex-wrap;
  }

  .Form-field:nth-child(1) {
    margin-left: 0;
  }

  .Form-input {
    width: 100%;
    border-radius: border-radius();
  }

  .Form-field {
    display: inline-block;
    flex-grow: 1;
    width: calc(50% - #{spacing()});
    vertical-align: top;
    @include respond-to(md, max-width) {
      width: 100%;
    }
    @include respond-to(md) {
      margin-left: spacing();
    }
  }
}

.no-flexbox {
  .Form-groupSpaced {
    display: block;
    width: 100%;

    .Form-field {
      display: block;
      width: 100%;
      margin-left: 0;
      clear: both;
    }
  }
}
