.Header {
  background-color: color($primary, 'dark');
  @include respond-to(sm, max-width) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 8;
  }

  .Container {
    display: flex;
    align-items: center;
    padding: 0 spacing();
    justify-content: space-between;
  }
}

.Header-homeLink {
  font-size: 0;
  @media (min-width: 1130px) {
    margin-left: 0;
  }
}

.Header-logo {
  @include respond-to(sm, max-width) {
    width: 160px;
  }
  @include respond-to(sm) {
    width: 240px;
  }
  @media (min-width: 1250px) {
    width: 300px;
  }
}

.Navigation {
  display: flex;
  align-items: center;
  //@media (min-width: 1131px) {
  //  margin-left: auto;
  //}
}

.Navigation-items {
  display: flex;
}

.Navigation-item {
  position: relative;
  margin: 0;
}

.Navigation-link {
  color: color('white');
  font-size: 13px;
  font-weight: 700;
  height: 70px;
  padding: 0 0.5em;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  &:focus,
  &:active,
  &:hover {
    background: color($primary);
    color: color('white');
  }
  @include respond-to(sm, max-width) {
    height: 50px;
  }

  span {
    margin-left: 4px;
  }
}

.Navigation-subnav {
  display: none;
  background: color('blue', 'dark');
  border: 1px solid color('blue', 'dark');
  left: 0;
  position: absolute;
  top: 70px;
  width: 150px;
  z-index: 4;

  .Navigation-link {
    height: auto;
    padding: 10px;
    display: block;

    span {
      margin-left: 0;
    }
  }
}

.Navigation-authentication {
  .Navigation-items {
    display: flex;
    align-items: center;
  }
}

.Navigation-twoColumns {
  width: 450px;

  .Navigation-item {
    display: inline-block;
    float: none;
    margin-right: -4px;
    vertical-align: top;
    width: 50%;
  }
}

.Has-subnav {
  &:hover {
    .Navigation-subnav {
      display: block;
    }
  }
}

// Todo Change mobile nav classes
.mobile-nav,
.mobile-menu {
  display: block;
  color: #fff;
  @media screen and (min-width: 1131px) {
    display: none;
  }
}

.MenuOverlay {
  display: none;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, .5);
  top: 50px;
  @include respond-to(sm) {
    top: 70px;
  }

  &.menu-open {
    display: block;
    @media (min-width: 1130px) {
      display: none;
    }
  }
}

.Navigation--main {
  width: 610px;
  @media (max-width: 1130px) {
    display: none;
    bottom: 0;
    left: 0;
    //top: 70px;
    position: fixed;
    overflow: scroll;
    z-index: 4;
    background: #004b92;
    flex-direction: column;
    max-width: 100%;
    width: 400px;
    .Navigation-subnav {
      width: 100%;
      position: relative;
      top: 0;
      display: block;

      .Navigation-link {
        height: 40px;
      }
    }
    .Navigation-link {
      height: 40px;
      padding-left: 16px;
    }
    span {
      display: none
    }
    &.menu-open {
      display: block;
    }
  }
  @media only screen and (max-width: 1130px) and (min-width: 768px) {
    top: 70px;
  }
  @include respond-to(sm, max-width) {
    top: 50px;
  }
}

.AccountNav-login {
  @include respond-to(sm) {
    margin-left: 4px;
  }

  .Navigation-link {
    border: 1px solid #fff;
    border-radius: 8px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 700;
    height: auto;
    line-height: 1;
    padding: 8px 11px;
    text-transform: uppercase;
    white-space: pre;

    &:hover {
      background: color($primary);
    }
  }
}

.AccountNav-register {
  display: none;
  margin-left: spacing();
  .Navigation-link {
    border: none;
    height: auto;
    line-height: 1;
    padding: 9px 11px;
    font-size: 14px;
  }
  @include respond-to(sm) {
    display: block
  }
}