@use 'sass:math';

.RecruiterShout {
  //background: $mediumgreen;
  display: none;
  position: fixed;
  right: $space-l;
  bottom: 0;
  transition: .3s;
  border-radius: 4px 4px 0 0;
  background: color($primary);
  z-index: 3;
  @include respond-to(sm) {
    display: block;
  }

  &:hover {
    right: $space-l - $space-s;
    padding: $space-s;
  }
}

.RecruiterShout-link {
  padding: math.div($space-s, 1.5) $space-s;
  color: #fff;
  font-size: 15px;

  span {
    font-weight: $font-weight-heavy;
  }
}

.SearchPage {
  .RecruiterShout {
    display: none;
  }
}
