.Bg-blue,

  // Old class
.inner-blue {
  background: color('blue');
}

.c-primary {
  color: color('blue');
}

.c-green {
  color: color('green');
}

.c-red {
  color: color('red');
}

.color-primary {
  color: color('blue');
}