$right: 50px !default;

.Notification-trigger {
  padding: 0.9rem;
  cursor: pointer;
  text-align: center;
  width: 47px;

  .span {
    margin: 0;
  }
}

.Notification-count {
  position: absolute;
  top: 50%;
  transform: translate(-4px, -18px);
  line-height: 1;
  left: 26px;
  padding: 2px 4px;
  border-radius: 6px;
  background: #FF3E3E;
  color: #fff;
  font-size: 12px;
  border: 1px solid #005cb3;
  font-weight: 700;
  transition: ease-in-out .3s;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);

  &.Notification--countZero {
    font-size: 0;
    height: 8px;
    width: 8px;
    padding: 0;
    top: 26px;
  }
}


.Notification-triggerIcon {
  display: block;
  color: #fff;
  font-size: 1.25rem;
  margin: 0 !important;
}

.Notification {
  font-size: 1rem;
  position: absolute;
  top: 100%;
  width: 350px;
  z-index: 15;
  right: -#{$right};
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  background: #f3f3f3;
  transform-origin: top center;
  animation: 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    right: $right + 12px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }

  @include respond-to(xs, max-width) {
    max-height: calc(100vh - 60px);
    position: fixed;
    top: 54px;
    width: auto;
    left: 10px;
    right: 10px;
    &:before {
      right: $right + 10px;
    }
  }
}

.Notification-ctaClose {
  display: none;
  text-align: center;
  margin-right: 16px;
  width: 100px;
  @include respond-to(xs, max-width) {
    display: block;
  }
}

.Notification-contentWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 600px;
  max-height: calc(100vh - 100px);
  @include respond-to(xs, max-width) {
    max-height: calc(100vh - 60px);
  }
}

.Notification-content {
  width: 100%;
  flex-grow: 1;
  max-height: calc(100% - 100px);
  overflow-y: scroll;
}

.Notification-cta {
  border-top: 1px solid #e2e2e2;
  padding: 1rem;
  text-align: center;
  align-items: center;
  background: #ffff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
}

.Notification-cta {
  .Button {
    width: 100%;
  }

  .Notification-ctaClose {
    width: 160px;
  }
}

.ScaleIn-enter-active,
.ScaleIn-leave-active {
  animation-name: scaleIn;
  transition: 0.2s;
}

.ScaleIn-leave-active {
  animation-direction: reverse;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(1, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
}

.ScaleIn-leave-active {
  animation-direction: reverse;
  opacity: 0;
  transform: translateX(60px) scaleY(0.5);
}

.HeaderCount-container {
  width: auto;
}