.UserIcon {
  display: inline-block;
  background: rgba(255, 255, 255, .2);
  border-radius: 8px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  //margin-right: 2px;
  margin-left: 2px;
  font-size: .8rem;
  text-align: center;
  vertical-align: middle;
  color: color('white');
}

.userDetails {
  display: inline-block;
  font-weight: 700;
  color: color('white');
  vertical-align: middle;
  padding-top: 9px;
  @include respond-to(sm, max-width) {
    .ProgressBar,
    .name {
      display: none !important;
    }
  }
}

.UserLoggedIn {
  //width: 101px;
  display: flex;
  align-items: center;
  gap: 4px;
  @include respond-to(sm, max-width) {
    width: auto;
  }
  .Navigation-subnav {
    flex-direction: column;
    top: 100%;
    right: 0;
    left: auto;
  }

  .Navigation-item {
    width: 100%;
  }
}

header#pageheader .AccountNav {
  .UserMenu {
    font-size: 17px;
    display: inline-block;
    line-height: 10px;
    position: relative;
    top: 3px;
  }

  li.subnav {
    padding: 26px 5px;

    span {
      margin: 0;
    }

    span.Icon-r Icon-angle-down {
      display: inline-block;
      vertical-align: middle;
    }

    span.name {
      display: inline-block;
      min-width: 24px;
      max-width: 80px;
      margin-right: 6px;
      font-size: 12px;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
    }

    ul {
      right: 0;
      left: auto;
      width: 160px;

      a {
        display: block;
        padding: 10px;
        color: #fff;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &.UserLoggedIn {
      position: relative;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}

.ProgressBar {
  text-align: center;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  background: $lightgrey;
  z-index: 0;
}

.ProgressBar-percentage {
  display: block;
  transition: .2s;
  transition-delay: .8s;
  border-radius: 8px;
  background: $mediumgreen;
  top: 0 !important; // TODO - Hack, as nav css is so specific
}

.ProgressBar-figure {
  left: 100%;
  color: color('green');
  font-size: toRem(14px);
}

.userDetails-name {
  display: flex;
  align-items: center;

  .UserMenu {
    margin-left: 2px;
    margin-top: -4px;
    @include respond-to(sm) {
      margin-top: 2px;
    }
  }
}


