// ASIDE SERVICE ============================================================================= */
.u-pullRight.aside {
  @include respond-to(md) {
    padding-left: 0;
    margin-left: auto;
  }
}

.small-promo.recruiters {
  padding: 0;
  background: color($primary);
}

  .small-promo.recruiters img {
  margin-bottom: 8px;
}


.unit {
  width: 100%;
  margin-bottom: $space-l;
  padding-bottom: $space-default;
  border-bottom: 1px solid #efede9;
  border-bottom: 1px solid #efede9;
  &:last-child {
    border-bottom: none;
  }

  > .text-link,
  > h4 {
    padding: 0 0.5em;
  }

  .long-advert {
    margin-bottom: 15px;
  }

  h3, h4, h5,
  p {

  }

  &.similar-reviews,
  &.more-reviews {
    .score--value {
      @include position(absolute, $right: -8px, $bottom: -8px);
    }

  }
}

//.profile-content2 {
//  border: none !important;
//  &.similar-reviews,
//  &.more-reviews {
//    .score--value {
//      @include position(absolute, $right: -8px, $bottom: -8px);
//      display: block;
//    }
//  }
//}

.aside h4 {
  margin-bottom: $space-default;
  color: #434a4f
}

.aside h5 {
  margin-bottom: $space-xs;
  color: #434a4f;
  font-weight: 500;
}

.aside h5 {
  //font-weight: 500
  font-size:1em;
}

.aside .unit li {
  display: inline-block;
  width: 100%;
  padding: $space-s;
  clear: left;
  transition: all .3s;
  border: 1px solid transparent;
  &.long-advert {
    padding: 0;
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.06);
    background: #fff;
   box-shadow: 0 8px 15px 1px, rgba(0, 0, 0, 0.15);
  }
}

.aside .unit li .sidebar-company-logo {
  position: relative;
  margin-right: 15px;
  float: left;
}

.aside .unit li .sidebar-company-logo {
  width: 50px;
  height: 50px;
  float: left;
  border: 1px solid $lightgrey;
  background: $white;
  img {
    height: auto;
  }
}

.aside .unit li .sidebar-company-logo + div {
  width: calc(100% - 90px);
  float: left;
}

.aside .unit li .sidebar-company-logo + div .text-link {
  float: left;
}

.aside .unit p {
  color: $mediumgrey;
}

.aside .long-advert .new-banner-advert {
  width: 100%;
  height: 180px;
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size();
}

.panel {
  display: inline-block;
  width: 100%;
  //margin-bottom: 20px;
  //padding: spacing();
  //background: $lightestgrey;
}

.SidebarRight {
  &.panel {
    padding-top: 0;
    padding-bottom: 0;
    .unit {
      padding: 0;
      background: none;
    }

    .sidebar-signup-modal {
      padding: $space-default;
    }
  }
}

.SidebarRight-content {
  height: 100%;
  padding: 16px;
  background: #fff;
  @extend .clearfix;
  
  .unit{
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .mini-advert-list{
    padding-left: 0;
    margin-bottom: 0;
  }
}

// Todo when test is over two
@media screen and (max-width: 600px) {
  .company-profile2 .aside {
    overflow: hidden;
  }
}