// @group Utilties classes
.u-flex-spaceBetween {
  justify-content: space-between;
}

// @group Utilties classes
.u-flex-alignCenter {
  align-items: center;
}

// @group Utilties classes
.u-flex-wrap {
  flex-wrap: wrap;
}

// @group Utilties classes
.u-flex {
  display: flex !important;
}

// @group Utilties classes
.u-flex-grow {
  flex-grow: 1;
}

// @group Utilties classes
.u-flex-column {
  flex-direction: column;
}
